<ul class="text-xl h-full flex flex-col">
  <!-- Each list item has a border and rounded corners, with 0 space between them -->
  <li
    class="menu-item cursor-pointer justify-center flex flex-col font-bold"
    [ngClass]="{'bg-vistrace-selected': activeRoute== '/main/home'}"
    (click)="toHome()"
  >
    <div 
    class="h-full flex flex-col items-center justify-center text-vistrace-primary hover:text-white"
      >
      <img
        src="assets/home.svg"
        class="w-8 h-8 m-4 cursor-pointer"
        alt="lock"
      />
      <p>{{this.textFragments.textFragmentsLanguage(this.ES, this.EN, this.DE)?.home}}</p>
    </div>
  </li>
  <!-- Repeat for other menu items, updating the href as needed -->
  <li
    class="menu-item cursor-pointer justify-center flex flex-col font-bold"
    [ngClass]="{'bg-vistrace-selected': activeRoute== '/main/products'}"
    (click)="toProducts()"
  >
    <div 
      class="h-full flex flex-col items-center justify-center text-vistrace-primary hover:text-white"
      >
      <img
        src="assets/trash.svg"
        class="w-8 h-8 m-4 cursor-pointer"
        alt="lock"
      />
      <p>{{this.textFragments.textFragmentsLanguage(this.ES, this.EN, this.DE)?.myProducts}}</p>
    </div>
  </li>
  <li
    class="menu-item cursor-pointer justify-center flex flex-col font-bold"
    [ngClass]="{'bg-vistrace-selected': activeRoute== '/main/subscriptions'}"
    (click)="toSubscriptions()"
  >
    <div 
    class="h-full flex flex-col items-center justify-center text-vistrace-primary hover:text-white"
      >
      <img
        src="assets/membership.svg"
        class="w-8 h-8 m-4 cursor-pointer"
        alt="lock"
      />
      <p>{{this.textFragments.textFragmentsLanguage(this.ES, this.EN, this.DE)?.subscriptionPlans}}</p>
    </div>
  </li>
  <li
    class="menu-item cursor-pointer justify-center flex flex-col font-bold"
    [ngClass]="{'bg-vistrace-selected': activeRoute.startsWith('/main/settings')}"
    (click)="toSettings()"
  >
    <div 
    class="h-full flex flex-col items-center justify-center text-vistrace-primary hover:text-white"
      >
      <img
        src="assets/settings.svg"
        class="w-8 h-8 m-4 cursor-pointer"
        alt="lock"
      />
      <p>{{this.textFragments.textFragmentsLanguage(this.ES, this.EN, this.DE)?.settings}}</p>
    </div>
  </li>
  
</ul>
