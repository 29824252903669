import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ChatBotComponent } from 'src/app/shared/vistrace-components/chat-bot/chat-bot.component';
import { TextFragmentService } from '../../services/text-fragment.service';
import { ModalCanvasIntroComponent } from 'src/app/shared/vistrace-components/modal-canvas-intro/modal-canvas-intro.component';
import { Subscription } from 'rxjs';
import { MessageService } from 'src/app/shared-generic/services/message.service';
import { filter } from 'rxjs/operators';
import { Router } from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, OnDestroy {

  public openChatWindow: boolean = false;
  private subscriptions = new Array<Subscription>();
  public products: any[] = [];
  public lastProduct: string = '';
  public activeSubscription: boolean = false;
  public serviceName = "-";
  public expiringDate = "-";

  EN = {
    subscriptionStatus : 'Subscription Status',
    expiringDate : 'Expiring Date:',
    recentlyAdded : 'Recently added product',
    productName : 'Name of Product:',
    usage : 'Usage of service',
    registered : '3 out of 5 products registered',
    needHelp : 'Need help?',
    getToKnow : 'Get to know how VisTrace works!',
    choose : 'Choose your subscription plan',
    faq : 'Frequently Asked Questions',
    productExceed: 'what happens if I exceed the product limit on my current plan?',
    productExceedAnswer: 'If you exceed your product limit, you can easily add more products at an additional cost per product, or upgrade to a higher plan to accommodate more items.',
    cancelSubscription: 'How do I cancel my subscription?',
    cancelSubscriptionAnswer: 'You can cancel your subscription at any time through the account settings page.',
    planSwitch: 'Can I switch to a different plan mid-cycle?',
    planSwitchAnswer: 'Yes, you can switch to a different plan at any time.',
    new : 'Are you new to VisTrace?',
    tutorial : 'Watch our tutorial video to get started!'
  }

  ES = {
    subscriptionStatus : 'Estado de la suscripción',
    expiringDate : 'Fecha de vencimiento:',
    recentlyAdded : 'Producto añadido recientemente',
    productName : 'Nombre del producto:',
    usage : 'Uso del servicio',
    registered : '3 de 5 productos registrados',
    needHelp : '¿Necesitas ayuda?',
    getToKnow : '¡Conoce cómo funciona VisTrace!',
    choose : 'Elige tu plan de suscripción',
    faq : 'Preguntas frecuentes',
    productExceed: '¿Qué sucede si excedo el límite de productos en mi plan actual?',
    productExceedAnswer: 'Si excede su límite de productos, puede agregar fácilmente más productos a un costo adicional por producto o actualizar a un plan superior para acomodar más elementos.',
    cancelSubscription: '¿Cómo cancelo mi suscripción?',
    cancelSubscriptionAnswer: 'Puede cancelar su suscripción en cualquier momento a través de la página de configuración de la cuenta.',
    planSwitch: '¿Puedo cambiar a un plan diferente a mitad de ciclo?',
    planSwitchAnswer: 'Sí, puede cambiar a un plan diferente en cualquier momento.',
    new : '¿Eres nuevo en VisTrace?',
    tutorial : '¡Mira nuestro video tutorial para comenzar!'
  }

  DE = {
    subscriptionStatus : 'Abonnementstatus',
    expiringDate : 'Ablaufdatum:',
    recentlyAdded : 'Kürzlich hinzugefügtes Produkt',
    productName : 'Produktname:',
    usage : 'Verwendung des Dienstes',
    registered : '3 von 5 Produkten registriert',
    needHelp : 'Brauchen Sie Hilfe?',
    getToKnow : 'Lernen Sie kennen, wie VisTrace funktioniert!',
    choose : 'Wählen Sie Ihren Abonnementplan',
    faq : 'Häufig gestellte Fragen',
    productExceed: 'Was passiert, wenn ich das Produktlimit auf meinem aktuellen Plan überschreite?',
    productExceedAnswer: 'Wenn Sie Ihr Produktlimit überschreiten, können Sie ganz einfach weitere Produkte zu einem zusätzlichen Preis pro Produkt hinzufügen oder auf einen höheren Plan aufrüsten, um mehr Elemente unterzubringen.',
    cancelSubscription: 'Wie kündige ich mein Abonnement?',
    cancelSubscriptionAnswer: 'Sie können Ihr Abonnement jederzeit über die Kontoeinstellungen-Seite kündigen.',
    planSwitch: 'Kann ich in der Mitte des Zyklus zu einem anderen Plan wechseln?',
    planSwitchAnswer: 'Ja, Sie können jederzeit zu einem anderen Plan wechseln.',
    new : 'Sind Sie neu bei VisTrace?',
    tutorial : 'Schauen Sie sich unser Tutorial-Video an, um loszulegen!'
  }

  //Store the data in a function, and subscribe to language change on OnInit
  data: any = [{
    "parentName": this.textFragments.textFragmentsLanguage(this.ES, this.EN, this.DE)?.productExceed,
    "childProperties":
      [
        { "propertyName": this.textFragments.textFragmentsLanguage(this.ES, this.EN, this.DE)?.productExceedAnswer },
      ]
  }, {
    "parentName": this.textFragments.textFragmentsLanguage(this.ES, this.EN, this.DE)?.cancelSubscription,
    "childProperties": 
      [
        { "propertyName": this.textFragments.textFragmentsLanguage(this.ES, this.EN, this.DE)?.cancelSubscriptionAnswer }
      ]
  }, {
    "parentName": this.textFragments.textFragmentsLanguage(this.ES, this.EN, this.DE)?.planSwitch,
    "childProperties": 
      [
        { "propertyName": this.textFragments.textFragmentsLanguage(this.ES, this.EN, this.DE)?.planSwitchAnswer }
      ] 
  }];

  toggleAccordian(event, index) {
    const element = event.target;
    element.classList.toggle("active");
    if (this.data[index].isActive) {
      this.data[index].isActive = false;
    } else {
      this.data[index].isActive = true;
    }
    const panel = element.nextElementSibling;
    if (panel.style.maxHeight) {
      panel.style.maxHeight = null;
    } else {
      panel.style.maxHeight = panel.scrollHeight + "px";
    }
  }

  constructor(public dialog: MatDialog,
              public textFragments : TextFragmentService,
              public messageService : MessageService,
              public router : Router
  ) { }


  ngOnInit(): void {
    const callbackGetProductId = "getProductIds"
    this.textFragments.textFragmentsLanguage(this.ES, this.EN, this.DE);
    this.subscriptions.push(
      this.messageService
        .awaitMessage('main')
        .pipe(filter((msg) => msg.name === callbackGetProductId))
        .subscribe((msg) => {
          this.products = msg.args.product_list;
          this.lastProduct = this.lastProductName();
        }));
    const getProductIdMessage = {
      name: 'getProductIds',
      args: [callbackGetProductId]
    };
    this.messageService.sendMsg(getProductIdMessage, 'main');

    const callbackId = "userinfo_key";
    this.subscriptions.push(
      this.messageService.awaitMessage()
        .pipe(filter(msg => msg.name === callbackId))
        .subscribe( msg => {
          this.serviceName = msg.args.serviceName;
          this.expiringDate = msg.args.expiringDate;
          this.activeSubscription = (msg.args.subscriptionStatus==="active");
          console.log(this.activeSubscription);
        }
      ));

    const msg = {
      name: 'getUserInfoKey',
      args: [callbackId]
    };
    this.messageService.sendMsg(msg, 'main');
  }

  openChatBot(): void {
    this.openChatWindow = !this.openChatWindow;
  }

  toSubscriptions() {
    this.router.navigateByUrl("/main/subscriptions")
  }

  lastProductName(): string {
    // if (this.products.length === 0) {
    //   return "No products added yet";
    // } else {
    //   return this.products[this.products.length - 1].name;
    // }
    return this.products.length > 0 ? this.products[this.products.length - 1].name : 'No products available';
  }

  toUpperCaseString(input: string): string {
    return input.toUpperCase();
  }

  //   dialogRef.afterClosed().subscribe(result => {
  //     console.log(`Dialog result: ${result}`);
  //   });
  // }

  openCanvasVideo(){
    this.dialog.open(ModalCanvasIntroComponent)
  }

  ngOnDestroy() {
    this.subscriptions.forEach( subs => subs.unsubscribe() );
  }
}
