import { Component, Input } from '@angular/core';
import { TextFragmentService } from 'src/app/ui-main/services/text-fragment.service';

@Component({
  selector: 'app-vistrace-card',
  templateUrl: './vistrace-card.component.html',
  styleUrls: ['./vistrace-card.component.scss']
})
export class VistraceCardComponent {
  @Input() cardType!: string;
  @Input() title: string;
  @Input() img_src: string;
  @Input() text_description: string;
  @Input() text_content: string;
  @Input() icon?: string;
  @Input() variant: 'default' | 'highlight' | 'accent' = 'default';

  EN = {
    currentPlan: 'Current Plan',
    expiringDate: 'Expiring Date',
  }

  ES = {
    currentPlan: 'Plan Actual',
    expiringDate: 'Fecha de Expiración',
  }

  DE = {
    currentPlan: 'Aktueller Plan',
    expiringDate: 'Ablaufdatum',
  }

  constructor(
    public textFragments : TextFragmentService) 
{ }

  ngOnInit(): void {
    this.textFragments.textFragmentsLanguage(this.ES, this.EN, this.DE);
  }

  getVariantClasses(): string {
    switch (this.variant) {
      case 'highlight':
        return 'bg-green-100 text-green-800';
      case 'accent':
        return 'bg-blue-100 text-blue-800';
      default:
        return 'bg-white text-gray-800';
    }
  }
}