import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DashboardTitleService {
  public product_key = "";
  public status = "";
  public batch_numbers: any;
  public active_batch_number = "";
  public selectedIDSubject = new BehaviorSubject<string | null>("Product Info");
  public activeBatchKeySubject = new BehaviorSubject<string | null>("");
  selectedID$: Observable<string | null> = this.selectedIDSubject.asObservable();
  activeBatchKey$: Observable<string | null> = this.activeBatchKeySubject.asObservable();

  public formStates = {
    "Product-Info": [
      {form: "product-general-form", state: false},
      {form: "product-general-form-I", state: false},
      {form: "product-consumer-info-form", state: false}
    ],
    "Cultivation": [
      {form: "product-cultivation-form", state: false},
      {form: "product-cultivation-form-2", state: false}
    ],
    "Production": [
      {form: "product-production-form", state: false},
      {form: "product-production-form-2", state: false}
    ],
    "Bottling": [
      {form: "product-bottling-form", state: false},
    ],
    "Sales": [
      {form: "product-sales-form", state: false},
    ],
    "Transportation": [
      {form: "product-transport-form", state: false},
    ],
  }

  setSelectedTitle(id: string) {
    console.log("IN service id", id);
    this.selectedIDSubject.next(id);
    
  }
}
