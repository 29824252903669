<div
  class="flex flex-col plan plan-container"
  [ngClass]="!raised ? '' : 'plan-highlighted'"
  [ngStyle]="subscriptionType == 'enterprise' ? {'width': '280px'} : ''">
  <div
    class="relative pb-1 m-0 mb-8 overflow-hidden text-center text-gray-700 bg-transparent border-b rounded-none shadow-none bg-clip-border border-white/10"
    *ngIf="subscriptionType !== 'enterprise'">
    <p class="bg-white text-gray-700 rounded block font-sans text-2xl antialiased font-normal leading-normal uppercase">
      {{subscriptionDesc?.service_name}}
    </p>
    <h1 class="plan-price">
      <span class="mt-2 text-2xl 2k:text-3xl text-white">€ {{subscriptionDesc?.price_per_year}} / {{this.textFragments.textFragmentsLanguage(this.ES, this.EN, this.DE)?.year}}</span>
    </h1>    
  </div>
  <div
    class="relative pb-4 m-0 mb-8 overflow-hidden text-center text-gray-700 bg-transparent border-b rounded-none shadow-none bg-clip-border border-white/10"
    *ngIf="subscriptionType == 'enterprise'">
    <p class="bg-white text-gray-700 rounded block font-sans text-2xl antialiased font-normal leading-normal uppercase">
      {{subscriptionDesc?.service_name}}
    </p>
    <h1 *ngIf="subscriptionType == 'enterprise'" class="plan-price">
      <span class="mt-2 text-2xl 2k:text-3xl text-white">{{this.textFragments.textFragmentsLanguage(this.ES, this.EN, this.DE)?.demand}}</span>
    </h1>
  </div>
  
  <div>
    <ul *ngIf="subscriptionType != 'enterprise'" class="flex flex-col gap-4 pt-2">
      <li class="flex items-center gap-4" *ngFor="let feature of subscriptionDesc?.feature_descriptions">
        <span class="p-1 border rounded-full border-white/20 bg-white/20"><svg xmlns="http://www.w3.org/2000/svg"
            fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" class="w-3 h-3">
            <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 12.75l6 6 9-13.5"></path>
          </svg></span>
        <p class="block font-sans text-base antialiased font-normal leading-relaxed text-inherit">
          <span class="font-bold">{{feature.title}}:</span> {{feature.text}}
        </p>
      </li>
    </ul>
    <ul *ngIf="subscriptionType == 'enterprise'" class="flex flex-col gap-4 pt-2">
      <li class="flex items-center gap-4">
        <p class="block font-sans text-base antialiased font-normal leading-relaxed text-inherit justify-center">
          {{this.textFragments.textFragmentsLanguage(this.ES, this.EN, this.DE)?.extra}}
        </p>
      </li>
      <li class="flex items-center gap-4" *ngFor="let feature of subscriptionDesc?.additional_features">
        <span class="p-1 border rounded-full border-white/20 bg-white/20"><svg xmlns="http://www.w3.org/2000/svg"
            fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" class="w-3 h-3">
            <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 12.75l6 6 9-13.5"></path>
          </svg></span>
        <p class="block font-sans text-base antialiased font-normal leading-relaxed text-inherit">
          {{feature}}
        </p>
      </li>
    </ul>
  </div>
  <div class="p-0 mt-6 flex w-full absolute left-0 bottom-[5%] justify-center">
    <button 
      *ngIf="subscriptionType != 'enterprise' && !activated && possible && !oneActive"
      class="w-4/5 text-gray-900 align-middle select-none text-center uppercase transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-sm py-3.5 px-7 rounded-lg bg-white shadow-md shadow-blue-gray-500/10 hover:shadow-lg hover:shadow-blue-gray-500/20 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none block hover:scale-[1.02] focus:scale-[1.02] active:scale-100"
      type="button" (click)="subscribe(subscriptionDesc['service_id'])">
      {{this.textFragments.textFragmentsLanguage(this.ES, this.EN, this.DE)?.subscribe}}
    </button>
    <button 
      *ngIf="subscriptionType != 'enterprise' && !activated && possible && oneActive"
      class="w-4/5 text-gray-900 align-middle select-none text-center uppercase transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-sm py-3.5 px-7 rounded-lg bg-white shadow-md shadow-blue-gray-500/10 hover:shadow-lg hover:shadow-blue-gray-500/20 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none block hover:scale-[1.02] focus:scale-[1.02] active:scale-100"
      type="button" (click)="upgrade(subscriptionDesc['service_id'])">
      {{this.textFragments.textFragmentsLanguage(this.ES, this.EN, this.DE)?.upgrade}}
    </button>
    <div
      *ngIf="subscriptionType == 'enterprise'"
      class="w-4/5 text-blue-200 align-middle select-none text-center uppercase transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-sm py-3.5 px-7 rounded-lg bg-gray-700 shadow-md shadow-blue-gray-500/10 hover:shadow-lg hover:shadow-blue-gray-500/20 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none block hover:scale-[1.02] focus:scale-[1.02] active:scale-100"
      type="button" disabled>
      {{this.textFragments.textFragmentsLanguage(this.ES, this.EN, this.DE)?.contact}}
    </div>
    <button
      *ngIf="activated"
      class="w-4/5 text-black bg-vistrace-hover-light align-middle select-none text-center uppercase transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-sm py-3.5 px-7 rounded-lg shadow-md shadow-blue-gray-500/10 hover:shadow-lg hover:shadow-blue-gray-500/20 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none block hover:scale-[1.02] focus:scale-[1.02] active:scale-100"
      type="button" disabled>
      {{this.textFragments.textFragmentsLanguage(this.ES, this.EN, this.DE)?.currentPlan}}
    </button>
  </div>
</div> 