import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { MessageService } from 'src/app/shared-generic/services/message.service';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { UiInfoService } from 'src/app/shared-generic/services/ui-info-service';
import { NgxSpinnerService } from 'ngx-spinner';
import { TextFragmentService } from '../../services/text-fragment.service';
import { ModalCreditCardComponent } from 'src/app/shared/vistrace-components/modal-credit-card/modal-credit-card.component';
import { MatDialog } from '@angular/material/dialog';
import { SubscriptionPlan } from '../../types/subscription-plan';
import { StripeService } from '../../services/stripe-service';
import { ModalUnsubscribeConfirmationComponent } from 'src/app/shared/vistrace-components/modal-unsubscribe-confirmation/modal-unsubscribe-confirmation.component';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit, OnDestroy {
  private personalData: any = {};
  private billingData: any = {};
  public personalDataChanged = false;
  public billingDataChanged = false;
  public billingReady = false;
  public activeTab = "0";
  public userinfo_key = "";
  public company_key = "";
  public agreeInfo = false;
  public agreeInfo_pre = false;
  public serviceName = "-";
  public paymentStatus = "-";
  public creditCard = "-";
  public voucherNumber = "";
  public subscriptionStatus = "-";
  public expiringDate = "-";
  public productsAvailable = "0";
  public purchaseIntentId = "";
  public purchaseIntentDetails: any;
  public typeOfPurchase = "-";
  public errorCheck = 0;
  public updateTrigger = "";
  public activatedPlan = "";
  public oneActive: boolean = false;
  public sub: SubscriptionPlan;
  public illustration = "none"; //one of plan, product, none
  public noInvoices = true;

  private subscriptions: Array<Subscription>=[];
  public invoice_table = "invoice-user-table";

  EN = {
    personalDetails: 'Personal Details',
    accountDetails: 'Account Details',
    payment: 'Payment',
    invoices: 'Invoices',
    agreement: 'I agree to receive news, product updates and event emails from QuantecDC. I can withdraw my consent at any time',
    learn: 'To learn how QuantecDC processes your data, see QuantecDC ',
    privacy: 'privacy policy',
    updatePersonalData: 'Update personal data',
    plan: 'Selected Plan',
    productsAvailable: 'Products Available',
    subscription: 'Subscription Status',
    expiring: 'Expiring Date Subscription',
    paymentStatus: 'Payment Status',
    creditCard: 'Provided Credit Card',
    updateBilling: 'Update Billing Details',
    planPurchase: 'Plan Purchase',
    payNow: 'Pay Now',
    paymentDetails: 'Payment Details',
    billingDetails: 'Billing Details',
    additionalProducts: 'Additional Products',
    planUpgrade: 'Plan Upgrade',
    previousPlan: 'Credit for previous plan',
    period: 'Period',
    subTotal: 'Sub total',
    discount: 'Discount',
    tax : 'Tax',
    grandTotal: 'Grand Total',
    providedCard: 'Provided Credit Card',
    voucher: 'Voucher',
    claim: 'Claim',
    cancelPayment: 'Cancel Payment Process',
    unsubscribe: 'Unsubscribe',
    subscriptionDetails: 'Subscription Details',
    upgradePlan: 'Upgrade Plan',
    purchasePlan: 'Purchase Plan',
    noInvoices: 'No Invoices up to now'
  };

  ES = {
    personalDetails: 'Detalles personales',
    accountDetails: 'Detalles de la cuenta',
    payment: 'Pago',
    invoices: 'Facturas',
    agreement: 'Acepto recibir noticias, actualizaciones de productos y correos electrónicos de eventos de QuantecDC. Puedo retirar mi consentimiento en cualquier momento',
    learn: 'Para saber cómo QuantecDC procesa tus datos, consulta la ',
    privacy: 'política de privacidad',
    updatePersonalData: 'Actualizar datos personales',
    plan: 'Plan seleccionado',
    productsAvailable: 'Productos disponibles',
    subscription: 'Estado de la suscripción',
    expiring: 'Fecha de vencimiento de la suscripción',
    paymentStatus: 'Estado del pago',
    creditCard: 'Tarjeta de crédito proporcionada',
    updateBilling: 'Actualizar detalles de facturación',
    planPurchase: 'Compra de plan',
    payNow: 'Pagar ahora',
    paymentDetails: 'Detalles del pago',
    billingDetails: 'Billing Details',
    additionalProducts: 'Productos adicionales',
    planUpgrade: 'Actualización del plan',
    previousPlan: 'Crédito del plan anterior',
    period: 'Período',
    subTotal: 'Subtotal',
    discount: 'Descuento',
    tax : 'Impuesto',
    grandTotal: 'Total',
    providedCard: 'Tarjeta de crédito proporcionada',
    voucher: 'Cupón',
    claim: 'Reclamar',
    cancelPayment: 'Cancelar pago processo',
    unsubscribe: 'Desuscribirse',
    subscriptionDetails: 'Detalles de la suscripción',
    upgradePlan: 'Actualizar plan',
    purchasePlan: 'Comprar plan',
    noInvoices: 'No Facturas'
  };

  DE = {
    personalDetails: 'Persönliche Details',
    accountDetails: 'Kontodetails',
    payment: 'Zahlung',
    invoices: 'Rechnungen',
    agreement: 'Ich stimme zu, von QuantecDC Nachrichten, Produktaktualisierungen und Event-E-Mails zu erhalten. Ich kann meine Einwilligung jederzeit widerrufen',
    learn: 'Um zu erfahren, wie QuantecDC Ihre Daten verarbeitet, lesen Sie die ',
    privacy: 'Datenschutzrichtlinie',
    updatePersonalData: 'Persönliche Daten aktualisieren',
    plan: 'Ausgewählter Plan',
    productsAvailable: 'Verfügbare Produkte',
    subscription: 'Abonnementstatus',
    expiring: 'Ablaufdatum Abonnement',
    paymentStatus: 'Zahlungsstatus',
    creditCard: 'Bereitgestellte Kreditkarte',
    updateBilling: 'Rechnungsdetails aktualisieren',
    planPurchase: 'Plan kaufen',
    payNow: 'Jetzt bezahlen',
    paymentDetails: 'Zahlungsdetails',
    billingDetails: 'Rechnungsdetails',
    additionalProducts: 'Zusätzliche Produkte',
    planUpgrade: 'Plan Upgrade',
    previousPlan: 'Guthaben für den vorherigen Plan',
    period: 'Zeitraum',
    subTotal: 'Zwischensumme',
    discount: 'Rabatt',
    tax : 'Steuer',
    grandTotal: 'Gesamtsumme',
    providedCard: 'Bereitgestellte Kreditkarte',
    voucher: 'Gutschein',
    claim: 'Anspruch',
    cancelPayment: 'Zahlungsvorgang abbrechen',
    unsubscribe: 'Abmelden',
    subscriptionDetails: 'Abonnementdetails',
    upgradePlan: 'Plan aktualisieren',
    purchasePlan: 'Plan kaufen',
    noInvoices: 'Keine Rechnungen bisher'
  }
  

  constructor(private messageService: MessageService,
              private router : Router,
              private route: ActivatedRoute,
              private uiInfoService: UiInfoService,
              private spinnerService: NgxSpinnerService,
              public textFragments: TextFragmentService,
              private dialogRef : MatDialog,
              private stripeService: StripeService
  ) { }

  ngOnInit(): void {
    this.textFragments.textFragmentsLanguage(this.ES, this.EN, this.DE);
    this.spinnerService.hide();
    this.route.params.subscribe(params => {
      this.activeTab = params['tab']; // Access the 'tab' parameter from the URL
    });

    this.subscriptions.push(
      this.messageService.awaitMessage()
        .pipe(filter(msg => msg.name === "doErrorCheck"))
        .subscribe( msg => {
          console.log("DO error check");
          this.errorCheck = this.messageService.getRandomInt(10000) + 1;
        }
    ));
    this.subscriptions.push(
      this.textFragments.language$.subscribe( ()=> {
        this.updateTrigger = this.messageService.getRandomInt(100000).toString();
      }
    ));

    const callbackId = "userinfo_key";
    this.subscriptions.push(
      this.messageService.awaitMessage()
        .pipe(filter(msg => msg.name === callbackId))
        .subscribe( msg => {
          this.userinfo_key = msg.args.userinfo_key;
          this.agreeInfo_pre = msg.args.agreeInfo;
          this.agreeInfo = msg.args.agreeInfo;
          this.serviceName = msg.args.serviceName;
          this.company_key = msg.args.company_key;
          this.creditCard = msg.args.creditCard;
          this.subscriptionStatus = msg.args.subscriptionStatus;
          this.paymentStatus = msg.args.paymentStatus;
          this.expiringDate = msg.args.expiringDate;
          this.productsAvailable = msg.args.productsAvailable;
          this.typeOfPurchase = msg.args.typeOfPurchase;
          this.billingReady = msg.args.billingReady;
          this.purchaseIntentId = msg.args.purchaseIntentId;
          this.noInvoices = msg.args.noInvoices;
          console.log("STATUS", this.noInvoices)
          if (this.purchaseIntentId) {
            this.getpurchaseIntentDetails();
          }
        }
    ));
    this.subscriptions.push(
      this.textFragments.language$.subscribe( ()=> {
        this.genericData(event);
        this.genericBillingData(event);
      }
    ));
    this.subscriptions.push(
      this.textFragments.language$.subscribe( ()=> {
        const callbackId = "addpurchaseIntentDetails";
        const msg = {
          name: 'getpurchaseIntentDetails',
          args: [this.purchaseIntentId, this.textFragments.language, callbackId]
        };
        this.messageService.sendMsg(msg, 'main');
      }
    ));

    this.subscriptions.push(
      this.messageService.awaitMessage()
        .pipe(filter(msg => msg.name === "addpurchaseIntentDetails"))
        .subscribe( msg => {
          this.purchaseIntentDetails = msg.args.details;
          this.sub = msg.args.sub;
          if (this.sub) {
            this.illustration = "plan";
          } else {
            this.illustration = "product";
          }
        }

        
      
    ));

    const msg = {
      name: 'getUserInfoKey',
      args: [callbackId]
    };
    this.messageService.sendMsg(msg, 'main');
    console.log("ILLUSTRATION",this.illustration)
    console.log("purchaseIntentId", this.purchaseIntentId)
  }

  getpurchaseIntentDetails() {
    const callbackId = "addpurchaseIntentDetails";
    const msg = {
      name: 'getpurchaseIntentDetails',
      args: [this.purchaseIntentId, this.textFragments.language, callbackId]
    };
    this.messageService.sendMsg(msg, 'main');
    console.log("ILLUSTRATION",this.illustration)
    console.log("purchaseIntentId", this.purchaseIntentId)
  }

  ngOnDestroy() {
    this.subscriptions.forEach( subs => subs.unsubscribe() );
  }

  toSubscriptions() {
    this.router.navigateByUrl("/main/subscriptions")
  }

  updatePersonalData() {
    if (this.personalData["new_password"]!=this.personalData["confirm_password"]) {
      this.uiInfoService.openSnackbar("Confirm Password not equal. Update failed.", 3000);
      return
    }
    if (this.personalData["new_password"]) {
      if (this.personalData["new_password"].length<8) {
        this.uiInfoService.openSnackbar("Password should be at least 8 characters. Update failed.", 3000);
        return
      } else {
        const salt = this.messageService.salt;
        const hash = this.messageService.hexdigest_n(this.personalData["new_password"]+salt, 100);
        this.personalData["new_password"] = hash;
        this.personalData["confirm_password"] = "";
      }
    }
    if (this.agreeInfo_pre!=this.agreeInfo) {
      this.personalData["agreeInfo"] = this.agreeInfo;
    }
    const msg = {
      name: 'updatePersonalData',
      args: [this.personalData]
    };
    console.log(this.personalData);
    this.messageService.sendMsg(msg, 'main');
  }

  changeAgreeInfo() {
    this.personalDataChanged = true;
    this.agreeInfo = !this.agreeInfo;
    console.log("FINALLY", this.agreeInfo)
  }

  updateBillingData(){
    const kk = Object.keys(this.billingData);
    if (kk.length>0) { 
      const msg = {
        name: 'updateBillingData',
        args: [this.billingData, this.company_key]
      };
      this.messageService.sendMsg(msg, 'main');
    } else {
      this.uiInfoService.openSnackbar("No Data changed.", 3000);
    }
  }

  genericData(event) {
    this.personalDataChanged = true;
    for (const k of Object.keys(event)) {
      this.personalData[k] = event[k];
    }
  }

  genericBillingData(event) {
    this.billingDataChanged = true;
    for (const k of Object.keys(event)) {
      this.billingData[k] = event[k];
    }
  }
  openAndDownload(event: MouseEvent, url: string): void {
    event.preventDefault(); // Prevent the default anchor tag behavior

    // Open the PDF in a new tab
    window.open(url, '_blank');

    // Create an element to trigger the download
    const link = document.createElement('a');
    link.href = url;
    link.download = url.split('/').pop() || 'download.pdf'; // Dynamically getting the file name from URL
    document.body.appendChild(link);
    link.click(); // Programmatically click the link to start the download
    document.body.removeChild(link); // Remove the element after download
  }

  startPaymentProcess() {
    if (this.creditCard == "-"){
       const reference = this.dialogRef.open(ModalCreditCardComponent)
       reference.afterClosed().subscribe( out => {
        if (out) {
          this.spinnerService.show();
          const msg = {
            name: 'paymentRequest',
              args: ["confirmPaymentProcess", this.purchaseIntentId]
            };
          this.messageService.sendMsg(msg, 'main');
        }
       } )
    } else {
      this.spinnerService.show();
      const msg = {
        name: 'paymentRequest',
          args: ["confirmPaymentProcess", this.purchaseIntentId]
        };
      this.messageService.sendMsg(msg, 'main');
    }
    this.sub = undefined;
    this.illustration = "none";
  }

  updatePurchaseReduction() {
    const msg = {
      name: 'updatePurchaseReduction',
        args: [this.purchaseIntentId, this.textFragments.language, this.voucherNumber]
    };
    this.messageService.sendMsg(msg, 'main');
    
  }

  cancelPaymentProcess() {
    const msg = {
      name: 'cleanUpPurchase',
        args: [this.purchaseIntentId, false]
      };
    this.messageService.sendMsg(msg, 'main');
    this.sub = undefined;
    this.illustration = "none";
    this.voucherNumber = "";
  }

  counterChange (string: string) {
    if (string == "decrease") {
      const msg = {
        name: 'updateBookProduct',
          args: [this.purchaseIntentId, this.textFragments.language, -1]
        };
      this.messageService.sendMsg(msg, 'main');
    } else if (string == "increase") {
      const msg = {
        name: 'updateBookProduct',
          args: [this.purchaseIntentId, this.textFragments.language, 1]
        };
      this.messageService.sendMsg(msg, 'main');
    }
  }


  unsubscribe() {
    const msg = {
      name: 'unsubscribe',
        args: []
      };
    this.messageService.sendMsg(msg, 'main');
    this.sub = undefined;
    this.illustration = "none";
  }

  toUpperCaseString(input: string): string {
    return input.toUpperCase();
  }

  openConfirmUnsubscribed(){
    const dialog = this.dialogRef.open(ModalUnsubscribeConfirmationComponent)

    dialog.afterClosed().subscribe(result => {
      if (result === 'accept') {
        this.unsubscribe();
      } else if (result === 'cancel') {
      }
    });

  }
}
