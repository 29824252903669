import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TextFragmentService } from 'src/app/ui-main/services/text-fragment.service';

@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss']
})
export class SideMenuComponent implements OnInit {

  public activeRoute: string = '';

  EN = {
    home: 'Home',
    myProducts: 'My Products',
    subscriptionPlans: 'Subscription Plans',
    settings: 'Settings',
  };

  ES = {
    home: 'Inicio',
    myProducts: 'Mis Productos',
    subscriptionPlans: 'Planes de Suscripción',
    settings: 'Configuración',
  };

  DE = {
    home: 'Startseite',
    myProducts: 'Meine Produkte',
    subscriptionPlans: 'Abonnementpläne',
    settings: 'Einstellungen'
  };

  constructor(private router: Router,
              public textFragments : TextFragmentService
  ) { }

  ngOnInit(): void {
    this.textFragments.textFragmentsLanguage(this.ES, this.EN, this.DE);
    this.activeRoute = this.router.url;
  }

  selectMenuItem(item: string) {
    this.activeRoute = item; // Establece el ítem seleccionado
    // Aquí puedes agregar lógica adicional según el ítem seleccionado si es necesario
    switch(item) {
      case 'Home':
        this.toHome();
        break;
      case 'My Products':
        this.toProducts();
        break;
      case 'Subscription Plans':
        this.toSubscriptions();
        break;
      case 'Settings':
        this.toSettings();
        break;
      default:
        // Lógica por defecto si es necesario
    }
  }

  toHome() {
    this.router.navigateByUrl("/main/home")
  }

  toProducts() {
    this.router.navigateByUrl("/main/products")
  }

  toSettings() {
    this.router.navigateByUrl("/main/settings/0")
  }

  toProductInfo() {
    this.router.navigateByUrl("/main/product-info-view/0")
  }

  toSubscriptions() {
    this.router.navigateByUrl("/main/subscriptions")
  }

}
