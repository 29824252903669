import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatTableModule } from '@angular/material/table';
import { MatSliderModule } from '@angular/material/slider';
import { MatPaginatorModule } from '@angular/material/paginator';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatRadioModule} from '@angular/material/radio';
import { MatDialogModule } from '@angular/material/dialog';

import { MatExpansionModule } from '@angular/material/expansion';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatMenuModule} from '@angular/material/menu';
import {MatTabsModule} from '@angular/material/tabs';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { SharedModule } from '../shared/shared.module';
import { SharedGenericModule } from '../shared-generic/shared-generic.module';
import { UiSupplierRoutingModule } from './ui-main-routing.module';
import { NgxSpinnerModule } from "ngx-spinner";
import { UserManagementViewComponent } from './views/user-management-view/user-management-view.component';
import { HomeComponent } from './views/home/home.component';
import { ProductInfoViewComponent } from './views/product-info-view/product-info-view.component';
import { ProductViewComponent } from './views/product-view/product-view.component';
import { SubscriptionPlansViewComponent } from './views/subscription-plans-view/subscription-plans-view.component';
import { LoginComponent } from './views/login/login.component';
import { RegisterComponent } from './views/register/register.component';
import { CustomerPaymentsProfileComponent } from './components/customer-payments-profile/customer-payments-profile.component';
import { SettingsComponent } from './views/settings/settings.component';
import { PasswordRecoverComponent } from './views/password-recover/password-recover.component';
import { RecoverComponent } from './views/recover/recover.component';


@NgModule({
  imports: [
    UiSupplierRoutingModule,
    MatCardModule,
    MatRadioModule,
    MatDialogModule,
    MatIconModule,
    MatFormFieldModule,
    MatCheckboxModule,
    MatTooltipModule,
    FormsModule,
    CommonModule,
    MatInputModule,
    MatButtonModule,
    MatTableModule,
    MatSliderModule,
    MatProgressSpinnerModule,
    MatPaginatorModule,
    MatSidenavModule,
    MatToolbarModule,
    MatExpansionModule,
    MatProgressBarModule,
    MatMenuModule,
    MatTabsModule,
    SharedModule,
    SharedGenericModule,
    DragDropModule,
    NgxSpinnerModule,
  ],
  declarations: [
    UserManagementViewComponent,
    HomeComponent,
    ProductViewComponent,
    ProductInfoViewComponent,
    SubscriptionPlansViewComponent,
    LoginComponent,
    RecoverComponent,
    RegisterComponent,
    CustomerPaymentsProfileComponent,
    SettingsComponent,
    PasswordRecoverComponent
  ],
  exports: [
    SettingsComponent,
    NgxSpinnerModule
  ],
  providers: [
  ],
})
export class UiMainModule { }
