<div class="flex flex-row justify-center m-5">
    <div class="p-4 border rounded-md w-[450px] m-2">
      <p class="text-lg mb-4 text-center">{{this.textFragments.textFragmentsLanguage(this.ES, this.EN, this.DE)?.enter}}</p>
      <form (ngSubmit)="onSubmit(paymentForm)" #paymentForm="ngForm">
        <div>
          <div class="mb-4">
            <input [placeholder]="this.textFragments.textFragmentsLanguage(this.ES, this.EN, this.DE)?.cardholderName" type="text" id="cardholder-name" name="cardholderName" [(ngModel)]="cardholderName" class="mt-1 p-2 block w-full border-2 rounded-md shadow-sm focus:outline-none focus:border-blue-500 h-10">
          </div>
        </div>
        <div id="card-element" class="mt-1 p-2 block w-full border-2 rounded-md shadow-sm focus:outline-none focus:border-blue-500 mb-6"></div>
        <div id="card-errors" role="alert"></div>
        <div>
        <button mat-stroked-button id="submit-button" type="submit" [disabled]="loading">
            {{this.textFragments.textFragmentsLanguage(this.ES, this.EN, this.DE)?.submit}}
        </button>
        </div>
      </form>
      <button (click)="this.close()" class="left-[90px] bottom-[36px]" mat-stroked-button [disabled]="loading">
        {{this.textFragments.textFragmentsLanguage(this.ES, this.EN, this.DE)?.cancel}}
      </button>

    </div>
</div>
  
  