import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TextFragmentService } from 'src/app/ui-main/services/text-fragment.service';

@Component({
  selector: 'app-payment-dialog',
  templateUrl: './payment-dialog.component.html',
  styleUrls: ['./payment-dialog.component.scss']
})
export class PaymentDialogComponent implements OnInit {

  EN = {
    header: "Payment Confirmation",
    selectedPlan: "Selected Plan",
    startingDate: "Starting Date",
    subTotal: "Sub total",
    tax: "Tax",
    grandTotal: "Grand Total",
    notNow: "Not Now",
    payNow: "Pay Now",
  };

  ES = {
    header: "Confirmación de pago",
    selectedPlan: "Plan seleccionado",
    startingDate: "Fecha de inicio",
    subTotal: "Sub total",
    tax: "Impuesto",
    grandTotal: "Gran total",
    notNow: "Ahora no",
    payNow: "Pagar ahora",
  };

  DE = {
    header: "Zahlungsbestätigung",
    selectedPlan: "Ausgewählter Plan",
    startingDate: "Startdatum",
    subTotal: "Zwischensumme",
    tax: "Steuer",
    grandTotal: "Gesamtsumme",
    notNow: "Nicht jetzt",
    payNow: "Jetzt bezahlen",
  }

  constructor(public dialogRef: MatDialogRef<PaymentDialogComponent>, 
              @Inject(MAT_DIALOG_DATA) public data: any,
              public textFragments : TextFragmentService) {

  }
  ngOnInit(): void {
    this.textFragments.textFragmentsLanguage(this.ES, this.EN, this.DE);
  }
 

  onNoClick(): void {
    this.dialogRef.close({"approved": false});
  }

  onYesClick(): void {
    this.dialogRef.close({"approved": true});
  }
}
