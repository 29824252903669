<div class="bg-blue-100 rounded-lg shadow px-5 py-4">
  <div class="flex justify-between items-center">
    <div>
      <span class="text-sm font-semibold text-gray-700">{{this.textFragments.textFragmentsLanguage(this.ES, this.EN, this.DE)?.statistics}}</span>
    </div>
    <div>
      <span class="text-sm font-semibold">{{this.textFragments.textFragmentsLanguage(this.ES, this.EN, this.DE)?.data}}</span>
    </div>
  </div>
  <!-- Content -->
</div>

