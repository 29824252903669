
  <div class="flex flex-1 lg:px-[8%] h-full">
    <!-- SideMenu -->
    <aside class="w-64 h-full flex-shrink-0">
      <app-side-menu></app-side-menu>
    </aside>
    <!--h-[85vh] to be avoided!!!-->
    <div
      class="flex flex-1 flex-col items-center w-full h-full p-4"
    >
      <section class="flex items-center flex-row justify-around w-full h-full">
        <div class="flex flex-col items-center justify-start w-full h-full gap-16 select-none overflow-auto">
          
          <div class="w-full flex flex-col items-center">
            <app-create-product-button (onBookProduct)="bookProduct($event)" [leftover_product_number]="leftover_product_number" class="my-4 w-4/5 bg-zinc-100 rounded-3xl border-4 border-dashed border-vistrace-primary"></app-create-product-button>
            <ul class="flex flex-col gap-8 w-4/5">
              <li
                class="rounded-lg h-44 hover:drop-shadow-xl"
                [ngClass]="{'drop-shadow-2xl': product.id == selectedProductId}"
                *ngFor="let product of products"
              >
                <app-product-info  
                  (previewAction)="launchPreview($event)" 
                  (deleteProductAction)="deleteProduct($event)"
                  (cloneProductAction)="copyProduct($event)"
                  [product]="product"
                  [productsLeft]="leftover_product_number"></app-product-info>
              </li>
            </ul>
          </div>
          
        </div>
        <div class="flex justify-around w-1/2">
          
          <app-mobile-preview [mobile_url]="mobile_url"></app-mobile-preview>

        </div>
      </section>
    </div>
  </div>

