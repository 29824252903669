import { Component, OnDestroy, OnInit } from '@angular/core';
import { Stripe } from '@stripe/stripe-js';
import { StripeService } from '../../services/stripe-service';
import { NgForm } from '@angular/forms';
import { MessageService } from 'src/app/shared-generic/services/message.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { Router } from '@angular/router';
import { TextFragmentService } from '../../services/text-fragment.service';

const style = {
  base: {
      color: '#32325d',
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: 'antialiased',
      fontSize: '16px',
      '::placeholder': {
          color: '#aab7c4',
      },
  },
  invalid: {
      color: '#fa755a',
      iconColor: '#fa755a',
  },
};

@Component({
  selector: 'app-customer-payments-profile-component',
  templateUrl: './customer-payments-profile.component.html',
  styleUrls: ['./customer-payments-profile.component.scss']
})
export class CustomerPaymentsProfileComponent implements OnInit, OnDestroy {
  cardErrors: any;
  cardElement: any;
  cardholderName = "";
  loading = false;
  private subscriptions: Array<Subscription> = [];
  private stripe: Stripe | null = null;

  EN = {
    enter : 'Enter Your Payment Details',
    submit : 'Submit',
    cardholderName : 'Cardholder Name',
  }

  ES = {
    enter : 'Introduce tus datos de pago',
    submit : 'Enviar',
    cardholderName : 'Titular de la tarjeta',
  }

  DE = {
    enter : 'Geben Sie Ihre Zahlungsdetails ein',
    submit : 'Einreichen',
    cardholderName : 'Karteninhaber',
  }

  constructor(
    private stripeService: StripeService,
    private messageService: MessageService,
    private spinnerService: NgxSpinnerService,
    private router: Router,
    public textFragments : TextFragmentService
    ) { }

  
  async ngOnInit() {
    this.textFragments.textFragmentsLanguage(this.ES, this.EN, this.DE);
    this.subscriptions.push(
      this.messageService
        .awaitMessage('main')
        .pipe(filter((msg) => msg.name === "finishHandlePayment"))
        .subscribe((msg) => {
          this.spinnerService.hide();
          this.router.navigateByUrl(msg.args.endpoint);
        }));

    this.stripe = this.stripeService.getStripe();
    //console.log(this.stripe);
    if (this.stripe) {
      const elements = this.stripe.elements({
        locale: 'en' // Specify English locale
      });
      this.cardElement = elements.create('card', {style, hidePostalCode: true, });
      this.cardElement.mount('#card-element');
      this.cardElement.on('change', (event: any) => {
        this.cardErrors = event.error ? event.error.message : '';
      });
    }
  } 

  async onSubmit(form: NgForm) {
    if (form.invalid) {
      return;
    }
    this.spinnerService.show();
    this.loading = true;
    const { cardholderName } = form.value;
    const { paymentMethod, error } = await this.stripe.createPaymentMethod({
      type: 'card',
      card: this.cardElement,
      billing_details: {
        name: cardholderName,
      },
    });
    this.loading = false;
    if (error) {
      this.cardErrors = error.message;
    } else {
      this.handlePaymentMethod(paymentMethod);
    }
  }

  handlePaymentMethod(paymentMethod: any) {
    const callbackId = "finishHandlePayment"
    const msg = {
      name: 'handlePaymentMethod',
      args: [paymentMethod, callbackId]
    };
    this.messageService.sendMsg(msg, 'main');
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subs => subs.unsubscribe());
  }
}