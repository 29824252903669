import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { Subscription } from 'rxjs/internal/Subscription';

@Component({
  selector: 'app-checkbox-dialog',
  templateUrl: './checkbox-dialog.component.html',
  styleUrls: ['./checkbox-dialog.component.scss']
})
export class CheckboxDialogComponent implements OnInit {

  constructor(
    private dialogRef: MatDialogRef<CheckboxDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, // required: title, name, [{label, checked}]
  ) { }

  ngOnInit(): void {
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  onOk() {
    // do not call this.dialogRef.close(); as this is called via [mat-dialog-close] in html
  }

}
