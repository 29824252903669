import { Injectable } from '@angular/core';

const px_width_criteria = 1680;
const px_height_criteria = 1000;
const px_table_height_criteria = 650;
// 100px is the height of addimap-infobar-top
const addimap_infobar_top_height = 115;
const addiplan_toolbar_top_height = 70;
const addiplan_mat_card_header_height = 52;


@Injectable({
  providedIn: 'root'
})
export class LayoutService {
  public localWindowInnerHeight = window.innerHeight; //858;
  public localWindowInnerWidth = window.innerWidth;   //1500
  // without toolbar
  public mainContentHeight = this.localWindowInnerHeight - addiplan_toolbar_top_height;
  public mainContentHeightWoTabHeader = this.localWindowInnerHeight - 151;// 70+71
  // NOTE: the heights for a single or doubled row in generic-form can change if we change the layout of generic-form
  // without single row generic-form
  public woSingleRowGenericFormHeight = this.mainContentHeight - 117 - addiplan_mat_card_header_height;
  // without doubled row generic-form
  public woTwoRowGenericFormHeight = this.mainContentHeight - 186 - 26 - addiplan_mat_card_header_height;
  public mainContentHeightWoTabHeaderFooter = this.localWindowInnerHeight - 195;// 70+71
  public tableHeight = this.localWindowInnerHeight - 350;
  public tableTitleHeight = this.localWindowInnerHeight - 370;
  public contentCardFooter = this.localWindowInnerHeight - 195;// 70+71
  public infoCardsCommissioning = this.localWindowInnerHeight - 618;
  public calendarCommissioning = this.localWindowInnerHeight - 220;//205;
  public tableActiveDashboard = this.localWindowInnerHeight - 280;
  // AddiMap specific
  public contentHeightAddiMap = this.localWindowInnerHeight - addimap_infobar_top_height;
  public contentHeightAddiMapWithoutMargin = this.localWindowInnerHeight - addimap_infobar_top_height -50;
  public popUpTabHeight = this.localWindowInnerHeight - 100;

  public wideScreen = false;
  public iosScreen = false;
  public smallestScreen = false;
  public logoScale = 0.85;
  public logoLeft = 45;
  public flagScale = 1;
  public worldScale = 1.3;
  private px_width_criteria = px_width_criteria;
  private px_height_criteria = px_height_criteria;
  public constructor() {
    if (this.mainContentHeight > px_height_criteria) {
      this.wideScreen = true;
    } else {
      this.wideScreen = false;
    }
    if (this.localWindowInnerWidth < 800) {
      this.iosScreen = true;
    } else {
      this.iosScreen = false;
    }
    if (this.localWindowInnerHeight > px_table_height_criteria){
      this.smallestScreen = false;
    }else{
      this.smallestScreen = true;
    }
    const x = this.localWindowInnerWidth;
    this.logoScale = Math.min(this.localWindowInnerWidth/2200, 0.7);
    this.worldScale = Math.min(this.localWindowInnerWidth/1600, 1.3);
    this.flagScale = Math.min(this.localWindowInnerWidth/1000, 1);
    if (x>1000) {
      this.logoLeft = Math.min(
        45,
        (x-1000)*(45-40)/(1800-1000)+40
      );
    } else {
      this.logoLeft = Math.min(
        40,
        (x-800)*(40-32)/(1000-800)+32
      );
    }
  }

  public resizeWindow(event) {
    this.localWindowInnerHeight = event.target.innerHeight; // - xyz ??
    this.localWindowInnerWidth = event.target.innerWidth; // - xyz ??
    // without toolbar
    this.mainContentHeight = this.localWindowInnerHeight - addiplan_toolbar_top_height;
    this.mainContentHeightWoTabHeader = this.localWindowInnerHeight - 151;// 70+71
    // NOTE: the heights for a single or doubled row in generic-form can change if we change the layout of generic-form
    // without single row generic-form
    this.woSingleRowGenericFormHeight = this.mainContentHeight - 117 - addiplan_mat_card_header_height;
    // without doubled row generic-form
    this.woTwoRowGenericFormHeight = this.mainContentHeight - 186 - 26 - addiplan_mat_card_header_height;
    this.mainContentHeightWoTabHeaderFooter = this.localWindowInnerHeight - 195;// 70+71
    this.tableHeight = this.localWindowInnerHeight - 350;
    this.tableTitleHeight = this.localWindowInnerHeight - 370;
    this.contentCardFooter = this.localWindowInnerHeight - 195;// 70+71
    this.infoCardsCommissioning = this.localWindowInnerHeight - 618;
    this.calendarCommissioning = this.localWindowInnerHeight - 220;//205;
    this.tableActiveDashboard = this.localWindowInnerHeight - 280;
    // AddiMap specific
    this.contentHeightAddiMap = this.localWindowInnerHeight - addimap_infobar_top_height;
    this.contentHeightAddiMapWithoutMargin = this.localWindowInnerHeight - addimap_infobar_top_height -50;
    this.popUpTabHeight = this.localWindowInnerHeight - 100;
    if (this.mainContentHeight > px_height_criteria) {
      this.wideScreen = true;
    } else {
      this.wideScreen = false;
    }
    if (this.localWindowInnerWidth < 800) {
      this.iosScreen = true;
    } else {
      this.iosScreen = false;
    }
    if (this.localWindowInnerHeight > px_table_height_criteria){
      this.smallestScreen = false;
    }else{
      this.smallestScreen = true;
    }
    const x = this.localWindowInnerWidth;
    this.logoScale = Math.min(this.localWindowInnerWidth/2200, 0.7);
    this.worldScale = Math.min(this.localWindowInnerWidth/1600, 1.3);
    this.flagScale = Math.min(this.localWindowInnerWidth/1000, 1);
    if (x>1000) {
      this.logoLeft = Math.min(
        45,
        (x-1000)*(45-40)/(1800-1000)+40
      );
    } else {
      this.logoLeft = Math.min(
        40,
        (x-800)*(40-32)/(1000-800)+32
      );
    }
  }
  
  public isEllipsisActive(e: HTMLElement): boolean {
    return e ? (e.offsetWidth < e.scrollWidth) : false;
  }

}
