<div class="w-full h-full rounded-xl skeleton-container">
  <!-- Skeleton para la barra superior -->
  <div class="skeleton-header">
    <div class="skeleton-icon"></div>
    <div class="skeleton-title"></div>
    <div class="skeleton-lang"></div>
  </div>

  <div class="skeleton-image"></div>

  <div class="skeleton-buttons">
    <div class="skeleton-button"></div>
    <div class="skeleton-button"></div>
    <div class="skeleton-button"></div>
    <div class="skeleton-button"></div>
  </div>
</div>
