import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TextFragmentService } from 'src/app/ui-main/services/text-fragment.service';

@Component({
  selector: 'app-clone-product-dialog',
  templateUrl: './clone-product-dialog.component.html',
  styleUrls: ['./clone-product-dialog.component.scss']
})
export class CloneProductDialogComponent implements OnInit {

  EN = {
    sure : 'Are you sure you want to clone the product?:',
    clone : 'Clone',
  }

  ES = {
    sure : '¿Seguro de que quieres clonar el producto?:',
    clone : 'Clonar',
  }

  DE = {
    sure : 'Möchten Sie das Produkt wirklich klonen?:',
    clone : 'Klonen',
  }

  constructor(public dialogRef: MatDialogRef<CloneProductDialogComponent>, 
              @Inject(MAT_DIALOG_DATA) public data: any,
              public textFragments : TextFragmentService) {

  }
  ngOnInit(): void {
    this.textFragments.textFragmentsLanguage(this.ES, this.EN, this.DE);
  }
 

  onNoClick(): void {
    this.dialogRef.close();
  }
}
