  
    <div class="flex flex-1 lg:px-[8%] h-full">
      <!-- SideMenu -->
      <aside class="w-64 h-full flex-shrink-0">
        <app-side-menu></app-side-menu>
      </aside>
      
      <div class="w-full h-full overflow-auto pl-4 flex flex-col items-center">
      <!--h2 class="text-center font-bold text-3xl mt-4 mb-2">Choose your plan</h2-->

        <h2 class="text-xl mt-4"><span class="font-bold">{{this.textFragments.textFragmentsLanguage(this.ES, this.EN, this.DE)?.enhancedSecurity}}:</span> {{this.textFragments.textFragmentsLanguage(this.ES, this.EN, this.DE)?.disclaimer}}</h2>
        <h2 class="text-xl"><span class="font-bold">{{this.textFragments.textFragmentsLanguage(this.ES, this.EN, this.DE)?.flexibleExpansion}}:</span> {{this.textFragments.textFragmentsLanguage(this.ES, this.EN, this.DE)?.addMore}}.</h2>
        <h2 class="text-xl mb-2"><span class="font-bold">{{this.textFragments.textFragmentsLanguage(this.ES, this.EN, this.DE)?.eLabelTitle}}:</span> {{this.textFragments.textFragmentsLanguage(this.ES, this.EN, this.DE)?.eLabelService}}.</h2>

        <section class="flex flex-row gap-8 flex-wrap pt-3">
            <app-subscription-card class="mt-2 mb-5" [updateTrigger]="updateTrigger" subscriptionType="basic" (activate)="activateSubscription()" [oneActive]="oneActive" [status]="subs[0]?.status" [possible]="subs[0]?.possible" [activated]="activatedPlan==subs[0]?.service_id" [subscriptionDesc]="subs[0]"></app-subscription-card>
            <app-subscription-card class="mt-2 mb-5" [updateTrigger]="updateTrigger" subscriptionType="advanced" (activate)="activateSubscription()" [oneActive]="oneActive" [status]="subs[1]?.status" [possible]="subs[1]?.possible" [activated]="activatedPlan==subs[1]?.service_id" [raised]="true" [subscriptionDesc]="subs[1]"></app-subscription-card>
            <app-subscription-card class="mt-2 mb-5" [updateTrigger]="updateTrigger" subscriptionType="premium" (activate)="activateSubscription()" [oneActive]="oneActive" [status]="subs[2]?.status" [possible]="subs[2]?.possible" [activated]="activatedPlan==subs[2]?.service_id" [subscriptionDesc]="subs[2]"></app-subscription-card>
            <!--app-subscription-card class="mt-2 mb-4" subscriptionType="enterprise" [status]="subs[3]?.status" [subscriptionDesc]="subs[3]"></app-subscription-card-->
        </section>

          <!--button class="mb-5 mt-[-20px] bg-vistrace-primary hover:bg-vistrace-hover-dark text-white z-10 font-bold py-2 px-4 rounded"
            [disabled]="!selectedPlan" 
            [matTooltipDisabled]="selectedPlan" 
            [matTooltip]="'Select Plan first'"
            (click)="activteSubscription()"
            [ngClass]="[!selectedPlan ? 'disabled-button' : '']"
          >
          {{this.textFragments.textFragmentsLanguage(this.ES, this.EN, this.DE)?.payment}}
          </button-->
      </div>
    </div>

  