import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TextFragmentService } from 'src/app/ui-main/services/text-fragment.service';

@Component({
  selector: 'app-modal-unsubscribe-confirmation',
  templateUrl: './modal-unsubscribe-confirmation.component.html',
  styleUrls: ['./modal-unsubscribe-confirmation.component.scss']
})
export class ModalUnsubscribeConfirmationComponent {

  EN = {
    title: "Beware",
    message: "Are you sure you want to unsubscribe?",
    confirm : "Confirm",
    cancel : "Cancel",
  }

  ES = {
    title: "Atención",
    message: "¿Está seguro de que desea darse de baja?",
    confirm : "Confirmar",
    cancel : "Cancelar",
  }

  DE = {
    title: "Achtung",
    message: "Möchten Sie sich wirklich abmelden?",
    confirm : "Bestätigen",
    cancel : "Abbrechen",
  }

  constructor(
    private dialogRef: MatDialogRef<ModalUnsubscribeConfirmationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public textFragments : TextFragmentService
  ) { }

  ngOnInit(): void {
    this.textFragments.textFragmentsLanguage(this.ES, this.EN, this.DE);
  }

  cancel(): void {
    this.dialogRef.close("cancel");
  }

  confirm(): void {
    this.dialogRef.close("accept");
  }

}
