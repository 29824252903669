import { Component, OnInit, Input, OnDestroy, SimpleChanges } from '@angular/core';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { BokehService } from '../../services/bokeh.service';
import { DropService } from '../../services/drop-service';

@Component({
    selector: 'app-chart-data-field',
    templateUrl: './chart-data-field.component.html',
    styleUrls: ['./chart-data-field.component.scss']
})
export class ChartDataFieldComponent implements OnInit, OnDestroy {
    @Input() chartFieldId = 'default';
    @Input() targetFieldId = 'default';
    @Input() backend = 'offer-creator';
    @Input() frozen: boolean = false;
    @Input() headerless: boolean = false;
    @Input() replot = '';
    @Input() updateAll = '';
    @Input() noicon: boolean = false;
    @Input() height= "450";

    public wh = window.innerHeight;
    public chartFieldData = {};
    public subs: Subscription[] = [];
    constructor(
      private bokehService: BokehService,
      private dropService: DropService) { }

    ngOnInit() {
        this.subs.push(this.bokehService.getChartFieldData(this.chartFieldId, this.backend).pipe(filter(msg => msg.args.chartFieldId === this.chartFieldId))
            .subscribe(msg => {
                if (!msg.args.chartFieldData) {
                    return;
                }
                console.log('chartFieldData...', msg);
                this.chartFieldData = msg.args.chartFieldData;
                this.dropService.targetIds.next(this.chartFieldData);
            }));
    }
    getCols() {
        return Object.keys(this.chartFieldData);
    }
    onAction($event) {
        console.log($event);
        const actionArray = $event.split(' ');
        if (actionArray[0] === 'delete') {
            const colId = actionArray[1];
            const delIdx = this.chartFieldData[colId].indexOf(actionArray[2]);
            // console.log()
            if (delIdx > -1) {
                this.chartFieldData[colId].splice(delIdx, 1);
            }
        }
        console.log(this.chartFieldData);
    }

    ngOnDestroy() {
        this.subs.forEach(subs => subs.unsubscribe());
    }
  
    ngOnChanges(changes: SimpleChanges) {
      // for (let propName in changes) {
      //   if(propName === "replot"  && this.chartFieldId !== 'default' && this.replot !='') {
      //     console.log("replot ChartDataFieldComponent", this.chartFieldId);
      //   }
      // }
    }
}
