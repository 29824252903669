<div class="user-main">
  <mat-card appearance="outlined" class="base">
    <app-styled-button class="back-button" [colorStyle]="'brand'" [title]="'Back'" [isFilled]="false"
      (action)="backHome()" [width]="'105'" [iconName]="'keyboard_arrow_left'">
    </app-styled-button>
    <mat-card-content style="margin-top: 10px;">
      <mat-tab-group [selectedIndex]="viewService.activeTab" [mat-stretch-tabs]="false">
        <mat-tab label="User">
          <div class="bottom-panel"
            [ngStyle]="{'height.px': layoutService.contentCardFooter, 'overflow': 'hidden auto'}">
            <app-database-table 
              [xname]="user_table" 
              [onFilter]="true" 
              [backend]="backend" 
              [singleSelection]="true"
              [onEditor]="false" 
              [page_size]="layoutService.wideScreen ? wideScreen_paginator : paginator">
            </app-database-table>
            <div class="button-add">
              <app-styled-button 
                [colorStyle]="'brand'" 
                [title]="'Add User'" 
                [isFilled]="false"
                (action)="addUser()" 
                [width]="'200'" 
                [iconName]="'add_circle_outline'">
              </app-styled-button>
              <app-styled-button 
                [colorStyle]="'brand'" 
                [title]="'Delete User'" 
                [isFilled]="false"
                (action)="deleteUser()" 
                [width]="'200'" 
                [iconName]="'delete'" 
                [disabled]="!rowSelectedUser">
              </app-styled-button>
            </div>
            <mat-card-content *ngIf="rowSelectedUser" class="input-user">
              <!--- form displaying data of an exisitng (selected in the table above) offer -->
              <app-generic-form class="input-user-form" 
                [xname]="formNameUser" 
                [backend]="backend" 
                [titlesize]="14"
                [fontsize]="14" 
                [entity]="userKey" 
                [col_number]="4" 
                [infoForm]="false" 
                [frozen]="true" 
                [init]="init"
                [updateTrigger]="updateTriggerUserForm">
              </app-generic-form>
            </mat-card-content>
          </div>
        </mat-tab>
      <mat-tab label="Invoice">
        <div class="bottom-panel"
          [ngStyle]="{'height.px': layoutService.contentCardFooter, 'overflow': 'hidden auto'}">
          <app-database-table 
            [xname]="invoice_table" 
            [onFilter]="true" 
            [backend]="backend" 
            [singleSelection]="true"
            [onEditor]="false"
            [row_key]="'invoice_number'"
            [page_size]="layoutService.wideScreen ? wideScreen_paginator : paginator">
        </app-database-table>
        <div class="button-add">
          <app-styled-button 
            [colorStyle]="'brand'" 
            [title]="'Download latest'" 
            [isFilled]="false"
            (action)="download_all()" 
            [width]="'200'" 
            [iconName]="'add_circle_outline'">
          </app-styled-button>
        </div>
        </div>
      </mat-tab>
      <mat-tab label="Customer">
        <div class="bottom-panel"
          [ngStyle]="{'height.px': layoutService.contentCardFooter, 'overflow': 'hidden auto'}">
          <app-database-table 
            [xname]="customer_table" 
            [onFilter]="true" 
            [backend]="backend" 
            [singleSelection]="true"
            [onEditor]="false" 
            [page_size]="layoutService.wideScreen ? wideScreen_paginator : paginator">
        </app-database-table>
        <div class="button-add">
          <app-styled-button 
            [colorStyle]="'brand'" 
            [title]="'Delete Customer'" 
            [isFilled]="false"
            (action)="deleteCustomer()" 
            [width]="'200'" 
            [iconName]="'delete'" 
            [disabled]="!rowSelectedCustomer">
          </app-styled-button>
        </div>
          <mat-card-content *ngIf="rowSelectedCustomer" class="input-user">
            <!--- form displaying data of an exisitng (selected in the table above) offer -->
            <app-generic-form class="input-user-form" 
              [xname]="formNameCustomer" 
              [backend]="backend" 
              [titlesize]="14"
              [fontsize]="14" 
              [entity]="customerKey" 
              [col_number]="4" 
              [infoForm]="false" 
              [frozen]="true" 
              [init]="init"
              [updateTrigger]="updateTriggerCustomerForm">
            </app-generic-form>
          </mat-card-content>
        </div>
      </mat-tab>

      <mat-tab label="Voucher">
        <div class="bottom-panel"
          [ngStyle]="{'height.px': layoutService.contentCardFooter, 'overflow': 'hidden auto'}">
          <app-database-table 
            [xname]="voucher_table" 
            [onFilter]="true" 
            [backend]="backend" 
            [singleSelection]="true"
            [onEditor]="false" 
            [page_size]="layoutService.wideScreen ? wideScreen_paginator : paginator">
        </app-database-table>
        <div class="button-add">
          <app-styled-button 
            [colorStyle]="'brand'" 
            [title]="'Add Voucher'" 
            [isFilled]="false"
            (action)="addVoucher()" 
            [width]="'200'" 
            [iconName]="'add_circle_outline'" >
          </app-styled-button>
          <app-styled-button 
            [colorStyle]="'brand'" 
            [title]="'Delete Voucher'" 
            [isFilled]="false"
            (action)="deleteVoucher()" 
            [width]="'200'" 
            [iconName]="'delete'" >
          </app-styled-button>
          <app-styled-button 
            [colorStyle]="'brand'" 
            [title]="'Send Mail'" 
            [isFilled]="false"
            (action)="sendVoucherMail()" 
            [width]="'200'" 
            [iconName]="'delete'" >
          </app-styled-button>
        </div>
          <mat-card-content *ngIf="rowSelectedVoucher" class="input-user">
            <!--- form displaying data of an exisitng (selected in the table above) offer -->
            <app-generic-form class="input-user-form" 
              [xname]="formNameVoucher" 
              [backend]="backend" 
              [titlesize]="14"
              [fontsize]="14" 
              [entity]="voucherKey" 
              [col_number]="4" 
              [infoForm]="false" 
              [frozen]="true" 
              [init]="init"
              [updateTrigger]="updateTriggerVoucherForm">
            </app-generic-form>
          </mat-card-content>
        </div>
      </mat-tab>

      <mat-tab label="Products">
        <div class="bottom-panel"
          [ngStyle]="{'height.px': layoutService.contentCardFooter, 'overflow': 'hidden auto'}">
          <app-database-table 
            [xname]="product_table" 
            [onFilter]="true" 
            [backend]="backend" 
            [singleSelection]="true"
            [onEditor]="false" 
            [row_key]="'product_key'"
            [page_size]="layoutService.wideScreen ? wideScreen_paginator : paginator">
        </app-database-table>

          <mat-card-content *ngIf="rowSelectedProduct" class="input-user">
            <!--- form displaying data of an exisitng (selected in the table above) offer -->
            <app-generic-form class="input-user-form" 
              [xname]="formNameProduct" 
              [backend]="backend" 
              [titlesize]="14"
              [fontsize]="14" 
              [entity]="productKey" 
              [col_number]="4" 
              [infoForm]="false" 
              [frozen]="true" 
              [init]="init"
              [updateTrigger]="updateTriggerProductForm">
            </app-generic-form>
          </mat-card-content>
        </div>
      </mat-tab>
    </mat-tab-group>
    </mat-card-content>
  </mat-card>
</div>