import { Component,Input, OnInit, OnDestroy } from '@angular/core';
import { FilterOption } from './../../types/filter-option';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { SimpleFilterService } from '../../services/simple-filter.service';

@Component({
  selector: 'app-simple-filter',
  templateUrl: './simple-filter.component.html',
  styleUrls: ['./simple-filter.component.scss']
})
export class SimpleFilterComponent implements OnInit, OnDestroy {

  public filterGroup: FilterOption[] = [];
  public subs: Subscription[] = [];
  @Input() backend = 'offer-creator';

  constructor(private simpleFilterService: SimpleFilterService) { }

  ngOnInit(): void {
    this.subs.push(this.simpleFilterService.getSimpleFilterGroup(this.backend).pipe(filter(msg => msg.args.backend === this.backend))
      .subscribe(
        msg => {
          this.filterGroup = msg.args.filterGroup;
        }));
  }

  public statusChanged(filter: FilterOption):void {
    // console.log("...checkbox...", filter);
    this.simpleFilterService.setSimpleFilterSelectionMessage(this.backend, filter)
  }

  ngOnDestroy() {
    this.subs.forEach( subs => subs.unsubscribe() );
  }

}
