<nav class="bg-vistrace-primary h-20 p-3 text-white flex justify-between items-center">
  <div class="flex items-center lg:px-[10%]">
    <img
      (click)="toLandingPage()"
      class="cursor-pointer"
      src="assets/ProductHubLogo.png"
      alt="Logo"
      class="h-16 mr-2"
    />
  </div>
  <div class="flex items-center lg:px-[8%]" *ngIf="messageService.login">
    <div class="flex flex-row align-middle">
      <div class="pt-1 relative inline-block text-left text-white m-3" (clickOutside)="closeDropdown()">
        <button (click)="toggleDropdown()" class="flex items-center gap-2 border border-gray-300 rounded-lg px-2 py-2 transition hover:bg-vistrace-hover-light">
          <!--img [src]="selectedLanguage.flag" [alt]="selectedLanguage.name" class="w-5 h-3 rounded"-->
          <mat-icon>language</mat-icon>
          {{selectedLanguage.code}}
          <svg class="w-4 h-4 transition-transform" [ngClass]="{'rotate-180': isOpen}" xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path d="M6 9l6 6 6-6" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
          </svg>
        </button>
        <ul *ngIf="isOpen" class="absolute z-10 w-max mt-2 bg-vistrace-primary border border-gray-300 rounded-lg shadow-lg">
          <li *ngFor="let language of languages" (click)="selectLanguage(language)" class="flex items-center gap-2 px-2 py-2 cursor-pointer hover:bg-vistrace-hover-light">
            <img [src]="language.flag" [alt]="language.name" class="w-5 h-3 rounded">
            {{language.name}}
          </li>
        </ul>
      </div>
      <div class="circle text-center m-3" (click)="manageUsers()"
        [ngStyle]="sessionService.isAdmin ? {'cursor': 'pointer'} : {'cursor': 'default'}"
      >
        <span>{{sessionService.session_name}}</span>
      </div>
    <!--img
      src="assets/user_icon.svg"
      class="w-8 h-8 m-4 cursor-pointer"
      alt="lock"
    /-->
    </div>
    <img
      src="assets/logout.svg"
      class="w-8 h-8 m-4 cursor-pointer"
      alt="lock"
      (click)="logout()"
    />
  </div>
</nav>
