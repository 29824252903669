import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { DialogLoginService } from 'src/app/shared-generic/services/dialog-login.service';
import { MessageService } from 'src/app/shared-generic/services/message.service';
import { Dictionary } from 'src/app/shared-generic/types/dictionary';

@Injectable({
  providedIn: 'root'
})
export class ViewService {
  public currentApp = "";
  public activeTab = 0;
  public activeNestedTab = 0;
  public activeTemplateTab = 0;
  public gotoSubject: Subject<string> = new Subject();

  
  public default_setting_selected = "/settings/software-info-view";

  constructor(private messageService: MessageService,
    private router: Router,
  ) {

    this.gotoSubject.subscribe( target => {
      if (target==="main") {this.goto_main();}
    });
  }


  goto_main() {
    this.router.navigateByUrl("/main/home");
    this.activeTab = 0;
    this.currentApp = "/main/home";
  }


}
