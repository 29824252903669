import { Component, OnDestroy } from '@angular/core';
import { filter } from 'rxjs/operators';
import { FileUploadService } from 'src/app/shared-generic/services/file-upload.service';
import { MessageService } from 'src/app/shared-generic/services/message.service';

@Component({
  selector: 'app-voice-input',
  templateUrl: './voice-input.component.html',
  styleUrls: ['./voice-input.component.scss']
})
export class VoiceInputComponent implements OnDestroy {
  mediaRecorder: MediaRecorder | undefined;
  isRecording: boolean = false;
  audioChunks: Blob[] = [];

  constructor(
    private fileUploadService: FileUploadService,
    private messageService: MessageService
  ) { }

  async startRecording(): Promise<void> {
    if (!this.isRecording) {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
        this.mediaRecorder = new MediaRecorder(stream);

        this.mediaRecorder.addEventListener('dataavailable', (event) => {
          if (event.data.size > 0) {
            this.audioChunks.push(event.data);
          }
        });

        this.mediaRecorder.addEventListener('stop', () => {
          const audioBlob = new Blob(this.audioChunks, { type: 'audio/mp3' });
          this.uploadMp3(audioBlob);
          this.audioChunks = [];
        });

        this.mediaRecorder.start();
        this.isRecording = true;
      } catch (error) {
        console.error('Error starting recording:', error);
      }
    }
  }

  stopRecording(): void {
    if (this.mediaRecorder && this.isRecording) {
      this.mediaRecorder.stop();
      this.isRecording = false;
    }
  }

  uploadMp3(blob: Blob): void {
    console.log("START UPLOAD....");
    const file = new File([blob], "recording.mp3");
    this.fileUploadService.saveFileAll(file, 'main', 'api-main')
      .pipe(filter(data => data.filename === file.name))
      .subscribe(
        data => {
          const msg = {
            name: 'send_recording',
            args: [data.filename]
          };
          this.messageService.sendMsg(msg);
        },
        error => {
          console.log(error);
        }
      );
  }

  ngOnDestroy(): void {
    if (this.mediaRecorder) {
      this.mediaRecorder.stop();
    }
  }
}