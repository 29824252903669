import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { MessageService } from 'src/app/shared-generic/services/message.service';
import { DashboardTitleService } from '../../services/dashboard-title.service';
import { FormDataService } from 'src/app/shared-generic/services/form-data.service';
import { TextFragmentService } from 'src/app/ui-main/services/text-fragment.service';
import { ModalDeleteBatchButtonComponent } from '../modal-delete-batch-button/modal-delete-batch-button.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, OnDestroy{
  @Input() productId = "";
  //@Input() batchId = "";  // usually points to batch_number
  public product_key = "";
  public batch_keys = [];
  public active_batch_key = "";
  public batch_numbers: any;   // {"key": "number", ...}
  public batch_finalized: any;
  public batch_in_review = false;
  //public currentCollor="black";
  public updateItemsCallbackIdB = "forms/features"
  public updateItemsCallbackIdC = "forms/certs"
  public updateItemsCallbackIdD = "forms/awards"
  public updateItemsCallbackIdE = "forms/transportation"
  public updateItemsCallbackIdF = "forms/sales"
  public featuresProduct: any = [];
  public certsProduct: any = [];
  public awardsProduct: any = [];
  public transportProduct: any = [];
  public salesProduct: any = [];
  public rebuildFormTrigger = 0;
  private subscriptions: Array<Subscription>=[];
  public updateTrigger = "";
  selectedID: string | null = "Product Info"; // the engl title
  formValidSubscription: Subscription;
  formDataChanged: { form: string, status: boolean };
  okEnabled = false;
  isFinalized = false;
  batch_line_width = 100;
  e_label_input = false;

  EN = {
    batch: "Batch",
    productInfo: "Product Information",
    eLabel: "E-Label Information",
    consumerInfo: "Consumer Information",
    tastingNotes: "Tasting Notes",
    addTastingNote: "Add Tasting Note",
    productCertificates: "Product Certificates",
    addCertificate: "Add Certificate",
    productAwards: "Product Awards",
    addAward: "Add Award",
    cultivationInfo: "Cultivation Information",
    productionInfo: "Production Information",
    bottlingInfo: "Bottling Information",
    transportationInfo: "Transportation Information",
    transportationSub: "The consumer app shows the product route as far as available (Cultivation, Production, Bottling, Sales)",
    salesLocations: "Sales Locations",
    addSalesLocation: "Add Sales Location",
    batchInfo: "Batch Information",
    batchInfoSub: "only needed if the consumer app should show batch related info",
    buttonToProduct: "to product data input",
    buttonAddBatch: "add one more batch"
  };

  ES = {
    batch: "Lote",
    productInfo: "Información del Producto",
    eLabel: "E-Label Information",
    consumerInfo: "Consumer Information",
    tastingNotes: "Notas de Cata",
    addTastingNote: "Agregar Nota de Cata",
    productCertificates: "Certificados del Producto",
    addCertificate: "Agregar Certificado",
    productAwards: "Premios del Producto",
    addAward: "Agregar Premio",
    cultivationInfo: "Información de Cultivo",
    productionInfo: "Información de Producción",
    bottlingInfo: "Información de Embotellado",
    transportationInfo: "Información de Transporte",
    transportationSub: "La aplicación del consumidor muestra la ruta del producto en la medida de lo posible (Cultivo, Producción, Embotellado, Ventas)",
    salesLocations: "Ubicaciones de Venta",
    addSalesLocation: "Agregar Ubicación de Venta",
    batchInfo: "Información del Lote",
    batchInfoSub: "solo necesario si la aplicación del consumidor debe mostrar información relacionada con el lote",
    buttonToProduct: "to product data input",
    buttonAddBatch: "add one more batch"
  };

  DE = {
    batch: "Charge",
    productInfo: "Produktinformation",
    eLabel: "E-Label Information",
    consumerInfo: "Consumer Information",
    tastingNotes: "Verkostungsnotizen",
    addTastingNote: "Verkostungsnotiz hinzufügen",
    productCertificates: "Produktzertifikate",
    addCertificate: "Zertifikat hinzufügen",
    productAwards: "Produktauszeichnungen",
    addAward: "Auszeichnung hinzufügen",
    cultivationInfo: "Anbauinformationen",
    productionInfo: "Produktionsinformationen",
    bottlingInfo: "Abfüllinformationen",
    transportationInfo: "Transportinformationen",
    transportationSub: "Die Verbraucher-App zeigt die Produktroute soweit verfügbar an (Anbau, Produktion, Abfüllung, Verkauf)",
    salesLocations: "Verkaufsorte",
    addSalesLocation: "Verkaufsort hinzufügen",
    batchInfo: "Charge Information",
    batchInfoSub: "nur erforderlich, wenn die Verbraucher-App chargenbezogene Informationen anzeigen",
    buttonToProduct: "zurück zur Dateneingabe Produkt",
    buttonAddBatch: "eine weitere Charge hinzufügen"
  }

  constructor(private messageService: MessageService,
              private titleService : DashboardTitleService,
              private formDataService : FormDataService,
              public textFragments : TextFragmentService,
              private dialogRef : MatDialog) { }

  ngOnInit(): void {
    this.textFragments.textFragmentsLanguage(this.ES, this.EN, this.DE);
    this.titleService.selectedID$.subscribe(id => {
          this.selectedID = id;
    });
    this.titleService.activeBatchKey$.subscribe(batch_number => {
      this.active_batch_key = batch_number;
    });
    this.subscriptions.push(
      this.messageService.awaitMessage()
        .pipe(filter(msg => msg.name === "reloadForm"))
        .subscribe( msg => {
          this.e_label_input = msg.args.e_label_input;
          this.rebuildFormTrigger = this.messageService.getRandomInt(1000) + 1;
        })
    );

    this.subscriptions.push(
      this.textFragments.language$.subscribe( ()=> {
        this.updateTrigger = this.messageService.getRandomInt(100000).toString();
      }
    ));

    const callbackId = "set_product_key";
    this.subscriptions.push(
      this.messageService.awaitMessage()
        .pipe(filter(msg => msg.name === callbackId))
        .subscribe( msg => {
          this.product_key = msg.args.product_key;
          this.e_label_input = msg.args.e_label_input;
          this.productId = this.product_key;  // finally the same
          this.titleService.product_key = this.product_key;
          this.isFinalized = msg.args.isFinalized || msg.args.status=="in-review";
          this.titleService.status = msg.args.status;
          const msg_get_I = {
            name: 'getFormItems',
            args: ['product-feature-info-form', this.updateItemsCallbackIdB]
          };
          this.messageService.sendMsg(msg_get_I, 'main');
          const msg_get_II = {
            name: 'getFormItems',
            args: ['product-cert-form', this.updateItemsCallbackIdC]
          };
          this.messageService.sendMsg(msg_get_II, 'main');
          const msg_get_III = {
            name: 'getFormItems',
            args: ['product-award-form', this.updateItemsCallbackIdD]
          };
          this.messageService.sendMsg(msg_get_III, 'main');
          /*
          const msg_get_IV = {
            name: 'getFormItems',
            args: ['product-transport-form', this.updateItemsCallbackIdE]
          };
          this.messageService.sendMsg(msg_get_IV, 'main');
          */
          const msg_get_V = {
            name: 'getFormItems',
            args: ['product-sales-form', this.updateItemsCallbackIdF]
          };
          this.messageService.sendMsg(msg_get_V, 'main');
        }
    ));

    const callbackId_II = "set_batch_keys";
    this.subscriptions.push(
      this.messageService.awaitMessage()
        .pipe(filter(msg => msg.name === callbackId_II))
        .subscribe( msg => {
          this.batch_keys = msg.args.batch_keys;
          this.batch_numbers = msg.args.batch_numbers;
          this.batch_finalized = msg.args.batch_finalized;
          this.batch_in_review = msg.args.batch_in_review;
          this.titleService.batch_numbers = this.batch_numbers;
          this.batch_line_width = 50 + 94 * this.batch_keys.length;
          if (this.batch_keys.length===0) {
            this.titleService.selectedIDSubject.next("Product Info");
            this.titleService.activeBatchKeySubject.next("");
          } else if (this.batch_keys.indexOf(this.active_batch_key)===-1) {
            if (this.selectedID==="Batch") {
              this.titleService.selectedIDSubject.next("Product Info");
            }
            // this.titleService.activeBatchKeySubject.next(this.batch_keys[0]);
          }
          console.log("batch info: ", msg.args)
        })
    );


    const msg = {
      name: 'getProductKey',
      args: [this.productId, callbackId]
    };
    this.messageService.sendMsg(msg, 'main');

    const msg_II = {
      name: 'getBatchKeys',
      args: [this.productId, callbackId_II]
    };
    this.messageService.sendMsg(msg_II, 'main');

    this.formDataService.formValidSubject.subscribe(item => {
      for (const groupName of Object.keys(this.titleService.formStates)) {
        const forms = this.titleService.formStates[groupName];
        const foundForm = forms.find(form => form.form === item.form);
        if (foundForm) {
          foundForm.state = item.status;
        }
      }
    });

    this.subscriptions.push(
      this.messageService.awaitMessage('main').pipe(filter(message => message.name === this.updateItemsCallbackIdB))
          .subscribe(message => {
            if (message.args?.formItems) {
              this.featuresProduct = message.args.formItems;
            }
          }
    ));
    this.subscriptions.push(
      this.messageService.awaitMessage('main').pipe(filter(message => message.name === this.updateItemsCallbackIdC))
          .subscribe(message => {
            if (message.args?.formItems) {
              this.certsProduct = message.args.formItems;
            }
          }
    ));
    this.subscriptions.push(
      this.messageService.awaitMessage('main').pipe(filter(message => message.name === this.updateItemsCallbackIdD))
          .subscribe(message => {
            if (message.args?.formItems) {
              this.awardsProduct = message.args.formItems;
            }
          }
    ));
    this.subscriptions.push(
      this.messageService.awaitMessage('main').pipe(filter(message => message.name === this.updateItemsCallbackIdE))
          .subscribe(message => {
            if (message.args?.formItems) {
              this.transportProduct = message.args.formItems;
            }
          }
    ));
    this.subscriptions.push(
      this.messageService.awaitMessage('main').pipe(filter(message => message.name === this.updateItemsCallbackIdF))
          .subscribe(message => {
            if (message.args?.formItems) {
              this.salesProduct = message.args.formItems;
            }
          }
    ));
  }

  selectProduct() {
    this.titleService.selectedIDSubject.next("Product Info");
    this.titleService.activeBatchKeySubject.next("");
  }

  deleteBatch(event: any, batch_key: string) {
    event.stopPropagation();
    const dialogRef = this.dialogRef.open(ModalDeleteBatchButtonComponent, {
      disableClose: true,
      data: {
        'batchId': this.batch_numbers[batch_key],
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (!result) return 
      
      console.log('The dialog was closed', result);
      const msg = {
        name: 'deleteBatch',
        args: [this.productId, batch_key]
      };
      this.messageService.sendMsg(msg, 'main');
    });
    
  }

  createBatch() {
    const msg = {
      name: 'createBatch',
      args: [this.productId]
    };
    this.messageService.sendMsg(msg, 'main');
  }

  selectBatch(batch_key: string) {
    this.titleService.selectedIDSubject.next("Batch");
    this.titleService.activeBatchKeySubject.next(batch_key);
  }

  add_feature() {
    const msg = {
        name: 'addFormItem',
        args: ["product-feature-info-form", this.updateItemsCallbackIdB]
    };
    this.messageService.sendMsg(msg, 'main');
  }

  add_certificate() {
    const msg = {
        name: 'addFormItem',
        args: ["product-cert-form", this.updateItemsCallbackIdC]
    };
    this.messageService.sendMsg(msg, 'main');
  }

  add_award() {
    const msg = {
        name: 'addFormItem',
        args: ["product-award-form", this.updateItemsCallbackIdD]
    };
    this.messageService.sendMsg(msg, 'main');
  }

  /*
  add_transport() {
    const msg = {
        name: 'addFormItem',
        args: ["product-transport-form", this.updateItemsCallbackIdE]
    };
    this.messageService.sendMsg(msg, 'main');
  }
  */

  add_sales() {
    const msg = {
        name: 'addFormItem',
        args: ["product-sales-form", this.updateItemsCallbackIdF]
    };
    this.messageService.sendMsg(msg, 'main');
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach( (subs) =>{
        subs.unsubscribe();
      }
    );
  }





}
