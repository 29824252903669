import { Component, OnInit } from '@angular/core';
import { Route, Router } from '@angular/router';
import { MessageService } from 'src/app/shared-generic/services/message.service';
import { SessionService } from 'src/app/ui-main/services/session-service';
import { ViewService } from '../../services/view.service';
import { TextFragmentService } from 'src/app/ui-main/services/text-fragment.service';

interface Language {
  name: string;
  flag: string;
  code: string;
}

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss']
})
export class NavBarComponent implements OnInit {
  isOpen = false;
  selectedLanguage: Language = { name: 'English', flag: 'https://upload.wikimedia.org/wikipedia/commons/a/a4/Flag_of_the_United_States.svg', code: 'EN' };
  languages: Language[] = [
    { name: 'English', flag: 'https://upload.wikimedia.org/wikipedia/en/a/ae/Flag_of_the_United_Kingdom.svg', code: 'EN' },
    { name: 'Spanish', flag: 'https://upload.wikimedia.org/wikipedia/commons/9/9a/Flag_of_Spain.svg', code: 'ES' },
    { name: 'German', flag: 'https://upload.wikimedia.org/wikipedia/en/b/ba/Flag_of_Germany.svg', code: 'DE' },
  ];


  constructor(
    private router: Router,
    public messageService: MessageService,
    public sessionService: SessionService,
    private textFragmentService: TextFragmentService
  ) { }

  ngOnInit(): void {
    this.textFragmentService.language$.subscribe( languageCode=> {
      this.selectedLanguage = this.languages.filter(ll=> ll.code==languageCode)[0];
    });
  }

  toLandingPage() {
    window.open("https://vistrace.quantecdc.es", "_blank");
  }

  manageUsers() {
    if (this.sessionService.isAdmin) {
      this.router.navigateByUrl("/main/user-management")
    }
  }

  logout() {
    this.messageService.disconnect();
  }


  toggleDropdown() {
    this.isOpen = !this.isOpen;
  }

  closeDropdown() {
    this.isOpen = false;
  }

  selectLanguage(language: Language) {
    this.selectedLanguage = language;
    this.textFragmentService.setLanguage(language.code); // Actualiza el idioma en el servicio
    this.closeDropdown();
    const msg = {
      name: 'set_default_language',
      args: [this.selectedLanguage.code]
    };
    this.messageService.sendMsg(msg, 'main');
  }

  

}
