import { Injectable } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { filter } from 'rxjs/operators';
import { MessageService } from 'src/app/shared-generic/services/message.service';
import { UiInfoService } from 'src/app/shared-generic/services/ui-info-service';
import { TextFragmentService } from './text-fragment.service';

@Injectable({
  providedIn: 'root'
})
export class SessionService {
  public session_name = "Martin";
  public isAdmin = false;
  constructor(
    private messageService: MessageService,
    private uiInfoService: UiInfoService,
    private spinnerService: NgxSpinnerService,
    private textFragments: TextFragmentService
    ) {
    const callbackId = 'sessionData';
    this.messageService.awaitMessage('main').pipe(filter(msg => msg.name === callbackId))
    .subscribe(
        msg => {
          // console.log("get session data", msg);
          this.session_name = msg.args.session_name;
          this.isAdmin = msg.args.isAdmin;
          if (msg.args.language) {
            this.textFragments.setLanguage(msg.args.language);
          }
        });
    // NOTE listener for messages
    this.messageService.awaitMessage()
      .pipe(filter(msg => msg.name === "open_snackbar"))
      .subscribe( msg => {
        if (!msg.args.noHide) {
          this.spinnerService.hide()
        }
        this.uiInfoService.openSnackbar(msg.args.text, 5000);
      }
    );
  }


}
