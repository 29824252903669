import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MessageService } from 'src/app/shared-generic/services/message.service';
import { UiInfoService } from 'src/app/shared-generic/services/ui-info-service';
import { TextFragmentService } from '../../services/text-fragment.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-password-recover',
  templateUrl: './password-recover.component.html',
  styleUrls: ['./password-recover.component.scss']
})
export class PasswordRecoverComponent implements OnInit {

  email: string = '';

  emailAddress = {
    email_address: this.email,
    language: this.textFragments.language
  };

  EN = {
    title: 'Recover password',
    email: 'Email',
    recoverButtonText: 'Send recovery email',
    loginText: 'Go back to login',
  };

  ES = {
    title: 'Recuperar contraseña',
    email: 'Correo electrónico',
    recoverButtonText: 'Enviar email de recuperación',
    loginText: 'Volver al inicio de sesión',
  };

  DE = {
    title: 'Passwort wiederherstellen',
    email: 'E-Mail',
    recoverButtonText: 'Wiederherstellungs-E-Mail senden',
    loginText: 'Zurück zum Login',
  }

  constructor(
              public router : Router,
              public uiInfoService : UiInfoService,
              public messageService : MessageService,
              private http : HttpClient,
              public textFragments : TextFragmentService,
              private spinnerService: NgxSpinnerService,
  ) { }

  ngOnInit(): void {
  }

  onSubmit(): void {
    this.textFragments.textFragmentsLanguage(this.ES, this.EN, this.DE);
    this.emailAddress = {
      email_address: this.email,
      language: this.textFragments.language
    };
    console.log('Email:', this.emailAddress);
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(this.email)) {
      this.uiInfoService.openSnackbar("You must enter a valid email", 3000);
    } else {
      this.spinnerService.show();
      this.http.post('/send_email_reset_pw', this.emailAddress)
      .subscribe((response: any) => {
        this.spinnerService.hide();
        if (response.success) {
          this.uiInfoService.openSnackbar(response.answer, 3000);
        } else {
          this.uiInfoService.openSnackbar(response.error, 3000);
        }
      }, error => {
        console.error('Error', error);
        this.uiInfoService.openSnackbar(error.error, 3000);
      });
    }
  }

  

  toLogin() {
    this.router.navigateByUrl("/login-view/login")
  }

}
