<div class="w-[280px] h-[280px] rounded-lg shadow-lg overflow-hidden border-vistrace-card"
    [ngClass]="getVariantClasses()">
  <main class="flex flex-col items-center h-full p-6">
    <h3 class="text-xl text-center font-semibold text-gray-800 mb-4">{{ title }}</h3>
    
    <div class="flex-grow flex items-center justify-center">
      <ng-container *ngIf="img_src; else iconTemplate">
        <img class="w-20 h-20 object-contain" [src]="img_src" alt="{{ title }}"/>
      </ng-container>
      
      <ng-template #iconTemplate>
        <i *ngIf="icon" [ngClass]="icon" class="text-4xl text-green-600"></i>
      </ng-template>
    </div>
    
    <div *ngIf="cardType === 'suscriptionStatus'" class="mt-4 w-[100%] text-center text-lg">
      <div class="flex flex-row justify-between items-center w-[100%]">
        <p class="font-normal text-gray-800">{{this.textFragments.textFragmentsLanguage(this.ES, this.EN, this.DE)?.currentPlan}}:</p>
        <p class="font-semibold text-gray-600 ">{{ text_description }}</p>
      </div>
      <div class="flex flex-row justify-between items-center w-[100%]">
        <p class="font-normal text-gray-800">{{this.textFragments.textFragmentsLanguage(this.ES, this.EN, this.DE)?.expiringDate}}:</p>
        <p class="font-semibold text-gray-600 ">{{ text_content }}</p>
      </div>
    </div>
    <div *ngIf="cardType === 'productAdded'" class="mt-4 text-center text-lg">
      <p class="font-semibold text-gray-800">{{ text_description }}</p>
      <p class="text-gray-600 ">{{ text_content }}</p>
    </div>
    <div *ngIf="cardType === 'serviceUsage'" class="mt-4 text-center text-lg">
      <p class="text- font-semibold text-gray-800">{{ text_content }}</p>
    </div>
  </main>
</div>
