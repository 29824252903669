import { Component } from '@angular/core';

@Component({
  selector: 'app-mobile-preview-skeleton',
  templateUrl: './mobile-preview-skeleton.component.html',
  styleUrls: ['./mobile-preview-skeleton.component.scss']
})
export class MobilePreviewSkeletonComponent {

}
