import { Injectable } from '@angular/core';

const cancel_title = {
  "DE": 'Abbrechen',
  "EN": 'Cancel',
  "ES": 'Cancelar'
}
const go_back_title = {
  "DE": 'Zurück',
  "EN": 'Back',
  "ES": 'Atrás'
}
const add_title = {
  "DE": 'Hinzufügen',
  "EN": 'Add'
}
const delete_title = {
  "DE": 'Löschen',
  "EN": 'Delete'
}

@Injectable({
  providedIn: 'root'
})
export class TextService {
  public cancel_title = cancel_title;
  public limit_of_input = 5000;
  public go_back_title = go_back_title;
  public add_title = add_title;
  public delete_title = delete_title;

  constructor() {
  }

}
