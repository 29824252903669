<div class="flex flex-row w-[1200px] gap-2">
    <div class="preview-header absolute top-0 right-0 m-4">
        <mat-icon (click)="close()" class="cursor-pointer">cancel</mat-icon>
    </div>
    <div class="flex flex-column w-1/2 py-3 pl-3 space-y-2">
        <p class="font-bold text-3xl">{{this.textFragments.textFragmentsLanguage(this.ES, this.EN, this.DE)?.getInTouch}}</p>
        <p>{{this.textFragments.textFragmentsLanguage(this.ES, this.EN, this.DE)?.inTouchMessage}}</p>
        <label class="text-sm font-bold">{{this.textFragments.textFragmentsLanguage(this.ES, this.EN, this.DE)?.businessHours}} 9:00-17:00</label>
        <div class="flex flex-col">
            <div class="flex items-center">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-8 w-8 text-vistrace-primary mr-2" viewBox="0 0 20 20" fill="currentColor">
                    <path fill-rule="evenodd" d="M10 2C6.686 2 4 4.686 4 8c0 4.418 6 10 6 10s6-5.582 6-10c0-3.314-2.686-6-6-6zm0 8a2 2 0 110-4 2 2 0 010 4z" clip-rule="evenodd" />
                </svg>
                <p class="">C/ Leonardo da Vinci, 18 Tecnoincubadora Marie Curie, 3ª 41092 Sevilla</p>
            </div>
            <div class="flex items-center">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-8 w-8 text-vistrace-primary mr-2" viewBox="0 0 20 20" fill="currentColor">
                    <path d="M2.94 6.34A2 2 0 014.81 5H15.2a2 2 0 011.86 1.34L10 10.28 2.94 6.34zM18 8l-8 5-8-5v6a2 2 0 002 2h12a2 2 0 002-2V8z" />
                </svg>
                <p class="">info (at) quantecdc.es</p>
            </div>
            <div class="flex items-center">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-8 w-8 text-vistrace-primary mr-2" viewBox="0 0 20 20" fill="currentColor">
                    <path d="M2 5a2 2 0 012-2h1.5A2 2 0 017 4.5v1a2 2 0 01-2 2h-.04a9.978 9.978 0 004.41 4.41V12a2 2 0 012-2H12a2 2 0 012 2v1.5a2 2 0 01-2 2H11a10 10 0 01-9-9V5z" />
                </svg>
                <p class="">+34 955 053 583</p>
            </div>
        </div>
        <p>{{this.textFragments.textFragmentsLanguage(this.ES, this.EN, this.DE)?.quantecBot}}</p>

    </div>
    <div class="flex flex-column w-1/2 py-3 pr-3 pl-1">
        <p class="font-bold text-3xl">{{this.textFragments.textFragmentsLanguage(this.ES, this.EN, this.DE)?.contactUs}}</p>
        <textarea (placeholder)="this.textFragments.textFragmentsLanguage(this.ES, this.EN, this.DE)?.message" [(ngModel)]="formData.context" class="h-40 mt-2 p-2 border-2 border-gray-300 rounded-lg focus:outline-none focus:ring-2 resize-none"></textarea>
        <input placeholder="Email" [(ngModel)]="formData.email" class="mt-2 p-2 border-2 border-gray-300 rounded-lg focus:outline-none focus:ring-2 resize-none">
        <button (click)="sendEmail()" class="bg-vistrace-primary hover:bg-vistrace-quinary text-white font-bold mt-2 py-2 rounded-xl">{{this.textFragments.textFragmentsLanguage(this.ES, this.EN, this.DE)?.send}}</button>
    </div>

</div>