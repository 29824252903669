<div data-html2canvas-ignore="true" class="date-selector-wrapper">
  <mat-form-field [ngStyle]="{width: width + 'px', height: '70px'}">
    <mat-label>Von: </mat-label>
    <input matInput [matDatepicker]="picker" [(ngModel)]="value"
      (ngModelChange)="valueChangedAction($event)"
      (keydown)="disableInput($event)">
    <mat-datepicker-toggle class="toggle" matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-datepicker [dateClass]="dateClass" #picker [calendarHeaderComponent]="exampleHeader"></mat-datepicker>
  </mat-form-field>
  <div class="weekly-switch" *ngIf="directionType=='week'">
    <button mat-icon-button (click)="changeWeek(-1)">
      <mat-icon>chevron_left</mat-icon>
    </button>
    <div>KW: {{week}}</div>
    <button mat-icon-button (click)="changeWeek(1)">
      <mat-icon>chevron_right</mat-icon>
    </button>
  </div>
  <div class="monthly-switch" *ngIf="directionType=='month'">
    <button mat-icon-button (click)="changeMonth(-1)">
      <mat-icon>chevron_left</mat-icon>
    </button>
    <div [ngStyle]="{'min-width': '80px', 'text-align': 'center', 'margin-top': '5px'}">{{month}}</div>
    <button mat-icon-button (click)="changeMonth(1)">
      <mat-icon>chevron_right</mat-icon>
    </button>
  </div>
</div>
<!-- alternative for html2canvas -->
<div class="showInCanvas" [style.display]="'none'">
  <span>{{'Von: ' + dateVisibleRender + ' KW: ' + week}}</span>
</div>