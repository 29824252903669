import { Component, ElementRef, HostListener } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { MessageService } from 'src/app/shared-generic/services/message.service';

import { UpdateService } from 'src/app/shared-generic/services/update.service';
import { LoginDialogComponent } from 'src/app/shared-generic/components/login-dialog/login-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { DialogLoginService } from './shared-generic/services/dialog-login.service';
import { LayoutService } from './shared-generic/services/layout.service';
import { Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { ResetPwDialogComponent } from './shared-generic/components/reset-pw-dialog/reset-pw-dialog.component';
import { CookieService } from 'ngx-cookie';
import { Subscription } from 'rxjs';
import { ViewService } from './shared/services/view.service';
import { NgxSpinnerService } from "ngx-spinner";
import { KeycloakService } from 'keycloak-angular';
import { UiInfoService } from './shared-generic/services/ui-info-service';
import { StripeService } from './ui-main/services/stripe-service';
import { TextFragmentService } from './ui-main/services/text-fragment.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  username: string;
  opened = true;
  password = '';
  private autologin = false;
  public error_message: string = "";
  private secret_number = '';
  private login_sub: Subscription
  private language_request = '';

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.layoutService.resizeWindow(event);
  }
  constructor(
    
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    public messageService: MessageService,
    private updateService: UpdateService,
    private router: Router,
    private viewService: ViewService,
    public layoutService: LayoutService,
    public cookieService: CookieService,
    private loginService: DialogLoginService,
    private keycloak: KeycloakService,
    private uiInfoService: UiInfoService,
    private stripeService: StripeService,
    private textFragments : TextFragmentService,
    private spinnerService: NgxSpinnerService) {

      this.matIconRegistry.addSvgIcon(
        'mes_apps',
        this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/mes_apps.svg')
      );
      // Logo_RE_mid.svg
      this.matIconRegistry.addSvgIcon(
        'product',
        this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/nucos_symbol.svg')
      );
      this.matIconRegistry.addSvgIcon(
        'product-trans',
        this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/nucos_symbol.svg')
      );
      this.matIconRegistry.addSvgIcon(
        'add_many',
        this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/add_add_circle_outline.svg')
        );
      this.matIconRegistry.addSvgIcon(
        'material_lab',
        this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/material_lab.svg')
        );
      this.matIconRegistry.addSvgIcon(
        'material_lab_no_color',
        this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/material_lab_no_color.svg')
        );
      this.matIconRegistry.addSvgIcon(
        'notification',
        this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/notification.svg')
        );
      this.matIconRegistry.addSvgIcon(
        'job_planned',
        this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/Icongeplant.svg')
        );
      this.matIconRegistry.addSvgIcon(
        'job_running',
        this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/Icongelaufend.svg')
        );
      this.matIconRegistry.addSvgIcon(
        'job_aborted',
        this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/Iconangehalten.svg')
        );
      this.matIconRegistry.addSvgIcon(
        'job_finished',
        this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/Iconabgeschlossen.svg')
        );
      this.matIconRegistry.addSvgIcon(
        'job_delayed',
        this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/IconInVerzug.svg')
        );
      this.matIconRegistry.addSvgIcon(
        'icon_info',
        this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/Iconinfo.svg')
        );
      this.matIconRegistry.addSvgIcon(
        'addi_plan',
        this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/AddiPlan_R01.svg')
      );
      this.matIconRegistry.addSvgIcon(
        'drag_drop',
        this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/drag_drop.svg')
      );
      this.matIconRegistry.addSvgIcon(
        'lock',
        this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/lock.svg')
      );
      this.matIconRegistry.addSvgIcon(
        'delete',
        this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/delete.svg')
      );
      this.matIconRegistry.addSvgIcon(
        'visibility',
        this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/visibility.svg')
      );
      this.matIconRegistry.addSvgIcon(
        'visibility',
        this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/visibility.svg')
      );
      this.matIconRegistry.addSvgIcon(
        'qr_code',
        this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/qr_icon_download.svg')
      );
      // Access URL parameters
      const rootUrl = new URL(window.location.href);
      // console.log("URL", rootUrl);
      this.language_request = <string>rootUrl.searchParams.get("L");
      if (this.language_request) {
        this.textFragments.setLanguage(this.language_request)
      }
  }

  ngOnInit() {

    this.messageService.absUrl = window.location.href;
    this.messageService.protocol = window.location.protocol;
    // NOTE: we need to set this in each project as we make use of it in login-dialog and reset-pw-dialog
    this.messageService.rootUrl = window.location.href.split('?')[0];    
    this.updateService.subscribe((upd: string) => {
      // console.log("upd", upd);
      if (upd.split(" ")[1]===this.messageService.final_auth_backend) {
        if (upd.startsWith('doneAuth')) {
          this.finalizeAuth();
        } else {
          this.messageService.login = false;
          this.router.navigateByUrl("/login-view/login")
          if (!this.messageService.user.startsWith("anonymous")) {
            const loginError = this.loginService.loginErrorSubject;
            if (!loginError) {
              this.uiInfoService.openSnackbar("Login failed", 3000);
            } else {
              this.uiInfoService.openSnackbar("loginError", 3000);
            }
          }
        }
      }
    });
    
    //////////////

    if (!this.login_sub) {
      this.login_sub = this.updateService.update.pipe(filter(upd=>upd==='autologin')).subscribe((upd: string) => {
        this.secret_number = this.loginService.secret_number; 
        this.username = this.messageService.user;
        this.loginService.app = "frontend-testing";
        console.log("USERNAME", this.username);
        

        if (this.loginService.secret_number) {
          this.startReset();
        } else {
          this.autoLogin();
        }
      })
    }

    this.messageService.getSalt()
  }

  async jwtLogin()  {
    console.log("end login process,....");
    const jwt_token = await this.keycloak.getToken();
    await this.keycloak.loadUserProfile();
    const username = await this.keycloak.getUsername();
    const admin = await this.keycloak.isUserInRole("admin");
    //const aud = await this.keycloak.
    this.messageService.jwt_token = jwt_token;
    //this.messageService.user = username;
    this.loginService.login(username, '');
    console.log("TOKEN", jwt_token);
    console.log("USERNAME", username);
    console.log("ADMIN", admin);
  }

  public autoLogin() {
    this.loginService.mode = "login";
    if ((this.username !== 'anonymous')&&(this.username)) {
      this.autologin = true;
      console.log("start autologin....", this.loginService.loginMode)
      if (this.loginService.loginMode === "keycloak") {
        this.router.navigateByUrl("/login-view/wait");
      } else {
        //this.router.navigateByUrl("/home")
        this.loginService.login(this.username, '');
      }
      
    } else {
      this.messageService.login = false;
      if (this.loginService.loginMode === "keycloak") {
        this.router.navigateByUrl("/login-view/wait");
      } else {
        this.router.navigateByUrl("/login-view/login")
      }
    }
    if (this.loginService.loginMode === "keycloak") {
      this.jwtLogin().then(value => {
        console.log('jwt login resolved', value);
      });
    }
  }

  public startReset() {
    this.loginService.mode = "reset";
    this.messageService.login = false;
    this.router.navigateByUrl("/recover");
  }

  public finalizeAuth(): void {
    this.loginService.shouldRun.next(true);
    this.loginService.login_finalize(this.messageService.user, this.messageService.token, this.messageService.final_urls, false);
    // NOTE redirect to the redirect_url
    if (this.messageService.redirect_url !== "/" && this.messageService.redirect_url !== "") {
      console.log("redirect to", this.messageService.redirect_url);
      setTimeout(()=> this.router.navigateByUrl(this.messageService.redirect_url),100); 
    } else {
      console.log("ususal startup");
      this.router.navigateByUrl("/main/home");
      this.uiInfoService.openSnackbar("Login success", 3000);
    }
    
    const prom = this.spinnerService.hide();
    prom.then(value => {
      console.log('resolved', value);
    });
    // reminder every hour
    
  }

}
