import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { MessageService } from 'src/app/shared-generic/services/message.service';
import { SessionService } from 'src/app/ui-main/services/session-service';
import { TextFragmentService } from 'src/app/ui-main/services/text-fragment.service';

@Component({
  selector: 'app-modal-review-product',
  templateUrl: './modal-review-product.component.html',
  styleUrls: ['./modal-review-product.component.scss']
})
export class ModalReviewProductComponent {

  public reason: string;

  EN = {
    title: "Product Review Denied",
    reason: "Please provide a reason for denying the product after the review",
    input: "Reason",
    send: "Send",
    cancel: "Cancel"
  }

  ES = {
    title: "Revisión de producto denegada",
    reason: "Por favor, proporcione una razón para denegar el producto después de la revisión",
    input: "Razón",
    send: "Enviar",
    cancel: "Cancelar"
  }

  DE = {
    title: "Produktüberprüfung abgelehnt",
    reason: "Bitte geben Sie einen Grund für die Ablehnung des Produkts nach der Überprüfung an",
    input: "Grund",
    send: "Senden",
    cancel: "Stornieren"
  }

  constructor(
    private dialogRef: MatDialogRef<ModalReviewProductComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { id: string },
    public textFragments : TextFragmentService,
    private messageService: MessageService,
    public sessionService : SessionService
  ) { }

  ngOnInit(): void {
    this.textFragments.textFragmentsLanguage(this.ES, this.EN, this.DE);
  }

  close(): void {
    this.dialogRef.close(false);
  }

  public sendReviewMail() {

    let request = {
      reason: this.reason,
      productId: this.data.id,
    }

    const msg = {
      name: 'sendDeniedProductReason',
      args: [request]
    };
    this.messageService.sendMsg(msg, "main");
    this.dialogRef.close(true);
  }

}
