import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class DialogService {
  private subscriptions = [];
  public statusSubject =  new Subject<{dialog_name: string, status: boolean}>();
  public rowSubject =  new Subject<{dialog_name: string, selected_row: object, status: boolean}>();
  public statusEntitySubject =  new Subject<{dialog_name: string, status: boolean, entityId: string}>();

  constructor(private httpClient: HttpClient) { }

  public dialogStatus(dialog_name: string, status: boolean): void {
    this.statusSubject.next({dialog_name: dialog_name, status: status});
  }

  public transferRowSelected(dialog_name: string, selectedRow: object, status: boolean): void {
    this.rowSubject.next({dialog_name: dialog_name, selected_row: selectedRow, status: status});
  }

  public dialogStatusEntity(dialog_name: string, status: boolean, entityId: string): void {
    this.statusEntitySubject.next({dialog_name: dialog_name, status: status, entityId: entityId});
  }

}
