import { AfterViewInit, Component, EventEmitter, Inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {CodeInputComponent} from 'angular-code-input';
import { InfoCard } from '../../types/infoCard';
import { Icon } from '../../types/icon';

@Component({
  selector: 'app-info-card',
  templateUrl: './info-card.component.html',
  styleUrls: ['./info-card.component.scss'],
})
export class InfoCardComponent implements OnInit{
  public isSelected: boolean = false;
  @Input() infoCard: InfoCard;
  @Input() icons: Icon[];
  @Input() cardWidth = 120;
  @Output() clickedIconEvent = new EventEmitter<string>();
  @Output() clickedCardEvent = new EventEmitter<boolean>();

  constructor(
  ) { }
  ngOnInit() {
  }
  
  public myClickFunction(event: any) {
    if (!this.infoCard.draggable) {
      this.infoCard.isSelected = !this.infoCard.isSelected;
      this.clickedCardEvent.emit(this.infoCard.isSelected);
    }
  }

  public clickedIcon(event, icon: Icon){
    event.stopPropagation();
    if (icon.triggerStatus){
      this.clickedIconEvent.emit(icon.icon);
    }
  }
}

