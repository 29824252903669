import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'app-radio-button-dialog',
  templateUrl: './radio-button-dialog.component.html',
  styleUrls: ['./radio-button-dialog.component.scss']
})
export class RadioButtonDialogComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<RadioButtonDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, // required: title, name, [{label, checked}]
  ) { }

  ngOnInit(): void {
  }
  
  changeRadioButton(identifier:string): void {
    // console.log("identifier: ", identifier)
    this.data.identifier = identifier;
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  onOk() {
    // do not call this.dialogRef.close(); as this is called via [mat-dialog-close] in html
  }

}
