import { Injectable } from '@angular/core';
import { ChatBotMessage } from '../types/chatbotMessages';
import { MessageService } from 'src/app/shared-generic/services/message.service';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ChatService {
  public messages: ChatBotMessage[] = []
  
  constructor(private messageService: MessageService) { 
    this.messageService.awaitMessage('main').pipe(filter(message => message.name === 'groqAnswer'))
    .subscribe(message => {
      this.messages.push(message.args.lastAnswer)
    });
  }
}
