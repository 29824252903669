import { Component, Input, OnDestroy, Output, OnInit, EventEmitter } from '@angular/core';
import { UntypedFormControl} from '@angular/forms';
import { SortService } from './../../services/sort.service';
import { filter } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { MessageService } from 'src/app/shared-generic/services/message.service';

@Component({
  selector: 'app-sort',
  templateUrl: './sort.component.html',
  styleUrls: ['./sort.component.scss']
})
export class SortComponent implements OnInit, OnDestroy {

  public sortControl: UntypedFormControl;
  public sortOptions = [];
  public subs: Subscription[] = [];
  @Input() backend = 'offer-creator';
  @Input() controller = '';
  @Output() sortTrigger = new EventEmitter<string>();

  constructor(
    private sortService: SortService,
    private messageService: MessageService,
  ) { }

  ngOnInit(): void {
    this.subs.push(this.sortService.getSortOptions(this.controller, this.backend).pipe(filter(msg => msg.args.identifier === this.backend + this.controller))
    .subscribe(
      msg => {
        // console.log(msg);
        this.sortControl = new UntypedFormControl(msg.args.selected);
        this.sortOptions = msg.args.options;
        this.sortControl.valueChanges.subscribe(val =>{
          // console.log("new value sort: ", val)
          this.sortService.setSortMessage(this.controller,this.backend, val);
          let trigger = this.messageService.getRandomInt(10000).toString();
          this.sortTrigger.emit(trigger);})
      }));
  }
  ngOnDestroy() {
    this.subs.forEach( subs => subs.unsubscribe() );
  }

}
