import { Injectable } from '@angular/core';
import { MessageService } from './message.service';
import { Subject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { Message } from '../types/message';
import { Dictionary } from '../types/dictionary';

@Injectable({
  providedIn: 'root'
})
export class FormDataService {
  private subscriptions = [];
  public formDataSubject =  new Subject<Message>();
  public checkViewStatusSubject =  new Subject<string>();
  // used to enable 'go forword' button (change between views)
  // in this case we need the status from various forms
  public formValidViewSubject =  new Subject<boolean>();
  public formTableDataSubject =  new Subject<{form: string, data: {}}>();
  public fieldValueSubject =  new Subject<{form: string, field: string, value: string}>();
  // listen to a status of a form
  // in this case we need the status from one specific form
  public formValidSubject =  new Subject<{form: string, status: boolean}>();
  public formFieldValidSubject =  new Subject<{form_and_field: string, status: boolean}>();
  public formDataHolidaysSubject =  new Subject<Message>();
  public listStatusDict = new Dictionary<boolean>()

  constructor(private messageService: MessageService) { }

  //request from data
  public getGenericForm(form_name: string, callbackId: string, backend: string): Subject<Message> {
    if (this.subscriptions.indexOf(callbackId) === -1) {
      this.messageService.awaitMessage(backend).pipe(filter(msg => msg.name === callbackId))
        .subscribe(
          msg => {
            this.formDataSubject.next(msg);
          });
      this.subscriptions.push(callbackId);
    }
    const message = {
        name: 'getFormData',
        args: [form_name, callbackId]
    };
    this.messageService.sendMsg(message, backend);
    return this.formDataSubject;
  }

  public setFormStatus(status: boolean): void {
    // used to enable navigation (forward) between views
    this.formValidViewSubject.next(status);
  }

  public listStatus(form: string, status: boolean): void {
    this.listStatusDict.add(form, status);
  }

  public setSingleStatus(form: string, status: boolean): void {
    // used to enable navigation (forward) between views
    var listState = true
    if (this.listStatusDict.containsKey(form)) {
      listState = this.listStatusDict.item(form);
    }
    this.formValidSubject.next({form: form, status: status && listState});
  }

  public setSingleFieldStatus(form_and_field: string, status: boolean): void {
    this.formFieldValidSubject.next({form_and_field: form_and_field, status: status});
  }

  //TODO: add a version of below function only for autocomplete
  public setGenericFormFieldValue(form: string, entity: string, field: string, value: any, backend: string): void {
    const message = {
      name: 'setGenericFormFieldValue',
      args: [form, entity, field, value]
  };
  this.messageService.sendMsg(message, backend);
  this.fieldValueSubject.next({form: form, field: field, value: value});
  }

  public setAutoCompleteFieldValue(form: string, entity: string, field: string, value: any, backend: string, callbackId=undefined): void {
    const message = {
      name: 'setAutoCompleteFieldValue',
      args: [form, entity, field, value, callbackId]
  };
  this.messageService.sendMsg(message, backend);
  this.fieldValueSubject.next({form: form, field: field, value: value});
  }

  // NOTE: old version use generic form instead
  // public setFormFieldValue(form: string, field: string, value: any): void {
  //   const message = {
  //     name: 'setFormFieldValue',
  //     args: [form, field, value]
  // };
  // this.messageService.sendMsg(message);
  // this.fieldValueSubject.next({form: form, field: field, value: value});
  // }

  // update form data
  public setTableDataSubject(form: string, data: {}): void {
    this.formTableDataSubject.next({form: form, data: data});
  }
}
