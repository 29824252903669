

  <ngx-spinner bdColor="rgba(51,51,51,0.8)" size="large" color="#fff" type="ball-beat" [fullScreen] = "true"></ngx-spinner>

  <!-- NavBar -->
  <header>
      <app-nav-bar></app-nav-bar>
  </header>
  
  <main class="h-[calc(100vh-158px)]">
    <router-outlet></router-outlet>
  </main>

  <footer class="bg-vistrace-primary bottom-0 absolute w-full flex flex-row items-center">
    <app-footer class="w-full"></app-footer>
  </footer>

  

