<div class="text-2xl text-center m-4">{{this.textFragments.textFragmentsLanguage(this.ES, this.EN, this.DE)?.preview}}</div>
<div class="preview-header absolute top-0 right-0 m-4">
  <mat-icon (click)="closePreview()" class="cursor-pointer">cancel</mat-icon>
  <!--button class="close-button bg-red-500 text-white rounded-full w-10 h-10 flex items-center justify-center" (click)="closePreview()">X</button-->
</div>
<div class="modal-container relative">


  <div class="flex flex-col justify-around w-1/2">
    <div class="m-2 mb-0 color-fix">
      <app-generic-form *ngIf="product_key"
        [xname]="'product-colors-form'" 
        [entity]="product_key"
        [backend]="'main'" 
        [col_number]="1" 
        [frozen]="true" 
        [justifyContent]="'space-around'"
        [focusFirst]="true">
      </app-generic-form>
    </div>
    <div class="m-2 mt-0 color-fix">
      <app-generic-form *ngIf="product_key"
        [xname]="'product-colors-form-2'" 
        [entity]="product_key"
        [backend]="'main'" 
        [col_number]="1" 
        [frozen]="true" 
        [justifyContent]="'space-around'"
        [focusFirst]="true">
      </app-generic-form>
    </div>

    <div class="flex justify-center mt-4">
      <button class="z-50 m-2 rounded-full bg-black text-white font-bold py-2 px-4"
        (click)="launchPreview()"
        [matTooltip]="this.textFragments.textFragmentsLanguage(this.ES, this.EN, this.DE)?.reload"
      >
        <mat-icon class="mt-1">autorenew</mat-icon>
      </button>
    </div>
  </div>
  <app-mobile-preview [mobile_url]="mobile_url"></app-mobile-preview>

</div>