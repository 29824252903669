import { Component, OnInit } from '@angular/core';
import { TextFragmentService } from 'src/app/ui-main/services/text-fragment.service';

@Component({
  selector: 'app-statistics',
  templateUrl: './statistics.component.html',
  styleUrls: ['./statistics.component.scss']
})
export class StatisticsComponent implements OnInit {

  EN = {
    statistics : "Statistics",
    data : "More data"
  }

  ES = {
    statistics : "Estadísticas",
    data : "Más datos"
  }

  DE = {
    statistics : "Statistiken",
    data : "Mehr Daten"
  }

  constructor(public textFragments : TextFragmentService) { }

  ngOnInit(): void {
    this.textFragments.textFragmentsLanguage(this.ES, this.EN, this.DE);
  }

}
