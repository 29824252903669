<div class="flex items-center justify-between w-full">
    <section class="flex flex-row items-center justify-around w-1/5 ml-32">
        <p class="text-white cursor-pointer">© 2024 VisTrace </p>
        <a href="/static/legals/vistrace-terms-of-service.pdf" download="vistrace-terms-of-service.pdf" (click)="openAndDownload($event, '/static/legals/vistrace-terms-of-service.pdf')" class="text-white cursor-pointer">{{this.textFragments.textFragmentsLanguage(this.ES, this.EN, this.DE)?.terms}}</a>
        <a href="/static/legals/vistrace-privacy-policy.pdf" download="vistrace-privacy-policy.pdf" (click)="openAndDownload($event, '/static/legals/vistrace-privacy-policy.pdf')" class="text-white cursor-pointer">{{this.textFragments.textFragmentsLanguage(this.ES, this.EN, this.DE)?.privacy}}</a>
        <p class="text-white cursor-pointer">Cookies</p>
    </section>
    
    <div class="flex flex-1 items-center justify-end mr-10">
        <a (click)="openContactUs()" class="text-white m-4 cursor-pointer">{{this.textFragments.textFragmentsLanguage(this.ES, this.EN, this.DE)?.contact}}</a>
        <p style="margin-inline: 128px;" class="text-white m-4 cursor-pointer">{{this.textFragments.textFragmentsLanguage(this.ES, this.EN, this.DE)?.about}}</p>
    </div>
    
    <div class="flex items-center lg:px-[8%]">
        <img
          src="assets/icon_linkedin.svg"
          class="m-4 cursor-pointer"
          alt="add product"
        />
        <img
          src="assets/icon_instagram.svg"
          class="m-4 cursor-pointer"
          alt="add product"
        />
    </div>   
</div>
