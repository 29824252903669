import { AfterViewInit, Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { Subscription } from "rxjs";
import { filter } from "rxjs/operators";
import { MessageService } from "src/app/shared-generic/services/message.service";
import { TextFragmentService } from "src/app/ui-main/services/text-fragment.service";
import Swiper from "swiper";
// import function to register Swiper custom elements
import { register } from 'swiper/element/bundle';

// register Swiper custom elements globally
register();


@Component({
  selector: 'app-right-column-card',
  templateUrl: './right-column-card.component.html',
  styleUrls: ['./right-column-card.component.scss']
})
export class RightColumnCardComponent implements AfterViewInit, OnDestroy {
  private swiper: Swiper;
  public tips = new Array<string>();
  public img_urls = new Array<string>();
  private subscriptions = new Array<Subscription>();
  // swiper element
  //swiperEl = document.querySelector('swiper-container');
  @ViewChild('swiperEl', { static: false }) swiperEl: ElementRef;

  @HostListener('mouseenter') onMouseEnter() {
    this.swiper.autoplay.stop();
  }

  @HostListener('mouseleave') onMouseLeave() {
    this.swiper.autoplay.start();
  }

  EN = {
    firstParagraph: '100% Picual extra virgin olive oil produced using bio-dynamic farming from Jaen, 500ml bottle',
    secondParagraph: 'First day of harvest. Intense green color, with a density and a notable bouquet of fresh olives. The palate is very complex, with a great variety of herbaceous and fruity notes, such as green tomato, artichoke, green almond, and banana. The bitterness and spiciness are very balanced, with a long and persistent finish.',
    didYouKnow: 'Did you know...?',
  }

  ES = {
    firstParagraph: 'Aceite de oliva virgen extra 100% Picual producido con agricultura biodinámica de Jaén, botella de 500ml',
    secondParagraph: 'Primer día de cosecha. Color verde intenso, con una densidad y un notable bouquet de aceitunas frescas. En boca es muy complejo, con una gran variedad de notas herbáceas y frutales, como el tomate verde, la alcachofa, la almendra verde y el plátano. El amargor y el picante están muy equilibrados, con un final largo y persistente.',
    didYouKnow: '¿Sabías que...?',
  }

  DE = {
    firstParagraph: '100% Picual extra natives Olivenöl, hergestellt mit biodynamischem Anbau aus Jaen, 500ml Flasche',
    secondParagraph: 'Erster Erntetag. Intensiv grüne Farbe, mit einer Dichte und einem bemerkenswerten Bouquet frischer Oliven. Am Gaumen sehr komplex, mit einer großen Vielfalt an krautigen und fruchtigen Noten wie grüne Tomate, Artischocke, grüne Mandel und Banane. Die Bitterkeit und Schärfe sind sehr ausgewogen, mit einem langen und anhaltenden Abgang.',
    didYouKnow: 'Wussten Sie, dass...?',
  }


  constructor(public textFragments : TextFragmentService,
              public messageService : MessageService,
  ) {}

  ngAfterViewInit(): void {
    this.swiperEl.nativeElement.initialize();
    this.swiper = this.swiperEl.nativeElement.swiper;
  }


  ngOnInit(): void {
    this.textFragments.textFragmentsLanguage(this.ES, this.EN, this.DE);
    const callbackTips = "get_tips";
    this.subscriptions.push(
      this.textFragments.language$.subscribe( ()=> {
        const msg = {
          name: 'getTips',
          args: [this.textFragments.language, callbackTips]
        };
        this.messageService.sendMsg(msg, 'main');
      }));
    this.subscriptions.push(
      this.messageService.awaitMessage()
      .pipe(filter(msg => msg.name === callbackTips))
      .subscribe( msg => {
        this.tips = msg.args.tips;
        this.img_urls = msg.args.img_urls;
        // seems to help update the slider internally every time after reload
        this.swiperEl.nativeElement.setAttribute('slides-per-view', '2'); 
        this.swiperEl.nativeElement.setAttribute('slides-per-view', '1');
        this.swiper.autoplay.start();
      })
    );
    const msg = {
      name: 'getTips',
      args: [this.textFragments.language, callbackTips]
    };
    this.messageService.sendMsg(msg, 'main');
  }


  ngOnDestroy() {
    this.subscriptions.forEach( subs => subs.unsubscribe() );
  }



}



