import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { MessageService } from 'src/app/shared-generic/services/message.service';
import { SubscriptionPlan } from '../../types/subscription-plan';
import { Route, Router } from '@angular/router';
import { TextFragmentService } from '../../services/text-fragment.service';



@Component({
  selector: 'app-subscription-plans-view',
  templateUrl: './subscription-plans-view.component.html',
  styleUrls: ['./subscription-plans-view.component.scss']
})
export class SubscriptionPlansViewComponent implements OnInit {
  private subscriptions: Array<Subscription>=[];
  public activatedPlan = "";
  public oneActive: boolean = false;
  public updateTrigger = "";
  public subs: Array<SubscriptionPlan>=[];
  constructor(private messageService: MessageService,
              private router: Router,
              public textFragments : TextFragmentService
    ) { }

  public subscriptionBasic: any = { service_name: "TEST1", price_per_year:"9,99", number_of_products: 2, number_of_languages: 1, branding: ["Basic Customization Options"]}
  public subscriptionAdvance: any = { service_name: "TEST2", price_per_year:"19,99", number_of_products: 5, number_of_languages: 2, branding: ["Advanced Customization Options"], blockchain: "None", feature_descriptions: ["Analytical Tools", "Tracking Information"]}
  public subscriptionPremium: any = { service_name: "TEST3", price_per_year:"34,99", number_of_products: 10, number_of_languages: 3, branding: ["Premium Customization Options"], blockchain: "Basic", feature_descriptions: ["Analytical Tools", "Tracking Information"]}
  public subscriptionEnterprise: any = { service_name: "TEST4", feature_descriptions: ["Analytical Tools", "Tracking Information"]}

  EN = {
    disclaimer: 'All plans include Blockchain security for provided information.',
    eLabelTitle: 'E-Label compliant',
    eLabelService: 'Optional e-label landing page, separated from marketing content (EU Regulation 2021/2117)',
    enhancedSecurity: 'Enhanced Security',
    flexibleExpansion: 'Flexible Expansion',
    addMore: 'Add more products to any plan at €79 per additional product, with up to 10 batches per product.'
  };

  ES = {
    disclaimer: 'Todos los planes incluyen seguridad Blockchain para la información proporcionada.',
    eLabelTitle: 'E-Label compliant',
    eLabelService: 'Página de bienvenida opcional de e-label, separada del contenido de marketing (Reglamento de la UE 2021/2117)',
    enhancedSecurity: 'Seguridad Mejorada',
    flexibleExpansion: 'Expansión Flexible',
    addMore: 'Agregue más productos a cualquier plan a €79 por producto adicional'
  }

  DE = {
    disclaimer: 'Alle Pläne enthalten Blockchain-Sicherheit für die bereitgestellten Informationen.',
    eLabelTitle: 'E-Label compliant',
    eLabelService: 'Optionale e-Label-Landingpage, getrennt vom Marketinginhalt (EU-Verordnung 2021/2117)',
    enhancedSecurity: 'Erhöhte Sicherheit',
    flexibleExpansion: 'Flexible Erweiterung',
    addMore: 'Fügen Sie jedem Plan weitere Produkte hinzu zu €79 pro zusätzlichem Produkt mit bis zu 10 Chargen für jedes Produkt.'
  }

  ngOnInit(): void {
    this.textFragments.textFragmentsLanguage(this.ES, this.EN, this.DE);
    const callbackId = "set_subs_plans";
    this.subscriptions.push(
      this.messageService.awaitMessage()
        .pipe(filter(msg => msg.name === callbackId))
        .subscribe( msg => {
          this.subs = msg.args.subs;
          this.activatedPlan = msg.args.activatedPlan;
          this.oneActive = this.activatedPlan.length>0;
          this.updateTrigger = this.messageService.getRandomInt(100000).toString();
        }
    ));
    this.subscriptions.push(
      this.textFragments.language$.subscribe( ()=> {
        const msg = {
          name: 'getSubscriptionPlans',
          args: [this.textFragments.language, callbackId]
        };
        this.messageService.sendMsg(msg, 'main');
      }
    ));
    const msg = {
      name: 'getSubscriptionPlans',
      args: [this.textFragments.language, callbackId]
    };
    this.messageService.sendMsg(msg, 'main');
  }

  activateSubscription() {
    this.router.navigateByUrl('/main/settings/2')
  }

  ngOnDestroy() {
    this.subscriptions.forEach( subs => subs.unsubscribe() );
  }

}


