export class OccupancyTask {

    static parseOccupancyTask(occupancyTaskObject) {
        const occupancyTaskItem = new OccupancyTask(
            occupancyTaskObject.task_id,
            occupancyTaskObject.subject_id,
            occupancyTaskObject.date_count,
            occupancyTaskObject.subject_count,
            occupancyTaskObject.display_label,
            occupancyTaskObject.type,
        );
        if (occupancyTaskObject.status) {
          occupancyTaskItem.status = occupancyTaskObject.status;
        }
        if (occupancyTaskObject.comment) {
          occupancyTaskItem.comment = occupancyTaskObject.comment;
        }
        if (occupancyTaskObject.task_length) {
          occupancyTaskItem.task_length = occupancyTaskObject.task_length;
        }
        if (occupancyTaskObject.icon) {
          occupancyTaskItem.icon = occupancyTaskObject.icon;
        }
        if (occupancyTaskObject.iconTooltip) {
          occupancyTaskItem.iconTooltip = occupancyTaskObject.iconTooltip;
        }
        if (occupancyTaskObject.subType) {
          occupancyTaskItem.subType = occupancyTaskObject.subType;
        }
        if (occupancyTaskObject.tooltip) {
          occupancyTaskItem.tooltip = occupancyTaskObject.tooltip;
        }
        if (occupancyTaskObject.color) {
          occupancyTaskItem.color = occupancyTaskObject.color;
        }
        return occupancyTaskItem;
    }
  
    constructor(
      public task_id: string,
      public subject_id: string,
      public date_count: number,
      public subject_count: number,
      public display_label: string[],
      public type: string,
      public subType = "",
      public isSelected = false,
      public status = '',
      public comment = '',
      public task_left = 0,
      public task_top = 0,
      public task_width = 0,
      public task_length = 0,
      public icon = "",
      public iconTooltip = "",
      public tooltip = "",
      public color = "",
    ) { }

  }
