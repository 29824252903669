<div class="flex items-center justify-between bg-gray-200 p-4 rounded-lg ml-[30px]">
  <div class="flex items-center space-x-4">
    <button class="bg-vistrace-primary hover:bg-vistrace-hover-dark text-white z-10 font-bold py-2 px-4 rounded"
      (click)="selectProduct()" [matTooltip]="this.textFragments.textFragmentsLanguage(this.ES, this.EN, this.DE)?.buttonToProduct"
    >
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
        <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 4.875c0-.621.504-1.125 1.125-1.125h4.5c.621 0 1.125.504 1.125 1.125v4.5c0 .621-.504 1.125-1.125 1.125h-4.5A1.125 1.125 0 013.75 9.375v-4.5zM3.75 14.625c0-.621.504-1.125 1.125-1.125h4.5c.621 0 1.125.504 1.125 1.125v4.5c0 .621-.504 1.125-1.125 1.125h-4.5a1.125 1.125 0 01-1.125-1.125v-4.5zM13.5 4.875c0-.621.504-1.125 1.125-1.125h4.5c.621 0 1.125.504 1.125 1.125v4.5c0 .621-.504 1.125-1.125 1.125h-4.5A1.125 1.125 0 0113.5 9.375v-4.5zM6.75 6.75h.75v.75h-.75v-.75zM6.75 16.5h.75v.75h-.75v-.75zM16.5 6.75h.75v.75h-.75v-.75zM13.5 13.5h.75v.75h-.75v-.75zM13.5 19.5h.75v.75h-.75v-.75zM19.5 13.5h.75v.75h-.75v-.75zM19.5 19.5h.75v.75h-.75v-.75zM16.5 16.5h.75v.75h-.75v-.75z" />
        <path stroke-linecap="round" stroke-linejoin="round" d="M6.75 6.75h.75v.75h-.75v-.75zM6.75 16.5h.75v.75h-.75v-.75zM16.5 6.75h.75v.75h-.75v-.75zM13.5 13.5h.75v.75h-.75v-.75zM13.5 19.5h.75v.75h-.75v-.75zM19.5 13.5h.75v.75h-.75v-.75zM19.5 19.5h.75v.75h-.75v-.75zM16.5 16.5h.75v.75h-.75v-.75z" />
      </svg>
    </button>
    <div *ngFor="let batch_key of batch_keys" class="relative border-2 border-gray-500 rounded overflow-hidden z-10 bg-gray-200 w-20 hover:bg-vistrace-hover-light"
      [ngClass]="batch_key==active_batch_key ? 'bg-gray-300' : 'bg-gray-200'"
    >
      <button class="px-2 py-1 rounded w-full"
        (click)="selectBatch(batch_key)"
      >
        <div class="text-gray-500 text-sm">{{this.textFragments.textFragmentsLanguage(this.ES, this.EN, this.DE)?.batch}}</div>
        <div class="text-xs h-4">{{batch_numbers[batch_key]}}</div>
        <div class="relative top-0 left-[25px] -mr-2 w-4 h-4" (click)="deleteBatch($event, batch_key)" *ngIf="!batch_finalized[batch_key] && !batch_in_review">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
          </svg>
        </div>
      </button>
      
      <div class="badge-www-batch" *ngIf="batch_finalized[batch_key]"></div>
    </div>
    <div class="mx-4 border-gray-400 border-2 w-[150px] absolute"
      [ngStyle]="{'width': batch_line_width+'px'}"
    ></div>
  </div>
  <button class="bg-vistrace-primary hover:bg-vistrace-hover-dark text-white font-bold py-2 px-4 rounded"
    (click)="createBatch()" [matTooltip]="this.textFragments.textFragmentsLanguage(this.ES, this.EN, this.DE)?.buttonAddBatch"
  >
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
      <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
    </svg>
  </button>
</div>

<section class="p-8 overflow-y-auto h-[calc(100vh-260px)]">
  <div *ngIf="selectedID === 'Product Info'" class="bg-white rounded shadow p-6">
    <h1 class="text-2xl font-bold mb-4 mt-4 text-center">{{this.textFragments.textFragmentsLanguage(this.ES, this.EN, this.DE)?.productInfo}}</h1>
    <app-generic-form *ngIf="product_key"
        [xname]="'product-general-form'" 
        [entity]="product_key"
        [backend]="'main'"
        [col_number]="3"
        [infoForm]="isFinalized" 
        [frozen]="true" 
        [justifyContent]="'space-around'"
        [focusFirst]="true"
        [emitNameValidStatus]="true"
        [rebuildTrigger]="updateTrigger"
        >
    </app-generic-form>
    <div *ngIf="e_label_input">
      <h1 class="text-2xl font-bold mb-4 mt-4 text-center">{{this.textFragments.textFragmentsLanguage(this.ES, this.EN, this.DE)?.eLabel}}</h1>
      <app-generic-form *ngIf="product_key"
        [xname]="'product-general-form-II'" 
        [entity]="product_key"
        [backend]="'main'" 
        [col_number]="3" 
        [infoForm]="isFinalized" 
        [frozen]="true" 
        [justifyContent]="'space-around'"
        [focusFirst]="true"
        [emitNameValidStatus]="true"
        [rebuildTrigger]="updateTrigger"
        >
      </app-generic-form>
      <app-generic-form *ngIf="product_key"
        [xname]="'product-nutrient-form'" 
        [entity]="product_key"
        [backend]="'main'" 
        [col_number]="4" 
        [infoForm]="isFinalized" 
        [frozen]="true" 
        [justifyContent]="'space-around'"
        [focusFirst]="true"
        [emitNameValidStatus]="true"
        [rebuildTrigger]="updateTrigger"
      >
      </app-generic-form>
    </div>
    <h1 class="text-2xl font-bold mb-4 mt-4 text-center">{{this.textFragments.textFragmentsLanguage(this.ES, this.EN, this.DE)?.consumerInfo}}</h1>
    <app-generic-form *ngIf="product_key"
      [xname]="'product-general-form-I'" 
      [entity]="product_key"
      [backend]="'main'" 
      [col_number]="2" 
      [infoForm]="isFinalized" 
      [frozen]="true" 
      [justifyContent]="'space-around'"
      [focusFirst]="true"
      [emitNameValidStatus]="true"
      [rebuildTrigger]="updateTrigger"
      >
    </app-generic-form>
    <app-generic-form *ngIf="product_key"
      [xname]="'product-consumer-info-form'" 
      [entity]="product_key"
      [backend]="'main'" 
      [col_number]="2" 
      [infoForm]="isFinalized" 
      [frozen]="true" 
      [justifyContent]="'space-around'"
      [focusFirst]="true"
      [emitNameValidStatus]="true"
      [rebuildTrigger]="rebuildFormTrigger"
      [rebuildTrigger]="updateTrigger"
      >
    </app-generic-form>
    <h1 class="text-2xl font-bold mb-4 mt-12 text-center">{{this.textFragments.textFragmentsLanguage(this.ES, this.EN, this.DE)?.tastingNotes}}</h1>
    <div cdkDropList class="frame mt-10" (cdkDropListDropped)="drop($event)">
      <div class="form-box" *ngFor="let product_feature_item of featuresProduct" cdkDrag> 
        <app-generic-form *ngIf="product_key"
          [xname]="'product-feature-info-form'"
          [entity]="product_feature_item"
          [backgroundColor]="true"
          [updateItemsCallbackId]="updateItemsCallbackIdB"
          [backend]="'main'"
          [col_number]="2"
          [infoForm]="isFinalized" 
          [justifyContent]="'space-around'"
          [frozen]="false"
          [emitNameValidStatus]="true"
          [rebuildTrigger]="updateTrigger"
          >
        </app-generic-form>
        <div class="example-handle" cdkDragHandle>
          <svg width="24px" fill="currentColor" viewBox="0 0 24 24">
              <path d="M10 9h4V6h3l-5-5-5 5h3v3zm-1 1H6V7l-5 5 5 5v-3h3v-4zm14 2l-5-5v3h-3v4h3v3l5-5zm-9 3h-4v3H7l5 5 5-5h-3v-3z"></path>
              <path d="M0 0h24v24H0z" fill="none"></path>
          </svg>
        </div>
      </div>
      <button mat-stroked-button class="new-process-button" 
        (click)="add_feature()" [disabled]="isFinalized">
        {{this.textFragments.textFragmentsLanguage(this.ES, this.EN, this.DE)?.addTastingNote}}
        <mat-icon>add_circle_outline</mat-icon>
      </button>
    </div>
    <h1 class="text-2xl font-bold mb-4 mt-8 text-center">{{this.textFragments.textFragmentsLanguage(this.ES, this.EN, this.DE)?.productCertificates}}</h1>
    <div cdkDropList class="frame mt-10" (cdkDropListDropped)="drop($event)">
      <div class="form-box" *ngFor="let product_cert_item of certsProduct" cdkDrag> 
        <app-generic-form *ngIf="product_key"
          [xname]="'product-cert-form'"
          [entity]="product_cert_item"
          [backgroundColor]="true"
          [updateItemsCallbackId]="updateItemsCallbackIdC"
          [backend]="'main'"
          [col_number]="2"
          [infoForm]="isFinalized" 
          [justifyContent]="'space-around'"
          [frozen]="false"
          [emitNameValidStatus]="true"
          [rebuildTrigger]="updateTrigger"
          >
        </app-generic-form>
        <div class="example-handle" cdkDragHandle>
          <svg width="24px" fill="currentColor" viewBox="0 0 24 24">
              <path d="M10 9h4V6h3l-5-5-5 5h3v3zm-1 1H6V7l-5 5 5 5v-3h3v-4zm14 2l-5-5v3h-3v4h3v3l5-5zm-9 3h-4v3H7l5 5 5-5h-3v-3z"></path>
              <path d="M0 0h24v24H0z" fill="none"></path>
          </svg>
        </div>
      </div>
      <button mat-stroked-button class="new-process-button" 
        (click)="add_certificate()" [disabled]="isFinalized">
        {{this.textFragments.textFragmentsLanguage(this.ES, this.EN, this.DE)?.addCertificate}}
        <mat-icon>add_circle_outline</mat-icon>
      </button>
    </div>
    <h1 class="text-2xl font-bold mb-4 mt-8 text-center">{{this.textFragments.textFragmentsLanguage(this.ES, this.EN, this.DE)?.productAwards}}</h1>
    <div cdkDropList class="frame mt-10" (cdkDropListDropped)="drop($event)">
      <div class="form-box" *ngFor="let product_award_item of awardsProduct" cdkDrag> 
        <app-generic-form *ngIf="product_key"
          [xname]="'product-award-form'"
          [entity]="product_award_item"
          [backgroundColor]="true"
          [updateItemsCallbackId]="updateItemsCallbackIdD"
          [backend]="'main'"
          [col_number]="2"
          [infoForm]="isFinalized" 
          [justifyContent]="'space-around'"
          [frozen]="false"
          [emitNameValidStatus]="true"
          [rebuildTrigger]="updateTrigger"
          >
        </app-generic-form>
        <div class="example-handle" cdkDragHandle>
          <svg width="24px" fill="currentColor" viewBox="0 0 24 24">
              <path d="M10 9h4V6h3l-5-5-5 5h3v3zm-1 1H6V7l-5 5 5 5v-3h3v-4zm14 2l-5-5v3h-3v4h3v3l5-5zm-9 3h-4v3H7l5 5 5-5h-3v-3z"></path>
              <path d="M0 0h24v24H0z" fill="none"></path>
          </svg>
        </div>
      </div>
      <button mat-stroked-button class="new-process-button" 
        (click)="add_award()" [disabled]="isFinalized">
        {{this.textFragments.textFragmentsLanguage(this.ES, this.EN, this.DE)?.addAward}}<mat-icon>add_circle_outline</mat-icon>
      </button>
    </div>
  </div>
  <div *ngIf="selectedID === 'Cultivation'" class="bg-white rounded shadow p-6">
    <h1 class="text-2xl font-bold mb-4 mt-4 text-center">{{this.textFragments.textFragmentsLanguage(this.ES, this.EN, this.DE)?.cultivationInfo}}</h1>
    <app-generic-form *ngIf="product_key"
        [xname]="'product-cultivation-form'" 
        [entity]="product_key"
        [backend]="'main'" 
        [col_number]="3" 
        [infoForm]="isFinalized" 
        [frozen]="true" 
        [justifyContent]="'space-around'"
        [focusFirst]="true"
        [emitNameValidStatus]="true"
        [rebuildTrigger]="updateTrigger"
      >
    </app-generic-form>
    <app-generic-form *ngIf="product_key"
        [xname]="'product-cultivation-form-3'" 
        [entity]="product_key"
        [backend]="'main'" 
        [col_number]="2" 
        [infoForm]="isFinalized" 
        [frozen]="true" 
        [justifyContent]="'space-around'"
        [focusFirst]="true"
        [emitNameValidStatus]="true"
        [rebuildTrigger]="updateTrigger"
        >
    </app-generic-form>
    <app-generic-form *ngIf="product_key"
        [xname]="'product-cultivation-form-2'" 
        [entity]="product_key"
        [backend]="'main'" 
        [col_number]="2" 
        [infoForm]="isFinalized" 
        [frozen]="true" 
        [justifyContent]="'space-around'"
        [focusFirst]="true"
        [emitNameValidStatus]="true"
        [rebuildTrigger]="updateTrigger"
        >
    </app-generic-form>
  </div>
  <div *ngIf="selectedID === 'Production'" class="bg-white rounded shadow p-6">
    <h1 class="text-2xl font-bold mb-4 mt-8 text-center">{{this.textFragments.textFragmentsLanguage(this.ES, this.EN, this.DE)?.productionInfo}}</h1>
    <app-generic-form *ngIf="product_key"
        [xname]="'product-production-form'" 
        [entity]="product_key"
        [backend]="'main'" 
        [col_number]="3" 
        [infoForm]="isFinalized" 
        [frozen]="true" 
        [justifyContent]="'space-around'"
        [focusFirst]="true"
        [emitNameValidStatus]="true"
        [rebuildTrigger]="updateTrigger"
        >
    </app-generic-form>
    <app-generic-form *ngIf="product_key"
        [xname]="'product-production-form-2'" 
        [entity]="product_key"
        [backend]="'main'" 
        [col_number]="3" 
        [infoForm]="isFinalized" 
        [frozen]="true" 
        [justifyContent]="'space-around'"
        [focusFirst]="true"
        [emitNameValidStatus]="true"
        [rebuildTrigger]="updateTrigger"
        >
    </app-generic-form>
  </div>
  <div *ngIf="selectedID === 'Bottling'" class="bg-white rounded shadow p-6">
    <h1 class="text-2xl font-bold mb-4 mt-4 text-center">{{this.textFragments.textFragmentsLanguage(this.ES, this.EN, this.DE)?.bottlingInfo}}</h1>
    <app-generic-form *ngIf="product_key"
        [xname]="'product-bottling-form'" 
        [entity]="product_key"
        [backend]="'main'" 
        [col_number]="3" 
        [infoForm]="isFinalized" 
        [frozen]="true" 
        [justifyContent]="'space-around'"
        [focusFirst]="true"
        [emitNameValidStatus]="true"
        [rebuildTrigger]="updateTrigger"
        >
    </app-generic-form>
    <app-generic-form *ngIf="product_key"
        [xname]="'product-bottling-form-II'" 
        [entity]="product_key"
        [backend]="'main'" 
        [col_number]="2" 
        [infoForm]="isFinalized" 
        [frozen]="true" 
        [justifyContent]="'space-around'"
        [focusFirst]="true"
        [emitNameValidStatus]="true"
        [rebuildTrigger]="updateTrigger"
        >
    </app-generic-form>
  </div>
  <div *ngIf="selectedID === 'Transportation'" class="bg-white rounded shadow p-6">
    <h1 class="text-2xl font-bold mb-4 mt-4 text-center">{{this.textFragments.textFragmentsLanguage(this.ES, this.EN, this.DE)?.transportationInfo}}</h1>
    <h2 class="text-1xl font-bold mb-4 text-center">{{this.textFragments.textFragmentsLanguage(this.ES, this.EN, this.DE)?.transportationSub}}</h2>

    <app-generic-form *ngIf="product_key"
        [xname]="'product-transport-form'" 
        [entity]="product_key"
        [backend]="'main'" 
        [col_number]="1" 
        [infoForm]="isFinalized" 
        [frozen]="true" 
        [justifyContent]="'space-around'"
        [focusFirst]="true"
        [emitNameValidStatus]="true"
        [rebuildTrigger]="updateTrigger"
        >
    </app-generic-form>
  </div>
  <div *ngIf="selectedID === 'Sales'" class="bg-white rounded shadow p-6">
    <h1 class="text-2xl font-bold mb-4 mt-8 text-center">{{this.textFragments.textFragmentsLanguage(this.ES, this.EN, this.DE)?.salesLocations}}</h1>
    <div cdkDropList class="frame mt-10" (cdkDropListDropped)="drop($event)">
      <div class="form-box" *ngFor="let product_sales_item of salesProduct" cdkDrag> 
        <app-generic-form *ngIf="product_key"
          [xname]="'product-sales-form'"
          [entity]="product_sales_item"
          [backgroundColor]="true"
          [updateItemsCallbackId]="updateItemsCallbackIdF"
          [backend]="'main'"
          [col_number]="3"
          [infoForm]="isFinalized" 
          [justifyContent]="'space-around'"
          [frozen]="false"
          [emitNameValidStatus]="true"
          [rebuildTrigger]="updateTrigger"
          >
        </app-generic-form>
        <div class="example-handle" cdkDragHandle>
          <svg width="24px" fill="currentColor" viewBox="0 0 24 24">
              <path d="M10 9h4V6h3l-5-5-5 5h3v3zm-1 1H6V7l-5 5 5 5v-3h3v-4zm14 2l-5-5v3h-3v4h3v3l5-5zm-9 3h-4v3H7l5 5 5-5h-3v-3z"></path>
              <path d="M0 0h24v24H0z" fill="none"></path>
          </svg>
        </div>
      </div>
      <button mat-stroked-button class="new-process-button" 
        (click)="add_sales()" [disabled]="isFinalized">
        {{this.textFragments.textFragmentsLanguage(this.ES, this.EN, this.DE)?.addSalesLocation}}
        <mat-icon>add_circle_outline</mat-icon>
      </button>
    </div>
  </div>
  <div *ngIf="selectedID === 'Batch'" class="bg-white rounded shadow p-6">
    <h1 class="text-2xl font-bold mb-2 mt-8 text-center">{{this.textFragments.textFragmentsLanguage(this.ES, this.EN, this.DE)?.batchInfo}}</h1>
    <h2 class="text-1xl font-bold mb-4 text-center">{{this.textFragments.textFragmentsLanguage(this.ES, this.EN, this.DE)?.batchInfoSub}}</h2>
    <app-generic-form *ngIf="active_batch_key"
        [xname]="'product-batch-form'" 
        [entity]="active_batch_key"
        [backend]="'main'" 
        [col_number]="3" 
        [infoForm]="batch_finalized[active_batch_key] || isFinalized"
        [frozen]="true" 
        [justifyContent]="'space-around'"
        [focusFirst]="true"
        [emitNameValidStatus]="true"
        [rebuildTrigger]="updateTrigger"
        >
    </app-generic-form>
    <app-generic-form *ngIf="active_batch_key"
        [xname]="'product-batch-form-2'"
        [entity]="active_batch_key"
        [backend]="'main'" 
        [col_number]="2" 
        [infoForm]="isFinalized" 
        [frozen]="true" 
        [justifyContent]="'space-around'"
        [focusFirst]="true"
        [emitNameValidStatus]="true"
        [rebuildTrigger]="updateTrigger"
        >
    </app-generic-form>
  </div>
</section>

