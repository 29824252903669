<div class="top-action">
  <button mat-icon-button (click)="onCancel()">
    <mat-icon>close</mat-icon>
  </button>
</div>
<h3 mat-dialog-title class="dialog-title">{{data.title}}</h3>
<mat-dialog-content>
  <app-filter-panel (sortTrigger)="setSortTrigger($event)" [title]="''" [backend]="data.backend" [sort]="data.sort"
  [maxWidth]="data.filterMaxWidth" [optionsMaxHeight]="data.optionsMaxHeight" [filtersMaxHeight]="data.filtersMaxHeight" [controller]="data.controller"></app-filter-panel>
</mat-dialog-content>
<div mat-dialog-actions class="dialog-actions">
  <button mat-button *ngIf="displaySaveButton" (click)="onOkSave()" [mat-dialog-close]="data">Schließen & Merken</button>
  <button mat-button (click)="onOk()" [mat-dialog-close]="data" cdkFocusInitial>Schließen</button>
</div>