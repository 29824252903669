<!-- <div class="h-full rounded shadow-lg mx-auto relative">
  <img class="h-1/2 w-full" src="assets/exampleOilBottle.jpg" alt="Card Image">
  <section class="h-1/2 overflow-y-auto custom-scrollbar">
    <div class="px-3 pb-5">
      <p class="text-gray-700 text-base">
        <b>CASTILLO DE CANENA</b> 100% Picual extra virgin <b>olive oil</b> produced using bio-dynamic farming from Jaen bottle 500ml
      </p>
    </div>
    <div *ngIf="showContent" class="px-3 pt-2 pb-2">
      <p class="text-gray-700 text-base">
        First day of harvest. Intense green color, with a density and a notable bouquet of fresh olives. The palate is very complex, with a great variety of herbaceous and fruity notes, such as green tomato, artichoke, green almond, and banana. The bitterness and spiciness are very balanced, with a long and persistent finish.
        ...
        ...
        ...
      </p>
    </div>
    <div class="px-3 pb-3 absolute bottom-0 right-0">
      <button (click)="toggleContent()">
        <img src="assets/arrowDown.png" class="h-4 w-4" *ngIf="!showContent" alt="Show more">
        <img src="assets/arrowUp.png" class="h-4 w-4" *ngIf="showContent" alt="Show less">
      </button>
    </div>
  </section>
</div> -->
<div class="rounded shadow-lg mx-auto relative h-[500px]">
  <swiper-container #swiperEl class="h-full"
    loop="true"
    speed="1000"
    autoplay-delay="2000"
    autoplay="true"
    allow-touch-move="true"
  >
    <swiper-slide *ngFor="let item of tips; let ii=index ">
      <img class="h-40 w-full mb-2" [src]="img_urls[ii]" alt="Card Image">
      <section class="h-[calc(100%-170px)] overflow-y-auto custom-scrollbar p-2">
        <p class="font-bold text-2xl text-center border-b-[1px] border-solid border-gray-400">{{this.textFragments.textFragmentsLanguage(this.ES, this.EN, this.DE)?.didYouKnow}}<p>
        <p class="text-gray-700 pt-3 text-lg text-center">
          {{item}}
        </p>
      </section>
    </swiper-slide>
  </swiper-container>
</div>
