import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { MessageService } from 'src/app/shared-generic/services/message.service';
import { TextFragmentService } from 'src/app/ui-main/services/text-fragment.service';


@Component({
  selector: 'app-modal-preview',
  templateUrl: './modal-preview.component.html',
  styleUrls: ['./modal-preview.component.scss']
})
export class ModalPreviewComponent implements OnInit, OnDestroy {
  EN = {
    preview: 'Preview',
    reload: 'Reload'
  }
  
  ES = {
    preview: 'Previsualizar',
    reload: 'Actualizar'
  }
  
  DE = {
    preview: 'Vorschau',
    reload: 'Aktualisieren'
  }
  
  public product_key = "";
  public mobile_url: SafeResourceUrl;

  private subscriptions: Array<Subscription>=[];

  public selectedProduct = ""

  constructor(private messageService: MessageService,
              private sanitizer: DomSanitizer,
              public dialogRef: MatDialogRef<ModalPreviewComponent>,
              public textFragments : TextFragmentService,
              @Inject(MAT_DIALOG_DATA) public data: any, 
  ) { 
    this.launchPreview();
  }

  ngOnInit(): void {
    // console.log("data inside", this.data);
    this.textFragments.textFragmentsLanguage(this.ES, this.EN, this.DE);
    this.product_key = this.data.productId;
    
  }

  launchPreview() {
    console.log("LAUNCH", this.data);
    const user = this.messageService.user;
    //let url = "https://data.vistrace.quantecdc.es/?id=" + id;
    let url = "/app?id=" + this.data.productId+"&user="+user+"&forward=1";
    this.urlSanitizer(url)
  }

  // previewClicked() {
  //   this.urlSanitizer(this.url)
  // }

  // urlSanitizer(url: string) {
  //   this.mobile_url = this.sanitizer.bypassSecurityTrustResourceUrl(url)
  // }

  closePreview() {
    this.dialogRef.close();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach( (subs) =>{
        subs.unsubscribe();
      }
    );
  }

  urlSanitizer(url: string) {
    this.mobile_url = this.sanitizer.bypassSecurityTrustResourceUrl(url)
  }



}
