<div class="flex lg:px-[8%] h-full justify-center pt-16 mb-5 overflow-auto">
  <div class="rounded w-96">
      <h2 class="text-2xl font-semibold mb-4">{{this.textFragments.textFragmentsLanguage(this.ES, this.EN, this.DE)?.title}}</h2>
      <form>
          <div class="mb-4">
              <label for="username" class="block text-sm font-medium text-gray-700">{{this.textFragments.textFragmentsLanguage(this.ES, this.EN, this.DE)?.email}}</label>
              <input type="text" name="username" [(ngModel)]="email" class="mt-1 p-2 block w-full border rounded-md shadow-sm focus:outline-none focus:border-blue-500">
          </div>
          <button (click)="onSubmit()" class="w-full bg-vistrace-primary text-white py-2 px-4 rounded-md hover:bg-vistrace-quinary focus:outline-none focus:bg-vistrace-quinary">{{this.textFragments.textFragmentsLanguage(this.ES, this.EN, this.DE)?.recoverButtonText}}</button>
          <div class="mt-4 text-center">
              <a (click)="toLogin()" class="text-vistrace-quinary hover:underline hover:text-vistrace-primary hover:cursor-pointer">{{this.textFragments.textFragmentsLanguage(this.ES, this.EN, this.DE)?.loginText}}</a><br>
          </div>
      </form>
  </div>
</div>