import { APP_INITIALIZER, LOCALE_ID, NgModule } from '@angular/core';
import {DateAdapter} from '@angular/material/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

// local moduls
import { SharedModule } from './shared/shared.module';
import { SharedGenericModule } from './shared-generic/shared-generic.module';
//import { CoreModule } from './core/core.module';
import { UiMainModule } from './ui-main/ui-main.module';

//import { EngineComponent } from './engine-cad/components/engine/engine.component';

// materials
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatButtonModule} from '@angular/material/button';
import {MatRadioModule} from '@angular/material/radio';
import {MatCardModule} from '@angular/material/card';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {MatSidenavModule} from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSelectModule} from '@angular/material/select';
import {MatInputModule} from '@angular/material/input';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import { MatDialogModule } from '@angular/material/dialog';

//register de locales
import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import{MyDateAdapter} from './shared/types/my-date-adapter'

import { CookieModule } from 'ngx-cookie';
import { NgxSpinnerModule } from "ngx-spinner";

import { MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';
import { initializeKeycloak } from './init/keycloak-init.factory';

//below steps are for module.ts file
// 1 step : 
import { CUSTOM_ELEMENTS_SCHEMA,} from '@angular/core';

// 3rd step : 
import { register } from 'swiper/element/bundle';
              
// and  
register(); // for swiper
registerLocaleData(localeDe, 'de');

const MY_DATE_FORMAT = {
  parse: {
    dateInput: 'DD.MM.YYYY', // this is how your date will be parsed from Input
  },
  display: {
    dateInput: 'DD.MM.YYYY', // this is how your date will get displayed on the Input
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@NgModule({
  declarations: [
    AppComponent,
    // EngineComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    CookieModule.withOptions(),
    AppRoutingModule,
    BrowserModule,
    HttpClientModule,
    SharedModule,
    SharedGenericModule,
    //CoreModule,
    UiMainModule,
    MatButtonModule,
    MatCheckboxModule,
    MatCardModule,
    MatRadioModule,
    BrowserAnimationsModule,
    MatSidenavModule,
    MatIconModule,
    MatToolbarModule,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    MatDialogModule,
    MatProgressBarModule,
    NgxSpinnerModule,
    KeycloakAngularModule,
    //StripeModule.forRoot('pk_test_51J3k8vKdBghYZ0A8VVCBErEapgorIecJwW99dYTZ8xC7ZdkHEENEeRb4kQsv0uutdoy0z9Y6vh1Mzw6ptfw2Oryz00XVCB9mXm')
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'en-US' },     // <-- Locale for Germany
    {provide: DateAdapter, useClass: MyDateAdapter},
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMAT },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeKeycloak,
      multi: true,
      deps: [KeycloakService],
    }
  
  ],
  exports: [
    // EngineComponent
    NgxSpinnerModule
  ],
  bootstrap: [
    AppComponent
  ]
})
export class AppModule { }
