import { filter } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { Component, Input, OnDestroy, AfterViewInit } from '@angular/core';
import { BokehService } from '../../services/bokeh.service';


const titles ={
  chart_build_job_overview_A: "Belichtung",
  chart_build_job_overview_X: "Schichten",
  chart_build_job_overview_B: "Sauerstoff",
  chart_build_job_overview_C: "Gasströmung",
  chart_build_job_overview_D: "Filterzustand"
}

@Component({
  selector: 'app-chart-carousel',
  templateUrl: './chart-carousel.component.html',
  styleUrls: ['./chart-carousel.component.scss']
})
export class ChartCarouselComponent implements AfterViewInit, OnDestroy {
  @Input() charts:any; 
  @Input() backend = 'machine';
  @Input() headerless: boolean = false;
  @Input() replot = '';
  
  public title="Component Chart-Carousel";
  public chartFieldData = [];
  public subs: Subscription[] = [];
  public col = "col1";
  public frozen = true;
  public noicon = true;
  public visible_idx = 0;
  public titles = titles;

  constructor( private bokehService:BokehService ) { }

  ngAfterViewInit(): void {
    this.subs.push(this.bokehService.getChartFieldData(this.charts, this.backend).pipe(filter(msg => msg.args.chartFieldId === this.charts))
    .subscribe(msg => {
        if (!msg.args.chartFieldData) {
            return;
        }
        // console.log('received chartFieldData...', msg);
        this.chartFieldData = msg.args.chartFieldData;
    }));
  }

  ngOnDestroy() {
    this.subs.forEach(subs => subs.unsubscribe());
  }

}
