import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SessionService } from './session-service';

@Injectable({
  providedIn: 'root'
})
export class ProductService {
  
  private serverUrlOlea: string = '/api'

  constructor(private http: HttpClient,
              private sessionService: SessionService
    ) { }

  getCustomHeaders(): HttpHeaders {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('x-api-key', 'xie2beiyidoom8ooQuahd2haalaeJahyeeshah7t')
    //console.log("HEADER", headers);
    return headers;
  }

  public getProductData(product_key: string, language?: string, batch_number?: string) {
    // language is short version: EN, DE, ES
    const header = this.getCustomHeaders();
    return this.http.post<any>(`${this.serverUrlOlea}/get_product_data`, 
      { product_key: product_key, 
        batch_number: batch_number, 
        target_language: language, 
        save_result: true
      },
      { headers: header })      
  }

  public sendContactEmail(email: string, context: string) {
    const header = this.getCustomHeaders();
    return this.http.post<any>(`${this.serverUrlOlea}/send_contact_email`, 
      { email: email, context: context },
      { headers: header }) 
  }

}
