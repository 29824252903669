import { Component, OnInit } from '@angular/core';
import { ChatBotMessage } from '../../types/chatbotMessages';
import { ChatService } from '../../services/chat.service';
import { MessageService } from 'src/app/shared-generic/services/message.service';
import { TextFragmentService } from 'src/app/ui-main/services/text-fragment.service';

@Component({
  selector: 'app-chat-bot',
  templateUrl: './chat-bot.component.html',
  styleUrls: ['./chat-bot.component.scss']
})
export class ChatBotComponent implements OnInit {

  public userMessage: ChatBotMessage = {role: 'user', content: ''}

  constructor(public chatService: ChatService,
              private messageService: MessageService,        
              public textFragments : TextFragmentService
            ) { }

  ngOnInit(): void {
    this.textFragments.textFragmentsLanguage(this.ES, this.EN, this.DE);
  }

  EN = {
    powered : 'Powered by Quantec Data Center',
    hello : 'Hello, how can I help you today?',
    user : 'User',
    send : 'Send',
  }

  ES = {
    powered : 'Desarrollado por Quantec Data Center',
    hello : 'Hola, ¿cómo puedo ayudarte hoy?',
    user : 'Usuario',
    send : 'Enviar',
  }

  DE = {
    powered : 'Powered by Quantec Data Center',
    hello : 'Hallo, wie kann ich Ihnen heute helfen?',
    user : 'Benutzer',
    send : 'Senden',
  }

  public sendUserMessage(): void {
    this.chatService.messages.push(this.userMessage)
    this.userMessage = {role: 'user', content: ''}
    const callbackId = 'groqAnswer';
    const message = {
          name: 'askGroq',
          args: [this.chatService.messages, callbackId]
    };
    this.messageService.sendMsg(message, 'main');
    //this.chatService.messages.push({role: 'assistant', content: 'I am a bot, I am not able to help you with that.'})

  }

}
