import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { ProjectSetupService } from 'src/app/shared/services/project-setup.service';

@Injectable({
  providedIn: 'root'
})
export class TextFragmentService {
  language$ = new Subject<string>();
  language = 'EN';

  constructor(
    private projectSetupService: ProjectSetupService
  ) { }

  setLanguage(lang: string) {
    this.language = lang;
    setTimeout(()=>this.language$.next(lang), 100);
    this.projectSetupService.language = lang;
  }
  
  textFragmentsLanguage(ES: any, EN: any, DE: any) {
    if (this.language=="ES"){ return ES; } 
    else if (this.language=="EN"){ return EN; } 
    else if (this.language=="DE"){ return DE; } 
    else { return }
  }

}
