import { Component, Input, OnInit, OnDestroy} from '@angular/core';
import { FilterGroup } from './../../types/filter-group';
import { FilterOption } from './../../types/filter-option';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { FilterService } from '../../services/filter.service';

@Component({
  selector: 'app-filter-group',
  templateUrl: './filter-group.component.html',
  styleUrls: ['./filter-group.component.scss']
})
export class FilterGroupComponent implements OnInit, OnDestroy {
  /*
   ____________
  |Group 1     |
  |____________|
  |Group 2     |
  |____________|
  |Group 3     |
  |o Option 1  |
  |o Option 2  |
  |____________|
  
  */
  public filterGroups: FilterGroup[] = [];
  public subs: Subscription[] = [];
  @Input() backend = 'offer-creator';
  @Input() maxWidth = "200";
  @Input() optionsMaxHeight;
  @Input() controller = "";

  constructor(private filterService: FilterService) { }

  ngOnInit(): void {
    this.subs.push(this.filterService.getFilterGroups(this.controller, this.backend).pipe(filter(msg => msg.args.identifier === this.backend + this.controller))
      .subscribe(
        msg => {
          this.filterGroups = msg.args.filterGroups;
        }));
  }

  public statusChanged(filter: FilterOption):void {
    // console.log("...checkbox...", filter);
    this.filterService.setFilterSelectionGroupMessage(this.controller, this.backend, filter)
  }

  public setLastOpened(groupId: string): void {
    this.filterService.lastOpenedGroup = groupId;
  }

  public removeLastOpened(groupId: string): void {
    if (this.filterService.lastOpenedGroup == groupId) {
      this.filterService.lastOpenedGroup = "";
    }
  }

  ngOnDestroy() {
    this.subs.forEach(subs => subs.unsubscribe());
  }

}
