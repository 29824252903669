import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { KeycloakAuthGuard, KeycloakService } from 'keycloak-angular';
import { MessageService } from '../shared-generic/services/message.service';
import { DialogLoginService } from '../shared-generic/services/dialog-login.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard extends KeycloakAuthGuard {
  
  public permission = false;
  constructor(
    protected readonly router: Router,
    protected readonly keycloak: KeycloakService,
    private messageService: MessageService,
    private loginService: DialogLoginService
  ) {
    super(router, keycloak);
  }
  
  async isAccessAllowed(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Promise<boolean | UrlTree> {
      //return true
    
      console.log("loginMOde authguard: ", this.loginService.loginMode, this.authenticated, this.permission)
      if (!this.permission) {
        if (this.loginService.loginMode == "") {
          console.log("WAIT in AUTH-Guard...:", route, this.authenticated, this.loginService.loginMode, this.permission);
          try {
            var val = await this.loginService.set_login_mode();
            console.log("....VAL", val, this.loginService.loginMode, this.authenticated, this.permission);
          } catch (error) {
            console.log(".....ERROR in login Service");
            this.permission = false;
          }
        }
        if (this.loginService.loginMode === "keycloak") {
          await this.keycloak.login({
            // in our case the redirection is done in the app
            redirectUri: window.location.origin, //+ state.url,
          }).catch((e) => console.error(e));
        } else {
          // all other cases set all routes for free ....
          console.log("SET permissions due to", this.loginService.loginMode);
          this.permission = true;
        }
      }
      // If keycloak returns this.authenticated = true
      if (this.authenticated) {
        this.permission = true;
      }
      return this.permission;
  }

  async getToken() {
    const token = await this.keycloak.getToken();
    console.log("TOKEN", token);
  }
}
