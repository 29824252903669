<div class="flex lg:px-[8%] h-full justify-center pt-16 mb-5 overflow-auto">
    <div class="rounded w-96">
        <div class="flex justify-center mb-4">
            <h2 class="text-2xl font-semibold">{{this.textFragments.textFragmentsLanguage(this.ES, this.EN, this.DE)?.recoverPassword}}</h2>
        </div>
        <div class="mb-4 relative">
            <label for="username" class="block text-sm font-medium text-gray-700">{{this.textFragments.textFragmentsLanguage(this.ES, this.EN, this.DE)?.password}}</label>
            <input [type]="passwordVisible ? 'text' : 'password'" [(ngModel)]="newPassword" class="mt-1 p-2 block w-full border rounded-md shadow-sm focus:outline-none focus:bg-vistrace-quinary">
            <img (click)="togglePasswordVisibility()" class="visibility-icon" [src]="passwordVisible ? 'assets/visibility-off.svg' : 'assets/visbility.svg'">
        </div>
        <div class="mb-4 relative">
            <label for="password" class="block text-sm font-medium text-gray-700">{{this.textFragments.textFragmentsLanguage(this.ES, this.EN, this.DE)?.retypePassword}}</label>
            <input [type]="passwordVisible ? 'text' : 'password'" [(ngModel)]="confirmPassword" class="mt-1 p-2 block w-full border rounded-md shadow-sm focus:outline-none focus:bg-vistrace-quinary">
        </div>
        <button (click)="onSubmit()" type="submit" class="w-full bg-vistrace-primary text-white py-2 px-4 rounded-md hover:bg-vistrace-quinary focus:outline-none focus:bg-vistrace-quinary ">{{this.textFragments.textFragmentsLanguage(this.ES, this.EN, this.DE)?.send}}</button>
    </div>
</div>