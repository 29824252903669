import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-product-info-view',
  templateUrl: './product-info-view.component.html',
  styleUrls: ['./product-info-view.component.scss'],
})
export class ProductInfoViewComponent implements OnInit {
  productId = "";

  constructor(private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.productId = params['id']; // Access the 'id' parameter from the URL
      // this.batchId = params['batch']; // Access the 'batch' parameter from the URL
      if (this.productId=="0") {
        this.productId = "";
      }
    });
  }

  ngOnDestroy(): void {
  }
}