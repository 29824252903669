
<!-- <div id="{{'main-wrapper-' + infoCard.infos[0]}}" [ngClass]="{'isSelected': isSelected}"> -->

<div (click)="myClickFunction($event)"  class="main-wrapper"
  [ngClass]="[infoCard.isSelected ? 'isSelected' : '', infoCard.draggable? 'open': '']"
  [ngStyle]="{'width': cardWidth + 'px'}">
  <div class="wrapper-infos">
    <div *ngFor="let info of infoCard.infos">{{info}}</div>
    <app-generic-form *ngIf="infoCard.entityId"
      [xname]="infoCard.formName"
      [backend]="infoCard.backend"
      [titlesize]="14"
      [fontsize]="14"
      [entity]="infoCard.entityId"
      [col_number]="1"
      [frozen]="true"
      [boundingBox]="false"
      [formPadding]="5"
      [formSpacing]="false"
    ></app-generic-form>
  </div>
  <div *ngIf="infoCard.draggable" class="example-handle" cdkDragHandle>
    <svg width="24px" fill="currentColor" viewBox="0 0 24 24">
        <path d="M10 9h4V6h3l-5-5-5 5h3v3zm-1 1H6V7l-5 5 5 5v-3h3v-4zm14 2l-5-5v3h-3v4h3v3l5-5zm-9 3h-4v3H7l5 5 5-5h-3v-3z"></path>
        <path d="M0 0h24v24H0z" fill="none"></path>
    </svg>
  </div>
  <div *ngIf="infoCard.icons" class="wrapper-icons">
    <mat-icon class="icon" *ngFor="let icon of icons" 
      id="{{icon.icon}}" 
      [matTooltip]="icon.iconTooltip"
      [ngClass]="{'displayOnHover': icon.displayOnHover}"
      (click)="clickedIcon($event, icon)">{{icon.icon}}</mat-icon>
  </div>
</div>