
<div class="w-full h-full justify-center items-center">

  <div class="w-full flex-grow justify-center items-center pt-4 relative border-white">
    <!-- <div class="h-full w-1 z-0 bg-blue-500 absolute left-8 transform -translate-x-1/2"></div> -->
    <app-product-menu-item 
      [title]="this.textFragments.textFragmentsLanguage(this.ES, this.EN, this.DE)?.productInfo"
      [id]="this.textFragments.textFragmentsLanguage(this.EN, this.EN, this.EN)?.productInfo"
      [isFinished]="product_info_finished"
      (itemSelected)="onItemSelected($event)" 
      [isSelected]="isSelected('Product Info')"
      [deactivated]="isSelectedNone()"
      >
    </app-product-menu-item>
    <app-product-menu-item 
      [title]="this.textFragments.textFragmentsLanguage(this.ES, this.EN, this.DE)?.cultivation"
      [id]="this.textFragments.textFragmentsLanguage(this.EN, this.EN, this.EN)?.cultivation"
      [isFinished]="cultivation_finished"
      (itemSelected)="onItemSelected($event)" 
      [isSelected]="isSelected('Cultivation')"
      [deactivated]="isSelectedNone()"
      >
    </app-product-menu-item>
    <app-product-menu-item 
      [title]="this.textFragments.textFragmentsLanguage(this.ES, this.EN, this.DE)?.production"
      [id]="this.textFragments.textFragmentsLanguage(this.EN, this.EN, this.EN)?.production"
      [isFinished]="production_finished"
      (itemSelected)="onItemSelected($event)" 
      [isSelected]="isSelected('Production')"
      [deactivated]="isSelectedNone()"
      >
    </app-product-menu-item>
    <app-product-menu-item 
      [title]="this.textFragments.textFragmentsLanguage(this.ES, this.EN, this.DE)?.bottling"
      [id]="this.textFragments.textFragmentsLanguage(this.EN, this.EN, this.EN)?.bottling"
      [isFinished]="bottling_finished"
      (itemSelected)="onItemSelected($event)" 
      [isSelected]="isSelected('Bottling')"
      [deactivated]="isSelectedNone()"
      >
    </app-product-menu-item>
    <app-product-menu-item
      [title]="this.textFragments.textFragmentsLanguage(this.ES, this.EN, this.DE)?.transport"
      [id]="this.textFragments.textFragmentsLanguage(this.EN, this.EN, this.EN)?.transport"
      [isFinished]="transport_finished"
      (itemSelected)="onItemSelected($event)" 
      [isSelected]="isSelected('Transportation')"
      [deactivated]="notPlan2andHigher"
      >
    </app-product-menu-item>
    <app-product-menu-item 
      [title]="this.textFragments.textFragmentsLanguage(this.ES, this.EN, this.DE)?.sales"
      [id]="this.textFragments.textFragmentsLanguage(this.EN, this.EN, this.EN)?.sales"
      [isFinished]="sales_finished"
      (itemSelected)="onItemSelected($event)" 
      [isSelected]="isSelected('Sales')"
      [deactivated]="notPlan2andHigher"
      >
    </app-product-menu-item>
    <div class="p-3">
      <div class="h-24 flex justify-between w-full py-2">
        <button (click)="openDialogConfirm()" [disabled]="!allRequiredData() || (titleService.status=='finalized' || (titleService.status=='in-review' && !sessionService.isAdmin))"
          class="confirmPreview relative flex items-center justify-center text-vistrace-primary hover:text-white">
          <div class="imageDiv relative w-10 h-10">
            <img src="assets/incomplete_circle_FILL0_wght400_GRAD0_opsz24.svg"
              class="defaultImg absolute inset-0 w-full h-full" alt="Default Finalize">
            <img src="assets/incomplete_circle_FILL0_wght400_GRAD0_opsz24_2.svg"
              class="hoverImg absolute inset-0 w-full h-full opacity-0" alt="Hover Finalize">
          </div>
          <span class="pt-2">{{this.textFragments.textFragmentsLanguage(this.ES, this.EN, this.DE)?.finalize}}</span>
        </button>
        <button (click)="openDialogPreview()"
          class="confirmPreview relative flex items-center justify-center text-vistrace-primary hover:text-white">
          <div class="imageDiv relative w-10 h-10">
            <img src="assets/visibility_FILL0_wght400_GRAD0_opsz24.svg"
              class="defaultImg absolute inset-0 w-full h-full" alt="Default Preview">
            <img src="assets/visibility_FILL0_wght400_GRAD0_opsz24_2.svg"
              class="hoverImg absolute inset-0 w-full h-full opacity-0" alt="Hover Preview">
          </div>
          <span class="pt-2 ">{{this.textFragments.textFragmentsLanguage(this.ES, this.EN, this.DE)?.preview}}</span>
        </button>
      </div>
    </div>
    <div class="p-3">
      <button *ngIf="sessionService.isAdmin && titleService.status=='in-review'" class="rejectButton mb-2" (click)="rejectProduct()">
        <span class="w-full text-lg" (click)="openDenialReason()">{{this.textFragments.textFragmentsLanguage(this.ES, this.EN, this.DE)?.reject}}</span>
        <!--section class="w-1/3"></section-->
      </button>
      <button class="backButton" (click)="toProducts()">
        <div class="w-1/3 flex justify-end">
          <img src="assets/back.svg" class="h-4" alt="Back">
        </div>
        <span class="w-1/3 text-lg">{{this.textFragments.textFragmentsLanguage(this.ES, this.EN, this.DE)?.back}}</span>
        <section class="w-1/3"></section>
      </button>
    </div>
  </div>