import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DashboardTitleService } from 'src/app/shared/services/dashboard-title.service';

@Component({
  selector: 'app-product-menu-item',
  templateUrl: './product-menu-item.component.html',
  styleUrls: ['./product-menu-item.component.scss']
})
export class ProductMenuItemComponent implements OnInit {

  @Input() title: string = "";
  @Input() id: string = "";
  @Input() isFinished: boolean = false;
  @Output() itemSelected = new EventEmitter<string>();
  @Input() isSelected: boolean = false;
  @Input() deactivated: boolean = false;

  constructor(private titleService : DashboardTitleService) { }

  ngOnInit(): void {
  }

  selectMenuItem() {
    if (this.deactivated) {
      return;
    }
    this.isSelected = !this.isSelected;
    this.itemSelected.emit(this.id);
    //this.titleService.setSelectedTitle(this.id);
  }

  

}
