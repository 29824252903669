<div class="flex flex-column p-3 space-y-2 gap-2">
    <p class="font-bold text-3xl">{{this.textFragments.textFragmentsLanguage(this.ES, this.EN, this.DE)?.title}}</p>
    <p>{{this.textFragments.textFragmentsLanguage(this.ES, this.EN, this.DE)?.reason}}</p>
    <textarea (placeholder)="this.textFragments.textFragmentsLanguage(this.ES, this.EN, this.DE)?.input" [(ngModel)]="reason" class="h-40 mt-2 p-2 border-2 border-gray-300 rounded-lg focus:outline-none focus:ring-2 resize-none"></textarea>
    <!-- <div class="flex flex-row">
        <button (click)="sendReviewMail()" class="w-1/2 mr-1 bg-vistrace-primary hover:bg-vistrace-quinary text-white font-bold mt-2 py-2 rounded-xl">{{this.textFragments.textFragmentsLanguage(this.ES, this.EN, this.DE)?.send}}</button>
        <button (click)="close()" class="w-1/2 ml-1 bg-red-600 hover:bg-red-400 text-white font-bold mt-2 py-2 rounded-xl">{{this.textFragments.textFragmentsLanguage(this.ES, this.EN, this.DE)?.cancel}}</button>
    </div> -->
    <mat-dialog-actions class="justify-center">
        <button mat-stroked-button (click)="sendReviewMail()">{{this.textFragments.textFragmentsLanguage(this.ES, this.EN, this.DE)?.send}}</button>
        <button mat-stroked-button (click)="close()">{{this.textFragments.textFragmentsLanguage(this.ES, this.EN, this.DE)?.cancel}}</button>
    </mat-dialog-actions>

</div>