<div class="drop-box" [ngStyle]="{'width.px': width}">
    <div id="drop_zone"  [appDnd]='backend' [disabled]="disabled" (fileUploaded)="onFileUploaded($event)"
        class="drop-content">
            <h6>{{title}}</h6>
            <div class="drop-announce">
              <div style="align-self: center;">
                <mat-icon [ngClass]="disabled ? 'inactivecolor' : 'activecoloricon'">insert_drive_file</mat-icon>
              </div>
              
              <div [ngClass]="disabled ? 'inactivecolor' : 'activecoloricon'">
                <div>{{init_title}}</div>
                <!-- <p>Drop Files</p> -->
              </div>
            </div>

            <mat-chip-listbox>
              <mat-chip-option *ngFor="let file of files" selectable="false" (click)="openFile(file)" (removed)="checkDeleteFile(file)">
                {{file}}
                <mat-icon *ngIf="!disabled" matChipRemove>delete</mat-icon>
              </mat-chip-option>
            </mat-chip-listbox> 
           
            <!-- <div *ngFor="let file of files" class="files d-flex row">
              <app-styled-button
                [colorStyle]="'brand'"
                [title]="file"
                [isFilled]="false"
                (action)="openFile(file)">
              </app-styled-button> -->
                <!-- <button mat-stroked-button color="primary" (click)="openFile(file)">{{file}}</button> -->
                <!-- <app-styled-button
                  [colorStyle]="'brand'"
                  [isFilled]="false"
                  (action)="checkDeleteFile(file)"
                  [disabled]="disabled"
                  [width]="'60'"
                  [iconName]="'delete'">
                </app-styled-button> -->
                <!-- <button mat-stroked-button color="primary" class="delete-files" (click)="checkDeleteFile(file)" [disabled]="disabled">
                  <mat-icon>delete</mat-icon>
                </button> -->
            <!-- </div> -->

    </div>
</div>
