import { AfterViewInit, Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { MessageService } from 'src/app/shared-generic/services/message.service';

@Component({
  selector: 'app-mobile-preview',
  templateUrl: './mobile-preview.component.html',
  styleUrls: ['./mobile-preview.component.scss']
})
export class MobilePreviewComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {

  @Input() mobile_url: string;
  @ViewChild('iframe') iframe: ElementRef<HTMLIFrameElement>;
  public loading: boolean = false
  private subscriptions = new Array<Subscription>();

  constructor(private messageService: MessageService) { }

  ngAfterViewInit(): void {
    if (this.iframe && this.mobile_url) {
      this.iframe.nativeElement.src = this.mobile_url["changingThisBreaksApplicationSecurity"];
    }
  }

  ngOnInit(): void {
    if (this.iframe && this.mobile_url) {
      console.log(this.mobile_url)
      this.iframe.nativeElement.src = this.mobile_url["changingThisBreaksApplicationSecurity"]
    }

    const callbackEndLoadingState = "endLoadingState"
    
    this.subscriptions.push(
      this.messageService
        .awaitMessage('main')
        .pipe(filter((msg) => msg.name === callbackEndLoadingState))
        .subscribe((msg) => {
          console.log("fin del cargado")
          this.loading = false;
        }));
  }

  ngOnChanges() {
    
    if (this.iframe && this.mobile_url) {
      this.loading = true;
      this.iframe.nativeElement.src = this.mobile_url["changingThisBreaksApplicationSecurity"]
      setTimeout(() => {
        this.loading = false;
        console.log("loading", this.loading)
      }, 2000);
    }

    

  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subs => subs.unsubscribe());
  }


}
