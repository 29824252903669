import { Component, OnInit, OnDestroy, Input, Output , EventEmitter} from '@angular/core';
import { FilterSelectionGroup } from './../../types/filter-selection-group';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { FilterService } from '../../services/filter.service';
import { MessageService } from 'src/app/shared-generic/services/message.service';

@Component({
  selector: 'app-filter-selection-group',
  templateUrl: './filter-selection-group.component.html',
  styleUrls: ['./filter-selection-group.component.scss']
})
export class FilterSelectionGroupComponent implements OnInit, OnDestroy {

  @Input() backend = 'offer-creator';
  @Input() controller: string = "";
  @Output() filterTrigger = new EventEmitter<string>();
  public subs: Subscription[] = [];
  public filterSelectionGroups: FilterSelectionGroup[];

  constructor(private filterService: FilterService,
    private messageService: MessageService,) { }

  ngOnInit(): void {
    this.subs.push(this.filterService.initFilterSelectionGroup(this.controller, this.backend).pipe(filter(msg => msg.args.identifier === this.backend + this.controller))
      .subscribe(
        msg => {
          this.filterSelectionGroups = msg.args.filterSelectionGroups;
          let trigger = this.messageService.getRandomInt(10000).toString();
          this.filterTrigger.emit(trigger);
        }));
  }

  ngOnDestroy() {
    this.subs.forEach( subs => subs.unsubscribe() );
  }

  public remove(group_id: string){
    this.filterService.removeFilterSelectionGroupMessage(this.controller, this.backend, group_id);
  }

}
