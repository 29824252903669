import { Component, OnInit, Inject, EventEmitter } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { DialogService } from 'src/app/shared-generic/services/dialog.service';

@Component({
  selector: 'app-dialog-filter',
  templateUrl: './dialog-filter.component.html',
  styleUrls: ['./dialog-filter.component.scss']
})
export class DialogFilterComponent implements OnInit {

  public sortTrigger = new EventEmitter<string>();
  public displaySaveButton = false;
  constructor(
    private dialogService: DialogService,
    private dialogRef: MatDialogRef<DialogFilterComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, // required: title, backend, controller, sort, filterMaxWidth, filtersMaxHeight, optionsMaxHeight
  ) { }

  ngOnInit(): void {
    if (this.data.displaySaveButton){
      this.displaySaveButton = true;
    }
  }

  onCancel(): void {
    this.dialogRef.close();
    this.dialogService.dialogStatus(this.data.name, false);
  }

  onOk() {
    //this.dialogRef.close();
    this.data.save = false;
    this.dialogService.dialogStatus(this.data.name, true);
  }

  onOkSave() {
    //this.dialogRef.close();
    this.data.save = true;
    this.dialogService.dialogStatus(this.data.name, true);
  }

  public setSortTrigger(event){
    this.sortTrigger.emit(event);
  }

}
