import { Component, Inject, Input, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TextFragmentService } from 'src/app/ui-main/services/text-fragment.service';

@Component({
  selector: 'app-modal-confirm-product',
  templateUrl: './modal-confirm-product.component.html',
  styleUrls: ['./modal-confirm-product.component.scss']
})
export class ModalConfirmProductComponent implements OnInit {

  EN = {
    confirm : "Confirm",
    cancel : "Cancel",
    beware: "Beware!",
    info: "This action is irreversible. The information is secured in a blockchain system, such that it cannot be changed afterwards. Please make sure that all information is valid."
  }

  ES = {
    confirm : "Confirmar",
    cancel : "Cancelar",
    beware: "¡Cuidado!",
    info: "Esta acción es irreversible. La información está asegurada en un sistema de blockchain, de tal forma que no puede ser cambiada posteriormente. Por favor asegúrese de que toda la información es válida."
  }

  DE = {
    confirm : "Bestätigen",
    cancel : "Abbrechen",
    beware: "Vorsicht!",
    info: "Diese Aktion ist nicht rückgängig zu machen. Die Informationen sind in einem Blockchain-System gesichert, so dass sie danach nicht mehr geändert werden können. Bitte stellen Sie sicher, dass alle Informationen gültig sind."
  }

  constructor(
    private dialogRef: MatDialogRef<ModalConfirmProductComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public textFragments : TextFragmentService
  ) { }

  ngOnInit(): void {
    this.textFragments.textFragmentsLanguage(this.ES, this.EN, this.DE);
  }

  cancel(): void {
    this.dialogRef.close(false);
  }

  confirm(): void {
    this.dialogRef.close(true);
  }

}
