<div>
    <!-- <canvas #canvasVideo width="400" height="400"></canvas> -->
    <!-- <iframe 
        #vimeoIframe 
        src="https://vimeo.com/180293809" 
        width="400" 
        height="400" 
        frameborder="0" 
        allow="autoplay; fullscreen" 
        allowfullscreen>
    </iframe> -->
    <iframe src="//player.vimeo.com/video/180293809?portrait=0" class="ivid" width="500" height="281" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>
</div>
