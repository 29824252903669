import {
    Directive,
    Output,
    Input,
    EventEmitter,
    HostBinding,
    HostListener
  } from '@angular/core';
  
  @Directive({
    selector: '[appInvalidCharacters]'
  })
  export class InvalidCharactersDirective {
    @Output() pastedTextReplaced = new EventEmitter<string>();
    @Input() invalidCharacters: string = "";
    public defaultInvalidCharacters: string = '\\\\#;';

    constructor() {}
  
    // keydown listener
    @HostListener('keydown', ['$event']) onKeyDown(event) {
      // console.log("---keydown---");
      var regex = new RegExp('[^' + this.defaultInvalidCharacters + this.invalidCharacters + ']');
      if (regex.test(event.key)) {
        return true;
      }
      event.preventDefault();
      return false;
    }
    // paste listener
    @HostListener('paste', ['$event']) onPaste(event) {
      // console.log("---paste---");
      var pastedTxt = event.clipboardData.getData('text');
      var regex = new RegExp('[' + this.defaultInvalidCharacters + this.invalidCharacters + ']', 'gm');
      var replacedTxt = pastedTxt.replace(regex, '');
      event.preventDefault();
      this.pastedTextReplaced.emit(replacedTxt);
    }
  }
