
<div class="p-4 w-[550px]">
  <p class="mb-1 text-center font-semibold text-xl">{{this.textFragments.textFragmentsLanguage(this.ES, this.EN, this.DE)?.header}}</p>
  <div class="content">
    

  <div class="flex justify-center p-4 w-full leading-relaxed">
    <div class="w-1/2 text-right">
      <div class="font-bold text-xl">{{this.textFragments.textFragmentsLanguage(this.ES, this.EN, this.DE)?.selectedPlan}}</div>
      <div class="font-bold text-xl">{{this.textFragments.textFragmentsLanguage(this.ES, this.EN, this.DE)?.startingDate}}</div>
      <div class="font-bold text-xl">{{this.textFragments.textFragmentsLanguage(this.ES, this.EN, this.DE)?.subTotal}}</div>
      <div class="font-bold text-xl">{{this.textFragments.textFragmentsLanguage(this.ES, this.EN, this.DE)?.tax}} ({{data.tax_perc}}%)</div>
      <div class="font-bold text-xl">{{this.textFragments.textFragmentsLanguage(this.ES, this.EN, this.DE)?.grandTotal}}</div>
      
    </div>
    <div class="w-1/2 text-left pl-4">
      <div class="text-gray-500 text-xl">{{data.service_name}} </div>
      <div class="text-gray-500 text-xl">{{data.billing_period_start}} </div>
      <div class="text-gray-500 text-xl ">{{data.price}} €</div>
      <div class="text-gray-500 text-xl">{{data.tax_amount}} €</div>
      <div class="font-bold text-xl ">{{data.total_amount}} €</div>
    </div>
  </div>

  <div mat-dialog-actions class="text-center">
    <button mat-button (click)="onNoClick()">{{this.textFragments.textFragmentsLanguage(this.ES, this.EN, this.DE)?.notNow}}</button>
    <button mat-button color="warn" (click)="onYesClick()" cdkFocusInitial>{{this.textFragments.textFragmentsLanguage(this.ES, this.EN, this.DE)?.payNow}}</button>
  </div>
</div>