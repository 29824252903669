
<!-- the outer scales must be put into the view itself (is not part of the app.component)-->


<div class="flex flex-1 overflow-hidden lg:px-[8%] h-full">
  <aside class="w-64 h-full flex-shrink-0 overflow-y-auto overflow-x-hidden"> 
    <app-product-info-menu></app-product-info-menu>
  </aside>


  <div class="flex flex-col justify-between h-full w-full overflow-y-hidden overflow-x-hidden">
    <app-dashboard [productId]="productId"></app-dashboard>
  </div>

</div>


  



    





  
  



