<div class="flex lg:px-[8%] h-full justify-center pt-16 mb-5 overflow-auto">
    <div class="rounded w-96">
        <div class="flex justify-center mb-4">
            <h2 class="text-2xl font-semibold">Register</h2>
        </div>
        <form (ngSubmit)="onSubmit()">
            <div class="flex flex-col lg:flex-row mb-2">
                <div class="w-full lg:w-1/2 pr-2 sm:mb-4 md:mb-4 lg:mb-0">
                    <label for="name" class="block text-sm font-medium text-gray-700">{{this.textFragments.textFragmentsLanguage(this.ES, this.EN, this.DE)?.firstName}}</label>
                    <input type="text" name="name" [(ngModel)]="name" class="mt-1 p-2 block w-full border rounded-md shadow-sm focus:outline-none focus:border-blue-500">
                </div>
                <div class="w-full lg:w-1/2">
                    <label for="surname" class="block text-sm font-medium text-gray-700">{{this.textFragments.textFragmentsLanguage(this.ES, this.EN, this.DE)?.lastName}}</label>
                    <input type="text" name="surname" [(ngModel)]="surname" class="mt-1 p-2 block w-full border rounded-md shadow-sm focus:outline-none focus:border-blue-500">
                </div>
            </div>
            <div class="mb-2">
                <label for="email" class="block text-sm font-medium text-gray-700">{{this.textFragments.textFragmentsLanguage(this.ES, this.EN, this.DE)?.email}}</label>
                <input type="email" name="email" [(ngModel)]="email" class="mt-1 p-2 block w-full border rounded-md shadow-sm focus:outline-none focus:border-blue-500">
            </div>
            <div class="mb-2 relative">
                <label for="password" class="block text-sm font-medium text-gray-700">{{this.textFragments.textFragmentsLanguage(this.ES, this.EN, this.DE)?.password}}</label>
                <input [type]="passwordVisible ? 'text' : 'password'" name="password" [(ngModel)]="password" class="mt-1 p-2 block w-full border rounded-md shadow-sm focus:outline-none focus:border-blue-500">
                <img (click)="togglePasswordVisibility()" class="visibility-icon" [src]="passwordVisible ? 'assets/visibility-off.svg' : 'assets/visbility.svg'">
            </div>
            <div class="mb-4 relative">
                <label for="confirmPassword" class="block text-sm font-medium text-gray-700">{{this.textFragments.textFragmentsLanguage(this.ES, this.EN, this.DE)?.confirmPassword}}</label>
                <input [type]="passwordVisible ? 'text' : 'password'" name="confirmPassword" [(ngModel)]="confirmPassword" class="mt-1 p-2 block w-full border rounded-md shadow-sm focus:outline-none focus:border-blue-500">
            </div>
            <div class="flex flex-row mb-2">
                <!--mat-checkbox [(ngModel)]="agreeTerms"></mat-checkbox>
                <label for="agreeTerms" class="ml-2 block text-sm text-gray-900">I agree to the terms of service</label-->
                <label class="flex items-center pl-2">
                  <mat-checkbox [(ngModel)]="agreeTerms" name="agreeTerms"></mat-checkbox>
                  <span class="text-gray-600">{{this.textFragments.textFragmentsLanguage(this.ES, this.EN, this.DE)?.agreeTerms}}
                    <a href="/static/legals/vistrace-terms-of-service.pdf" download="vistrace-terms-of-service.pdf" (click)="openAndDownload($event, '/static/legals/vistrace-terms-of-service.pdf')" class="text-blue-500 underline cursor-pointer hover:text-blue-300">{{this.textFragments.textFragmentsLanguage(this.ES, this.EN, this.DE)?.termsOfService}}</a> 
                    {{this.textFragments.textFragmentsLanguage(this.ES, this.EN, this.DE)?.and}} <a href="/static/legals/vistrace-privacy-policy.pdf" download="vistrace-privacy-policy.pdf" (click)="openAndDownload($event, '/static/legals/vistrace-privacy-policy.pdf')" class="text-blue-500 underline cursor-pointer hover:text-blue-300">{{this.textFragments.textFragmentsLanguage(this.ES, this.EN, this.DE)?.privacyPolicy}}</a>
                  </span>
                </label>
            </div>
            
            <div class="flex flex-row mb-2">
                <!--input type="checkbox" name="notARobot" [(ngModel)]="notARobot" class="h-4 w-4 checked:bg-vistrace-quinary  border-gray-300 rounded">
                <label for="notARobot" class="ml-2 block text-sm text-gray-900">I am not a robot</label-->
                <label class="flex items-center pl-2">
                  <mat-checkbox [(ngModel)]="agreeInfo" name="agreeInfo"></mat-checkbox>
                  <span class="text-gray-600">{{this.textFragments.textFragmentsLanguage(this.ES, this.EN, this.DE)?.agreeNews}}</span>
                </label>
            </div>
            <button class="w-full bg-vistrace-primary text-white py-2 px-4 rounded-md hover:bg-vistrace-quinary focus:bg-vistrace-quinary">{{this.textFragments.textFragmentsLanguage(this.ES, this.EN, this.DE)?.continue}}</button>
        </form>
        <div class="mt-4 pb-4 text-center">
            <a (click)="toLogin()" class="text-vistrace-quinary hover:text-vistrace-primary hover:underline hover:cursor-pointer">{{this.textFragments.textFragmentsLanguage(this.ES, this.EN, this.DE)?.login}}</a><br>
        </div>
    </div>
</div>