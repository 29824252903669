<div class="p-2">
  <p class="mb-1 text-center font-semibold text-xl">{{this.textFragments.textFragmentsLanguage(this.ES, this.EN, this.DE)?.header}}</p>
  <div mat-dialog-content>
    <p>{{this.textFragments.textFragmentsLanguage(this.ES, this.EN, this.DE)?.confirmationMessage}} {{data.productName}}</p>
  </div>
<!-- 
  <div mat-dialog-actions class="text-center">
    <button mat-button (click)="onNoClick()">No</button>
    <button mat-button color="warn" (click)="onYesClick()" cdkFocusInitial>{{this.textFragments.textFragmentsLanguage(this.ES, this.EN, this.DE)?.delete}}</button>
  </div> -->
  <mat-dialog-actions class="justify-center">
    <button mat-stroked-button color="warn" (click)="onYesClick()">{{this.textFragments.textFragmentsLanguage(this.ES, this.EN, this.DE)?.delete}}</button>
    <button mat-stroked-button (click)="onNoClick()">No</button>
  </mat-dialog-actions>
</div>
