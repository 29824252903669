import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { DialogService } from 'src/app/shared-generic/services/dialog.service';
import { MessageService } from 'src/app/shared-generic/services/message.service';
import { filter } from 'rxjs/operators';
import { Subscription } from 'rxjs/internal/Subscription';
import { Dictionary } from 'src/app/shared-generic/types/dictionary';
import { ConditionalFormOptions } from './../../types/conditional-form-options'
import { FormDataService } from 'src/app/shared-generic/services/form-data.service';

@Component({
  selector: 'app-conditional-form-dialog',
  templateUrl: './conditional-form-dialog.component.html',
  styleUrls: ['./conditional-form-dialog.component.scss']
})
export class ConditionalFormDialogComponent implements OnInit, OnDestroy {
  
  private subscriptions: Array<Subscription> = [];
  public callbackId = "setConditionalDialogSecondForm";
  public updateDataCheckInfoCallbackId = 'dataCheckInfo';
  public entityId: string;
  public options: Dictionary<ConditionalFormOptions>[] = [];
  public okEnabled: boolean = false;
  public currrentSelection:string;
  public dataCheckInfo:string = "";
  
  constructor(
    private dialogService: DialogService,
    private messageService: MessageService,
    private formDataService: FormDataService,
    private dialogRef: MatDialogRef<ConditionalFormDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, // required: title, name, backend, first_form, editMode
  ) { }

  ngOnInit(): void {
    const callbackId = 'currentKey';
    this.subscriptions.push(
      this.messageService.awaitMessage(this.data.backend).pipe(filter(message => message.name === callbackId))
          .subscribe(message => {
            if (this.entityId !== message.args.current_key){
              const msgB = {
                name: 'getAvailableOptions',
                args: [message.args.current_key, this.callbackId]
              };
              this.messageService.sendMsg(msgB, this.data.backend);
            }
              this.entityId = message.args.current_key;
          }
      )); 
    const msg = {
        name: 'getCurrentFormKey',
        args: [this.data.firstForm, callbackId]
    };
    this.messageService.sendMsg(msg, this.data.backend);

    this.subscriptions.push(
      this.messageService.awaitMessage(this.data.backend).pipe(filter(message => message.name === this.callbackId))
          .subscribe(message => {
              this.options = message.args.options;
              this.currrentSelection = message.args.selected;
              this.subscriptions.push(
                this.formDataService.formValidSubject.pipe(filter(item => item.form === this.currrentSelection))
                  .subscribe(item => {
                    if(item.form === this.currrentSelection){
                      this.okEnabled = item.status;
                    }
                })
              );
          }
      ));

      this.subscriptions.push(
        this.messageService.awaitMessage(this.data.backend).pipe(filter(message => message.name === this.updateDataCheckInfoCallbackId))
            .subscribe(message => {
                this.dataCheckInfo = message.args.dataCheckInfo;
            }
        ));

  }

  ngOnDestroy() {
    this.subscriptions.forEach(subs => subs.unsubscribe());
}

  onCancel(): void {
    this.dialogRef.close();
    this.dialogService.dialogStatusEntity(this.data.name, false, this.entityId);
  }

  onOk() {
    this.dialogRef.close();
    this.dialogService.dialogStatusEntity(this.data.name, true, this.entityId);
  }

}
