
    <div class="filter-wrapper">
      <mat-checkbox *ngFor="let filter of filterGroup"
                    color="primary"
                    (change)="statusChanged(filter)"
                    [(ngModel)]="filter.isActive"
                    matTooltip="{{filter.tooltip}}">{{filter.displayName}}</mat-checkbox>
    </div>

  
