import { Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs/internal/Subscription';
import { MessageService } from 'src/app/shared-generic/services/message.service';
import { filter } from 'rxjs/operators';
import { TableDataService } from 'src/app/shared-generic/services/table-data.service';
import { Router } from '@angular/router';
import { FormDialogComponent } from 'src/app/shared-generic/components/form-dialog/form-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { LayoutService } from 'src/app/shared-generic/services/layout.service';
import { ViewService } from 'src/app/shared/services/view.service';
import { DialogLoginService } from 'src/app/shared-generic/services/dialog-login.service';
import { KeycloakService } from 'keycloak-angular';
import { DialogComponent } from 'src/app/shared-generic/components/dialog/dialog.component';

@Component({
  selector: 'app-user-management-view',
  templateUrl: './user-management-view.component.html',
  styleUrls: ['./user-management-view.component.scss']
})
export class UserManagementViewComponent implements OnInit, OnDestroy {
  @HostBinding('class.app-bottom') dataView = true;
  public userKey = '';
  public customerKey = '';
  public voucherKey = '';
  public productKey = '';
  public newUserKey = "";
  public newVoucherKey = "";
  private subscriptions: Array<Subscription> = [];
  public backend = "main";
  public user_table = "user-management-table";
  public voucher_table = "voucher-management-table";
  public invoice_table = "invoice-management-table";
  public customer_table = "customer-management-table";
  public product_table = "product-management-table";
  public rowSelectedUser = false;
  public rowSelectedCustomer = false;
  public rowSelectedVoucher = false;
  public rowSelectedProduct = false;
  public selectedRowUser: any;
  public selectedRowCustomer: any;
  public selectedRowVoucher: any;
  public selectedRowProduct: any;
  public paginator =7;
  public wideScreen_paginator = 10;
  public updateTriggerUserForm = '';
  public updateTriggerCustomerForm = '';
  public updateTriggerVoucherForm = '';
  public updateTriggerProductForm = '';
  public formNameUser = 'user-management-form';
  public formNameCustomer = 'customer-management-form';
  public formNameVoucher = 'voucher-management-form';
  public formNameProduct = 'product-management-form';
  public newUserCallbackId = "setNewUser";
  public newVoucherCallbackId = "setNewVoucher";
  public init = 100;
  public dialog_name ="add-user";
  public reset_passwort_form = 'reset-passwort-form';
  public reset_passwort_dialog = 'reset-passwort-dialog';

  constructor(
    private messageService: MessageService,
    private tableDataService: TableDataService,
    private dialog: MatDialog,
    private router: Router,
    public layoutService: LayoutService,
    public viewService: ViewService,
    public dialogLoginService: DialogLoginService,
    public keycloak: KeycloakService
  ) { }

  ngOnInit(): void {
    this.subscriptions.push(
      this.tableDataService.singleSelectionSubject.pipe(filter(msg => msg.table === this.user_table))
      .subscribe(msg => {
        this.rowSelectedUser = msg.status;
        if (this.rowSelectedUser) {
          // console.log("selectedRow: ", msg.row);
          this.userKey = msg.row["key"];
          this.selectedRowUser = msg.row;
          this.init = this.messageService.getRandomInt(10000);
        }
      })
    ); 

    this.subscriptions.push(
      this.tableDataService.singleSelectionSubject.pipe(filter(msg => msg.table === this.customer_table))
      .subscribe(msg => {
        console.log("SELECT ROW CUSTOMER", msg);
        this.rowSelectedCustomer = msg.status;
        if (this.rowSelectedCustomer) {
          this.customerKey = msg.row["key"];
          this.selectedRowCustomer = msg.row;
          this.init = this.messageService.getRandomInt(10000);
        }
      })
    ); 

    this.subscriptions.push(
      this.tableDataService.singleSelectionSubject.pipe(filter(msg => msg.table === this.voucher_table))
      .subscribe(msg => {
        console.log("SELECT ROW VOUCHER", msg);
        this.rowSelectedVoucher = msg.status;
        if (this.rowSelectedVoucher) {
          this.voucherKey = msg.row["key"];
          this.selectedRowVoucher = msg.row;
          this.updateTriggerVoucherForm = this.messageService.getRandomInt(10000).toString();
        }
      })
    ); 

    this.subscriptions.push(
      this.tableDataService.singleSelectionSubject.pipe(filter(msg => msg.table === this.product_table))
      .subscribe(msg => {
        console.log("SELECT ROW PRODUCT", msg);
        this.rowSelectedProduct = msg.status;
        if (this.rowSelectedProduct) {
          this.productKey = msg.row["product_key"];  // import to sort out the correct name of the key!!!!
          this.selectedRowProduct = msg.row;
          this.updateTriggerProductForm = this.messageService.getRandomInt(10000).toString();
          //this.init = this.messageService.getRandomInt(10000);
        }
      })
    ); 

    this.subscriptions.push(
      this.messageService.awaitMessage(this.backend).pipe(filter(msg => msg.name === this.newUserCallbackId))
      .subscribe(msg => {
        this.newUserKey = msg.args.new_key;
      })
    );

    this.subscriptions.push(
      this.messageService.awaitMessage(this.backend).pipe(filter(msg => msg.name === this.newVoucherCallbackId))
      .subscribe(msg => {
        this.newVoucherKey = msg.args.new_key;
      })
    );

  }

  ngOnDestroy(): void {
    for (const subs of this.subscriptions) {
      subs.unsubscribe();
    }
  }

  public deleteCustomer() {

  }

  public deleteUser() {
    const dialogRef = this.dialog.open(DialogComponent, {
      disableClose: true,
      width: '450px',
      data: {
        title: "Nutzer entfernen",
        message: "Möchten Sie den Nutzer " + this.selectedRowUser["username"] + 
                 " wirklich entfernen?",
        name: "notNeeded"}
     });
     dialogRef.afterClosed().subscribe( data => {
         if (data) {
          const msg = {
              name: 'deleteLine',
              args: [this.user_table, this.selectedRowUser, 'tabledata/main/user-management-table'],
          }
          this.messageService.sendMsg(msg, this.backend);
          this.rowSelectedUser = false;
          this.selectedRowUser = undefined;
          this.userKey = "";
         }
       }
     );
  }

  public deleteVoucher() {
    const dialogRef = this.dialog.open(DialogComponent, {
      disableClose: true,
      width: '450px',
      data: {
        title: "Delete Voucher",
        message: "You want to delete this Voucher " + this.selectedRowVoucher["voucher_name"] + 
                 "?",
        name: "notNeeded"}
     });
     dialogRef.afterClosed().subscribe( data => {
         if (data) {
          const msg = {
              name: 'deleteLine',
              args: [this.voucher_table, this.selectedRowVoucher, 'tabledata/main/voucher-management-table'],
          }
          this.messageService.sendMsg(msg, this.backend);
          this.rowSelectedVoucher = false;
          this.selectedRowVoucher = undefined;
          this.voucherKey = "";
         }
       }
     );
  }

  public sendVoucherMail() {
    const msg = {
      name: 'sendVoucherMail',
      args: [this.voucherKey]
    };
    this.messageService.sendMsg(msg, this.backend);
  }

  public backHome() {
    this.router.navigateByUrl("/main/home");
  }

  public download_all() {

  }

  public addVoucher(): void {
    const msg = {
        name: 'initLine',
        args: [this.voucher_table]
    };
    this.messageService.sendMsg(msg, this.backend);
  }

  public addUser(){
    const id = (Math.floor(Math.random() * Math.floor(10000))).toString();
    const dialogRef = this.dialog.open(FormDialogComponent, {
      disableClose: true,
      width: '800px',
      data: {
        title: "Benutzer hinzufügen",
        form_dialog_name: this.user_table + `_${id}`,
        backend: this.backend,
        form: this.formNameUser + "-init",
        create_new: true,
      }  
    });
    console.log("newUserCallbackId", this.newUserCallbackId);
    console.log("user_table", this.user_table);
    dialogRef.afterClosed().subscribe(result => {
      if(result){
        const msg = {
          name: 'select_new_row',
          args: [this.newUserCallbackId, this.user_table]
        }
        this.messageService.sendMsg(msg, this.backend);
      } else {
        this.newUserKey = "";
      }
    });
  }

}
