import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, tap, catchError } from 'rxjs/operators';
import { MessageService } from './message.service';
import { UiInfoService } from './ui-info-service';

const localHeadersConfig = new HttpHeaders(
    {
        //'Content-Type': 'multipart/form-data; boundary=something'
        'enctype':"multipart/form-data"
    }
)

const URL_save = '/upload_cad';
const URL_save_all = '/upload_save_all';
const URL_save_in_sales_order = '/upload_cad_in_sales_order';


@Injectable({
    providedIn: 'root',
  })
  export class FileUploadService {
    public title = '';
    private url = URL;
    private url_save = URL_save;
    private url_save_all = URL_save_all;
    private url_save_in_sales_order = URL_save_in_sales_order;
    constructor(
        private httpClient: HttpClient,
        private uiInfoService: UiInfoService,
        private msgService: MessageService) {}


    public saveFileCAD(fileToUpload: File, api: string): Observable<any> {
        //console.log("save file");
        const endpoint = `/${api}${this.url_save}`;
        // FormData seems to be usefull for aiohttp on the server side ...
        const formData: FormData = new FormData();
        formData.append('user', this.msgService.user);
        // formData.append('doShow', 'true');
        formData.append('fileKey', fileToUpload, fileToUpload.name);
        return this.httpClient
        .post(endpoint, formData, { headers: localHeadersConfig })
        .pipe(
            map(data => {return data;} ),
            catchError(this.handleError('post', endpoint))
        );
    }

    public saveFileAll(fileToUpload: File, backend: string, api: string): Observable<any> {
        //console.log("save file all");
        const endpoint = `/${api}${this.url_save_all}`;
        // FormData seems to be usefull for aiohttp on the server side ...
        const formData: FormData = new FormData();
        formData.append('user', this.msgService.user);
        formData.append('backend', backend);
        // formData.append('doShow', 'true');
        formData.append('fileKey', fileToUpload, fileToUpload.name);
        return this.httpClient
        .post(endpoint, formData, { headers: localHeadersConfig })
        .pipe(
            map(data => {return data;} ),
            catchError(this.handleError('post', endpoint))
        );
    }

    public saveFileCADInSalesOrder(fileToUpload: File, api: string, sub_sales_order_number: string): Observable<any> {
        //console.log("save file");
        const endpoint = `/${api}${this.url_save_in_sales_order}`;
        // FormData seems to be usefull for aiohttp on the server side ...
        const formData: FormData = new FormData();
        formData.append('user', this.msgService.user);
        // formData.append('doShow', 'true');
        formData.append('sub_sales_order_number', sub_sales_order_number);
        formData.append('fileKey', fileToUpload, fileToUpload.name);
        return this.httpClient
        .post(endpoint, formData, { headers: localHeadersConfig })
        .pipe(
            map(data => {return data;} ),
            catchError(this.handleError('post', endpoint))
        );
    }

    private handleError(operation: String, url: string) {
        return (err: any) => {
            let errMsg = `error in ${operation}() retrieving ${url}`;
            console.log(`${errMsg}:`, err)
            this.uiInfoService.openSnackbar("Error in Upload", 4000);
            if(err instanceof HttpErrorResponse) {
                // you could extract more info about the error if you want, e.g.:
                console.log(`status: ${err.status}, ${err.statusText}`);
                // errMsg = ...
            }
            // or throwError
            return throwError(errMsg);
        }
    }

}