<section 
  class="flex flex-col items-center justify-center w-full"
  >
  <mat-icon (click)="toInput()" class="m-4 scale-[2] cursor-pointer" [ngClass]="{'disable': leftover_product_number<=0}">{{this.textFragments.textFragmentsLanguage(this.ES, this.EN, this.DE)?.add}}</mat-icon>
  <!--img
    src="assets/plusIcon.svg"
    class="w-10 h-12"
    alt="add product"
  /-->
  <label class="text-xl text-vistrace-primary">{{this.textFragments.textFragmentsLanguage(this.ES, this.EN, this.DE)?.product}}</label>
  <label class="text-vistrace-primary">({{this.textFragments.textFragmentsLanguage(this.ES, this.EN, this.DE)?.left}}: {{leftover_product_number}})</label>
  <button *ngIf="leftover_product_number<=0" matTooltip="Purchase new product" mat-mini-fab class="w-8 h-8 m-2 cursor-pointer" (click)="bookProduct()">
    <mat-icon class="text-white">shopping_cart</mat-icon>
  </button>
</section>
