import { Component, Inject, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Stripe } from '@stripe/stripe-js';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { StripeService } from 'src/app/ui-main/services/stripe-service';
import { TextFragmentService } from 'src/app/ui-main/services/text-fragment.service';

const style = {
  base: {
      color: '#32325d',
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: 'antialiased',
      fontSize: '16px',
      '::placeholder': {
          color: '#aab7c4',
      },
  },
  invalid: {
      color: '#fa755a',
      iconColor: '#fa755a',
  },
};

@Component({
  selector: 'app-modal-credit-card',
  templateUrl: './modal-credit-card.component.html',
  styleUrls: ['./modal-credit-card.component.scss']
})
export class ModalCreditCardComponent implements OnInit {

  constructor(
    private dialogRef: MatDialogRef<ModalCreditCardComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private stripeService: StripeService,
    private spinnerService: NgxSpinnerService,
    public textFragments : TextFragmentService
  ) { }

  close(): void {
    console.log('close');
    this.dialogRef.close(false);
    this.spinnerService.hide();
    this.loading = false;
  }

  cardErrors: any;
  cardElement: any;
  cardholderName = "";
  loading = false;
  private stripe: Stripe | null = null;

  EN = {
    enter : 'Enter Your Payment Details',
    submit : 'Submit',
    cardholderName : 'Cardholder Name',
    cancel : 'Cancel',
  }

  ES = {
    enter : 'Introduce tus datos de pago',
    submit : 'Enviar',
    cardholderName : 'Titular de la tarjeta',
    cancel : 'Cancelar',
  }

  DE = {
    enter : 'Geben Sie Ihre Zahlungsdetails ein',
    submit : 'Einreichen',
    cardholderName : 'Karteninhaber',
    cancel : 'Stornieren',
  }
  
  async ngOnInit() {
    this.textFragments.textFragmentsLanguage(this.ES, this.EN, this.DE);
    this.stripe = this.stripeService.getStripe();
    if (this.stripe) {
      const elements = this.stripe.elements({
        locale: 'en' // Specify English locale
      });
      this.cardElement = elements.create('card', {style, hidePostalCode: true, });
      this.cardElement.mount('#card-element');
      this.cardElement.on('change', (event: any) => {
        this.cardErrors = event.error ? event.error.message : '';
      });
    }
  } 

  async onSubmit(form: NgForm) {
    if (form.invalid) {
      return;
    }
    this.spinnerService.show();
    const { cardholderName } = form.value;
    const { paymentMethod, error } = await this.stripe.createPaymentMethod({
      type: 'card',
      card: this.cardElement,
      billing_details: {
        name: cardholderName,
      },
    });
    if (error) {
      this.stripeService.handlePaymentMethodErrors(error.message);
      this.dialogRef.close(false);
    } else {
      this.stripeService.handlePaymentMethod(paymentMethod);
      this.dialogRef.close(true);
    }
    
  }

}
