import { Component, OnInit, OnChanges, ViewChild, Input, Output,EventEmitter, AfterViewInit, SimpleChanges, OnDestroy, HostListener, ChangeDetectionStrategy } from '@angular/core';
import { Subscription } from 'rxjs';   
import { TableDataService } from '../../services/table-data.service';
import {SelectionModel} from '@angular/cdk/collections';
import { MatDialog } from '@angular/material/dialog';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { filter } from 'rxjs/operators';
import { UntypedFormControl } from '@angular/forms';
import { MessageService } from './../../services/message.service';
import { FormDataService } from './../../services/form-data.service';
import { EditorDialogComponent } from '../editor-dialog/editor-dialog.component';
// import { EventEmitter } from 'stream';
import { DomSanitizer} from '@angular/platform-browser';
import { FileDownloadService } from '../../services/file-download.service';
import { LayoutService } from '../../services/layout.service';
import { Dictionary } from '../../types/dictionary';
import {CdkDragDrop, CdkDropList, CdkDrag, moveItemInArray} from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-database-table',
  templateUrl: './database-table.component.html',
  styleUrls: ['./database-table.component.scss'],
  //changeDetection: ChangeDetectionStrategy.OnPush
})
export class DatabaseTableComponent implements OnInit, OnDestroy, OnChanges {
  // @ViewChild('table', {static: false}) table: MatTable<any>;
  @ViewChild(MatTable) table: MatTable<any>;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  @Input() xname: string;
  @Input() row_key: string = 'key';   // in case another key is given ...
  @Input() singleSelection: boolean = false;
  @Input() onEditor: boolean = true;
  @Input() onFilter: boolean = false;
  @Input() onFilterColumns: boolean = false;
  @Input() onButton: boolean = false;
  @Input() onTopButton: boolean = false;
  // @Input() selected = undefined;
  @Input() enabled: boolean  = true;
  @Input() entity: string  = '';
  @Input() updateTrigger = '';
  @Input() updateFilter = '';
  @Input() rebuildTrigger = '';
  @Input() editorDisabled: boolean = true;
  @Input() page_size: number;
  @Input() backend = 'offer-creator';
  @Input() selectByKeyonInit = undefined;
  @Input() selectByKeyonUpdateFilterInit = undefined;
  @Input() openEditorEvent = "";
  @Input() selectionDisabled = false;
  @Input() title = "";
  @Input() deselectAll: string;
  @Input() updateRow: any = undefined;
  @Input() disableSort = true;
  @Output() selectedRowEvent = new EventEmitter<any>();
  @Output() selectedRowKeyEvent = new EventEmitter<string>();
  @Output() buttonClickedEvent = new EventEmitter<any>();
  @Input() outsideFilter: string = '';
  @Input() editorWarningMsg = "";
  // pass here the key of the row which should be selected
  @Input() updateTriggerSelectedRow = "";
  @Input() tableHeight = "";
  @Input() selectFirstOnInit = false;
  @Input() short_version = false;
  @Input() dargAndDrop = false;
  // @Input() selectInitRow: boolean = false;
  private callBackToken = "callBackToken"
  public selection = new SelectionModel<{}>(true, []);
  public tableDataList: MatTableDataSource<{}>;
  public displayedColumnsOrig = [];
  public rows = 0;
  public subs: Subscription[] = [];
  public filtersOn = false;
  filters = {};
  filtervalues = {};
  tableInputFormat = {};
  public initFields = {};
  public filterColumns = [];
  public initializedSearchColumns = [];
  // private isSelected = false;
  // public selectedRow: any;
  // public selectedKey = ""; // can now be found in this.selection.selected[0]
  public main_img = '';  
  public valueCallbackId = '';
  private defaultFilterPredicate: any;
  public displayedColumns: any[] = [];
  public filterHeaders: string[] = [];
  public filterValue = "";
  public short_version_length = 0;
  dragDisabled = true;
  constructor(public tableDataService: TableDataService,
              private messageService: MessageService,
              private dialog: MatDialog,
              public sanitizer: DomSanitizer,
              public fileDownloadService: FileDownloadService,
              private formDataService: FormDataService,
              public layoutService: LayoutService,) { }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    // console.log(numSelected);
    return numSelected === this.rows;
  }

  // tslint:disable-next-line: use-life-cycle-interface
  ngOnInit() {
    this.initialize();
  }

  getSanitizedUrl(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  isTooltipEnabled(cellText: string, width: number): boolean {
    const canvas = <HTMLCanvasElement>document.getElementById("canvas");
    if (canvas){
      const ctx = canvas.getContext("2d");
      let text = ctx.measureText(cellText);
      // console.log("IS ENABLE", text.width, width)
      return text.width*1.6 > width;
    }
    return false
    // return this.layoutService.isEllipsisActive(cellText);
  }

  initialize() {
    if (!this.tableDataService.tableSearchInfo.containsKey(this.xname)) {
      let searchInfo = new Dictionary<string>;
      searchInfo.setItem("searchValue", "");
      searchInfo.setItem("searchColumn", "");
      this.tableDataService.tableSearchInfo.setItem(this.xname, searchInfo);
    }
    this.subs.push(this.tableDataService.getTable(this.xname, this.entity, this.backend).pipe(filter(msg => msg.args.tablename === this.xname + this.entity))
      .subscribe(
        msg => {
          // console.log("received data", msg)
          if (!msg.args.data) {
            return;
          }
          if (msg.args.img) {
            this.main_img = 'data:image/png;base64,' + msg.args.img;
          }
          // console.log("received new table data", msg.args.data);
          msg.args.data.forEach(row => {
            if("img_str" in row){
              row["img_str"] = 'data:image/png;base64,' + row["img_str"];
            }
          });
          this.tableDataList = new MatTableDataSource(msg.args.data); // sufficient to start new rendering !!!
          // we may add filter columns in the backend (TBD, otherwise all are taken ..
          this.filterColumns = msg.args.filterColumns;
          this.rows = msg.args.data.length;
          this.defaultFilterPredicate = this.tableDataList.filterPredicate;
          this.short_version_length = msg.args.short_version_length;
          // 210920: changed this condition, was: msg.args.data.length > 0
          // Before the header was not displayed if there was no data
          if (msg.args.table_header.length > 0) {
            // this.displayedColumns = Object.keys(msg.args.data[0]);
            // const idx = this.displayedColumns.indexOf('id');
            this.displayedColumns = msg.args.table_header; //.map(x => x['title']);
            this.displayedColumnsOrig = msg.args.table_header; 
            // console.log("cols", msg.args.table_header);
            this.filters = {};
            this.filtervalues = {};
            this.filterHeaders = [];
            for (const [index, col] of this.displayedColumns.entries()) {
              const newFilter = new UntypedFormControl('');
              newFilter.valueChanges.subscribe(
                value => {
                  this.filtervalues[col['id']] = value;
                  this.tableDataList.filter = JSON.stringify(this.filtervalues);
                }
              )
              this.filterHeaders.push(col['id'] + '_filter');
              this.filters[col['id'] + '_filter'] = newFilter;
              this.filtervalues[col['id']] = '';
              if (col['ellipsis'] && col['width']) {    
                if (index == 0 || index == this.displayedColumns.length - 1) {
                  col['width-effective'] = col['width'] - 40;
                } else {
                  col['width-effective'] = col['width'] - 10;
                }
              }
            }
            // console.log("more: ", this.filters, this.filtervalues);
          }
          this.tableDataList.sort = this.sort;
          //NOTE: check if necessary
          //this.tableDataList.filterPredicate = this.createFilter();
          this.tableDataList.paginator = this.paginator;
          // why next line @AGA? leads to bug
          // this.tableDataService.setTableStatus(this.xname, this.entity, false, {});
          // if init select
          if(this.selectByKeyonInit){
            let selectedByKeyRow = this.tableDataList.data.filter(row => row[this.row_key] === this.selectByKeyonInit)
            if (selectedByKeyRow.length > 0) {
              this.onRowClicked(selectedByKeyRow[0]);
            }
            this.selectByKeyonInit = undefined;
          }
          if(this.selectFirstOnInit && this.tableDataList.data.length > 0){
            this.onRowClicked(this.tableDataList.data[0])
          }
          if(msg.args.select_row) {
            // console.log("1. init...", msg.args.select_row)
            this.onRowClicked(this.tableDataList.data.filter(row=> row[this.row_key]===msg.args.select_row[this.row_key])[0]);
          }
          this.switchPageBasedOnSelection();
          // TEST narrow down the filter range:
          // console.log("DISPLAYED COLS", this.displayedColumns);
          if (this.filterColumns === undefined) {
            this.initializedSearchColumns = this.displayedColumns.map( x => x);
          } else {
            this.initializedSearchColumns = this.displayedColumns.filter( x => this.filterColumns.indexOf(x["id"]) != -1);
          }
          this.initializedSearchColumns.unshift({id: "all", title: "Alle"});
          this.narrowFilterBasedOnColum(this.tableDataService.tableSearchInfo.item(this.xname).item("searchColumn"));
        }
      ));


    this.subs.push(this.tableDataService.getTableInputFormat(this.xname, this.backend).pipe(filter(msg => msg.args.tablename === this.xname))
      .subscribe(
        msg => {
          if (!msg.args.inputFormat) {
            return;
          }
          this.tableInputFormat = msg.args.inputFormat;
        }
    ));

    // for download mechanics
    this.subs.push(
      this.messageService.awaitMessage(this.backend).pipe(filter(message => message.name === this.callBackToken))
        .subscribe(message => {
          this.fileDownloadService.downloadFile(message.args.url+`?token=${message.args.token}`, false);
        }
    ));

    // if corresponding form exists update table data according to the form data
    this.subs.push(this.formDataService.fieldValueSubject.pipe(filter(msg => msg.form === this.xname))
    .subscribe(msg => {
      // I decided to comment it as selectedRow is never applied @OLI: please review
      // form and database table need to have the same name?
      console.log("do we ever enter here????")
      // if (this.selectedRow) {
      //   this.selectedRow[msg.field] = msg.value;
      // }
    }
    ));

    this.subs.push(this.tableDataService.selectRowSubject.pipe(filter(msg => msg.table === this.xname))
    .subscribe(msg => {
      const selectedRow = this.tableDataList.data.filter(row => row[this.row_key] === msg.row[this.row_key])
      this.onRowClicked(selectedRow[0]);
    }))

    const callbackId = 'tabledata/' + this.backend + "/" + this.xname + this.entity + "/resetStatus";
    this.subs.push(this.messageService.awaitMessage(this.backend).pipe(filter(msg => msg.name === callbackId))
    .subscribe(msg => {
      this.tableDataService.setTableStatus(this.xname, this.entity, false, {});
    }))

    this.valueCallbackId = this.messageService.getRandomInt(1000000).toString();

    this.subs.push(
        this.messageService.awaitMessage(this.backend).pipe(filter(msg => msg.name === this.valueCallbackId))
        .subscribe(msg => {
            // console.log("received update table data", msg);
            msg.args.data.forEach(row => {
              if("img_str" in row){
                row["img_str"] = 'data:image/png;base64,' + row["img_str"];
              }
            });
            this.tableDataList = new MatTableDataSource(msg.args.data);
            this.rows = msg.args.data.length;
            this.tableDataList.paginator = this.paginator;
            this.tableDataList.sort = this.sort;
            if (msg.args.table_header) {
              this.displayedColumns = msg.args.table_header;
            }
            this.onDeselectAll();
            if(msg.args.select_row) {
              console.log("2. update...", msg.args.select_row)
              this.onRowClicked(this.tableDataList.data.filter(row=> row[this.row_key]===msg.args.select_row[this.row_key])[0]);
            }
            // WHY next line??
            this.defaultFilterPredicate = this.tableDataList.filterPredicate;
            // this.tableDataList.filterPredicate = this.createFilter();
            // this.paginator._changePageSize(this.paginator.pageSize);

            this.narrowFilterBasedOnColum(this.tableDataService.tableSearchInfo.item(this.xname).item("searchColumn"));

            for (const [index, col] of this.displayedColumns.entries()) {
              if (col['ellipsis'] && col['width']) {
                if (index == 0 || index == this.displayedColumns.length - 1) {
                  col['width-effective'] = col['width'] - 40;
                } else {
                  col['width-effective'] = col['width'] - 10;
                }
              }
            }
            }
        ));

    
    this.subs.push(
      this.messageService.awaitMessage(this.backend).pipe(filter(msg => msg.name === "initOnEditor"))
      .subscribe(msg => {
          this.initFields = msg.args.initFields;
      }));

    const callbackName = 'tabledata/' + this.backend + "/" + this.xname + this.entity + "/updateValue";
    this.subs.push(
      this.messageService.awaitMessage(this.backend).pipe(filter(msg => msg.name === callbackName))
      .subscribe(msg => {
        this.tableDataList.data.forEach(row=> {if (row[this.row_key]===msg.args.update_row_key){
          row[msg.args.update_column] = msg.args.update_value;
        }})
      }));
  }
  
  ngOnChanges(changes: SimpleChanges) {
    for (let propName in changes) {
      if((propName === "updateTrigger" || propName === "updateFilter")  && this.valueCallbackId) {
        // console.log("changes in table detected", this.entity, this.valueCallbackId );
        // const callbackId = this.xname + this.entity;
        let args = [this.xname, this.entity, this.valueCallbackId]
        if (this.selectByKeyonUpdateFilterInit){
          args.push(this.selectByKeyonUpdateFilterInit);
          this.selectByKeyonUpdateFilterInit = undefined;
        }
        const msg = {
            name: 'getTableData',
            args: args,
        };
        this.messageService.sendMsg(msg, this.backend);
      } else if((propName === "updateTriggerSelectedRow")  && this.valueCallbackId) {
        // const callbackId = this.xname + this.entity;
        // key is not yet in frontend available
        // const selectedRow = this.tableDataList.data.filter(row=> row[this.row_key]===this.updateTriggerSelectedRow)[0];
        if (this.updateTriggerSelectedRow) {
          const msg = {
              name: 'getTableData',
              args: [this.xname, this.entity, this.valueCallbackId, this.updateTriggerSelectedRow]
          };
          this.messageService.sendMsg(msg, this.backend);
        } else {
          const msg = {
              name: 'getTableData',
              args: [this.xname, this.entity, this.valueCallbackId]
          };
          this.messageService.sendMsg(msg, this.backend);
        }
      } else if((propName === "rebuildTrigger" || propName === "entity")  && this.valueCallbackId) {
        this.subs.forEach( subs => subs.unsubscribe() );
        this.initialize();
      } else if (propName === "openEditorEvent" && this.openEditorEvent != '') {
        this.openEditor();
      } else if (propName === "deselectAll") {
        this.tableDataService.lastSelectedRowKey.setItem(this.xname,"");
        this.onDeselectAll();
      } else if (propName === "updateRow" && this.updateRow != undefined) {
        this.getRowFromColumnNameAndClick(this.updateRow["value"], this.updateRow["column_name"]);
      } else if(propName === "outsideFilter") {
        this.filterValue = this.outsideFilter;
        this.applyOutsideFilter(this.filterValue);
      } else if (propName == "short_version"){
        if (this.short_version){
          // this.tableDataList = new MatTableDataSource(msg.args.data); 
          this.displayedColumns =this.displayedColumnsOrig.slice(0, this.short_version_length);
        }else{
          this.displayedColumns = this.displayedColumnsOrig;
        }
      } else if (propName == "page_size"  && this.valueCallbackId){
        this.paginator._changePageSize(this.page_size);
        this.tableDataList.paginator = this.paginator;
      }
    }
  }

  ngOnDestroy() {
    this.subs.forEach( subs => subs.unsubscribe() );
  }

  getDisplayColumnIds() {
      return this.displayedColumns.map(x => x.id);
  }
  
  masterToggle() {
    if (this.singleSelection) {
      return;
    }
    if (this.isAllSelected()) {
      this.tableDataList.data.forEach(row => {
        if (this.selection.isSelected(row)) {
          this.onRowClicked(row);
        }
      });
    } else {
      this.tableDataList.data.forEach(row => {
        if (!this.selection.isSelected(row)) {
          this.onRowClicked(row);
        }
      });
    }
  }

  public openFile(url) {
    const msg = {
      name: 'getTokenUrl',
      args: [url, this.callBackToken]
    };
    this.messageService.sendMsg(msg, this.backend);
  }

  public buttonClicked(element){
    this.buttonClickedEvent.emit(element);
  }

  toggleFilter() {
    // toggle filter display
    this.filtersOn = !this.filtersOn;
    // this.participantService.getTable(this.xname);
    // this.participantService.finalizeQuestionnaire();
  }

  openEditor(): void{
    if (this.messageService.login) {
      const selectedRow = this.tableDataList.data.filter(row=> row[this.row_key]===this.selection.selected[0])[0];
      const savedRow = this.tableDataService.copyRow(selectedRow);
      const dataOld = this.tableDataService.copyRow(selectedRow);
      // console.log("INIT", savedRow);
      const dialogRef = this.dialog.open(EditorDialogComponent, {
        width: '500px',
        data: {inputFields: savedRow, tableInputFormat: this.tableInputFormat, initFields: this.initFields, warning_msg: this.editorWarningMsg },
        position: {
          top: '100px',
          left: '600px'
        }
      });
      dialogRef.afterClosed().subscribe( data => {
          if (data) {
            // console.log(data)
            this.tableDataService.changeTableData(this.xname, data.inputFields, dataOld, this.backend);
          }
        }
      );
    }
  }

  public getFormat(digits: any): string {
    // {minIntegerDigits}.{minFractionDigits}-{maxFractionDigits}
    // const digits = highPrecision ? 3 : 2;
    if (!digits) {
        digits = 0;
    }
    const format = `1.${digits}-${digits}`;
    return format;
  }

  createFilter(): (data: any, filter: string) => boolean {
    const filterFunction = (data, filter): boolean => {
      const searchTerms = JSON.parse(filter);
      const keys = Object.keys(data);
      keys.shift();
      let out = true;
      for (const k of keys) {
        out = out && data[k].toLowerCase().indexOf(searchTerms[k].toLowerCase()) !== -1;
      }
      return out;
    }
    return filterFunction;
  }

  onDeselectAll(){
    if (this.tableDataList) {
      this.selection.selected.forEach(key_local => {
        this.selection.toggle(key_local);
      });
      this.editorDisabled = true;
    }
  }

  onRowClicked(row) {
    if (this.enabled) {
      console.log('Row clicked:  ', row);
      // get data from be
      this.tableDataService.selectRow(this.xname, row, this.selection.isSelected(row), this.backend);
      if (this.singleSelection) {
        // row selected
        this.tableDataService.setTableStatus(this.xname, this.entity, true, row);
        // deselect previous selected line
        this.selection.selected.forEach(key_local => {
          this.selection.toggle(key_local);
        });
      }
      this.selection.toggle(row[this.row_key]);
      this.editorDisabled = false;
      this.selectedRowEvent.emit(true);
      // this.selectedRowKeyEvent.emit(row[this.row_key]);
      this.tableDataService.lastSelectedRowKey.setItem(this.xname, row[this.row_key]);
    }
  }

  switchPageBasedOnSelection() {
      // if row is selected automatically: switch to correct page of paginator
      if (this.singleSelection && this.selection.selected) {
        const selected_row_index = this.tableDataList.data.map(x => x[this.row_key]).indexOf(this.selection.selected[0]);        
        // console.log("selected_row_index: ", selected_row_index);
        if (this.tableDataList.paginator && selected_row_index >= 0) {
          // console.log("switchPageBasedOnSelection: ", selected_row_index,  Math.floor(selected_row_index/this.tableDataList.paginator.pageSize));
          this.tableDataList.paginator.pageIndex = Math.floor(selected_row_index/this.tableDataList.paginator.pageSize);
          // update table data based on pageIndex
          this.tableDataList.paginator = this.paginator;
        }
      }
  }

  narrowFilter($event) {
    // console.log($event);
    const column  = $event.value;
    this.narrowFilterBasedOnColum(column);
  }

  narrowFilterBasedOnColum(column: string) {
    if (column.length !== 0) {
      // column is the new to be filtered col
      if (column === "all") {
        this.tableDataList.filterPredicate = this.defaultFilterPredicate;
      } else {
        this.tableDataList.filterPredicate = (data, filter) => {
          var tot = false;
          tot = (tot || data[column].toString().trim().toLowerCase().indexOf(filter.trim().toLowerCase()) !== -1);
          return tot;
        }
      }
      this.tableDataService.tableSearchInfo.item(this.xname).setItem("searchColumn", column);
    }
    this.applyOutsideFilter(this.tableDataService.tableSearchInfo.item(this.xname).item("searchValue"));
  }

  applyFilter(event: Event) {
    this.filterValue = (event.target as HTMLInputElement).value;
    this.tableDataList.filter = this.filterValue.trim().toLowerCase();
    this.tableDataService.tableSearchInfo.item(this.xname).setItem("searchValue", this.filterValue);
  }

  applyOutsideFilter(value: string) {
    // const filterValue = (event.target as HTMLInputElement).value;
    if(this.tableDataList){
      this.tableDataList.filter = value.trim().toLowerCase();
    }
  }

  getRowFromColumnNameAndClick(value: string, column_name: string) {
    this.tableDataList.data.forEach(row => {
      if (row[column_name] == value) {
        this.onRowClicked(row);
      }
    });
  }

  getWidthWOPadding(e: HTMLElement) {
    var computedStyle = getComputedStyle(e);
    let elementWidth = e.clientWidth;   // width with padding
    if (computedStyle.paddingLeft && computedStyle.paddingRight) {
      elementWidth -= parseFloat(computedStyle.paddingLeft) + parseFloat(computedStyle.paddingRight);
    }
    return elementWidth;
  }

  public drop(event: CdkDragDrop<string>) {
    this.dragDisabled = true;
    const previousIndex = this.tableDataList.data.findIndex(d => d === event.item.data);
    moveItemInArray(this.tableDataList.data, previousIndex, event.currentIndex);
    // NOTE: below line does not work together with matSort
    // this.table.renderRows();
    this.tableDataList._updateChangeSubscription();
    const row_dropped = this.tableDataList.data.filter((table_row) => this.tableDataList.data.indexOf(table_row) == event.currentIndex);
    const row_above = this.tableDataList.data.filter((table_row) => this.tableDataList.data.indexOf(table_row) == event.currentIndex -1 );
    const row_below = this.tableDataList.data.filter((table_row) => this.tableDataList.data.indexOf(table_row) == event.currentIndex + 1 );
    let row_above_index = 0.0
    let row_below_index = -1.0
    if (row_above.length > 0){
      row_above_index = row_above[0]["dashboard_chronology_number"];
    }
    if (row_below.length > 0){
      row_below_index = row_below[0]["dashboard_chronology_number"];
    }
    const msg = {
      name: 'dropped_table_row',
      args: [this.xname, row_dropped[0]["key"], row_above_index, row_below_index]
    };
    this.messageService.sendMsg(msg, this.backend);

  }
}

