import { Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-modal-canvas-intro',
  templateUrl: './modal-canvas-intro.component.html',
  styleUrls: ['./modal-canvas-intro.component.scss']
})
export class ModalCanvasIntroComponent {

  @ViewChild('canvasVideo') canvasEl: ElementRef;

  private context: CanvasRenderingContext2D;

  constructor(
    private dialogRef: MatDialogRef<ModalCanvasIntroComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngAfterViewInit() {
    // this.context = (
    //   this.canvasEl.nativeElement as HTMLCanvasElement
    // ).getContext('2d');

    // this.draw();
  }

  private draw() {
    this.context.font = '30px Arial';
    this.context.textBaseline = 'middle';
    this.context.textAlign = 'center';

    const x = (this.canvasEl.nativeElement as HTMLCanvasElement).width / 2;
    const y = (this.canvasEl.nativeElement as HTMLCanvasElement).height / 2;
    this.context.fillText('Angular Canvas', x, y);
  }

  

  close(): void {
    this.dialogRef.close(false);
  }


}
