import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';


import { AuthGuard } from '../guard/auth.guard';
import { UserManagementViewComponent } from './views/user-management-view/user-management-view.component';
import { HomeComponent } from './views/home/home.component';
import { ProductInfoViewComponent } from './views/product-info-view/product-info-view.component';
import { ProductViewComponent } from './views/product-view/product-view.component';
import { SettingsComponent } from './views/settings/settings.component';
import { SubscriptionPlansViewComponent } from './views/subscription-plans-view/subscription-plans-view.component';
import { LoginComponent } from './views/login/login.component';
import { RegisterComponent } from './views/register/register.component';
import { PasswordRecoverComponent } from './views/password-recover/password-recover.component';
import { RecoverComponent } from './views/recover/recover.component';

const uiSupplierRoutes: Routes = [
  {
    path: 'main',
    children: [
      {
        path: 'home',
        component: HomeComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'user-management',
        component: UserManagementViewComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'product-info-view/:id',     //:batch
        component: ProductInfoViewComponent,
      },
      {
        path: 'products',
        component: ProductViewComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'settings/:tab',
        component: SettingsComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'subscriptions',
        component: SubscriptionPlansViewComponent,
        canActivate: [AuthGuard]
      },

    ]
  },
  //{
  //  path: 'login-view/:id',
  //  component: LoginViewComponent,
  //  canActivate: [AuthGuard]
  //},
  {
    path: 'login-view/:id',
    component: LoginComponent
  },
  {
    path: 'recover',
    component: RecoverComponent
  },
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'register',
    component: RegisterComponent
  },
  {
    path: 'password-recover',
    component: PasswordRecoverComponent
  }
    
];

@NgModule({
  imports: [RouterModule.forChild(uiSupplierRoutes)],
  exports: [RouterModule],
})
export class UiSupplierRoutingModule { }
