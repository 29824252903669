import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { DialogService } from '../../services/dialog.service';
import { Title } from '@angular/platform-browser';
import { filter } from 'rxjs/operators';
import { MessageService } from './../../services/message.service';
import { Subscription } from 'rxjs/internal/Subscription';
import { TableDataService } from '../../services/table-data.service';
import { TextService } from '../../services/text.service';
import { ProjectSetupService } from 'src/app/shared/services/project-setup.service';

@Component({
  selector: 'app-table-dialog',
  templateUrl: './table-dialog.component.html',
  styleUrls: ['./table-dialog.component.scss']
})
export class TableDialogComponent implements OnInit, OnDestroy {
  public rowSelected: boolean = false;
  public selectedRow: any;
  private subscriptions: Array<Subscription> = [];

  constructor(
    private dialogService: DialogService,
    public messageService: MessageService,
    public dialogRef: MatDialogRef<TableDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, // required: title, message, name
    private tableDataService: TableDataService,
    private textService: TextService,
    private projectSetupService: ProjectSetupService
  ) { }

  ngOnInit(): void {
    if (! this.data.text_cancel_button) {
      this.data.text_cancel_button = this.textService.cancel_title[this.projectSetupService.language];
    }
    if (! this.data.text_confirm_button) {
      this.data.text_confirm_button = this.textService.add_title[this.projectSetupService.language];
    }
    this.subscriptions.push(
      this.tableDataService.singleSelectionSubject.pipe(filter(msg => msg.table === this.data.table_name))
      .subscribe(msg => {
        this.rowSelected = msg.status;
        if (this.rowSelected) {
          // console.log("selectedRow in table-dialog: ", msg.row);
          this.selectedRow = msg.row;
        }
      })
    );
  }

  onCancel(): void {
    this.dialogRef.close();
    this.dialogService.dialogStatus(this.data.name, false);
    this.dialogService.transferRowSelected(this.data.name, {}, false);
  }

  onOk() {
    this.dialogService.dialogStatus(this.data.name, true);
    this.dialogService.transferRowSelected(this.data.name, this.selectedRow, true);
    this.dialogRef.close();
  }

  ngOnDestroy() {
    this.subscriptions.forEach( subs => subs.unsubscribe() );
  }

}
